import React from "react";
import { Grid, Card, Box, Typography, Button, Container } from "@mui/material";
import mobileImg from "../../assets/images/english/phone.png";
import appStore from "../../assets/images/appleStore.png";
import googleStore from "../../assets/images/googleStore.png";

function LearnAnywhere() {
  return (
    <Box
      sx={{
        backgroundColor: "#f4f4f4",
        mt: 8,
        mb: 8,
        pt: { xs: 0, sm: 0, md: 4 },
        pb: 4,
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={12} md={6}>
            <Box
              height="409px"
              //data-aos="fade-left"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: { xs: "center", sm: "center", md: "flex-start" },
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: { xs: "33px", sm: "55px", md: "48px" },
                  lineHeight: "60px",
                  fontWeight: 600,
                  textAlign: { xs: "center", sm: "center", md: "left" },
                  lineHeight: { xs: "48px", sm: "70px", md: "60px" },
                  color: "#130160",
                }}
              >
                <span style={{ color: "#EA5806" }}>Start your learning</span>
                <br />
                <span style={{ color: "#008035" }}>journey now!</span>
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  lineHeight: "28px",
                  mt: 4,
                  mb: 4,
                }}
              >
                <span style={{ fontSize: "25px", verticalAlign: "top" }}>
                  •
                </span>{" "}
                Live and recorded classes
                <br />
                <span style={{ fontSize: "25px", verticalAlign: "top" }}>
                  •
                </span>{" "}
                Free study material
                <br />
                <span style={{ fontSize: "25px", verticalAlign: "top" }}>
                  •
                </span>{" "}
                Quick doubt resolution
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  rowGap: "3px",
                  columnGap: "20px",
                  mt: 2,
                }}
              >
                <a
                  href="https://apps.apple.com/in/app/veda-academy/id6448278906"
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <img src={appStore} width="152px" height="45px" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.koshish.veda&pli=1"
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <img src={googleStore} width="152px" height="45px" />
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <img src={mobileImg} alt="mobile image" height="409" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default LearnAnywhere;
