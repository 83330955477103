import React from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import experts from "../../assets/images/experts.svg";

function HappyTohelpYou() {
  const navigate = useNavigate();
  return (
    <Box sx={{ mx: { xs: 3, md: 0 } }}>
      <Container
        sx={{
          backgroundColor: "#FF8331",
          mt: 10,
          mb: 10,
          maxWidth: { xs: "100%", lg: "1224px" },
          borderRadius: "20px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Container>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "32px",
                  lineHeight: "40px",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  mt: 10,
                }}
              >
                Happy to help you!
              </Typography>

              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  lineHeight: "22.4px",
                  color: "#FFFFFF",
                  mt: 3,
                }}
              >
                Ready to create a positive impact in your life. (Create your own
                test or play with your friends)
              </Typography>

              <Button
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/contact");
                }}
                variant="outlined"
                sx={{
                  backgroundColor: "#FFFFFF",
                  color: "black",
                  mt: 3,
                  borderRadius: "8px",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  lineHeight: "25.6px",
                  fontWeight: 600,
                  paddingTop: 1.3,
                  paddingBottom: 1.3,
                  paddingLeft: 2,
                  paddingRight: 2,
                  boxShadow: 7,
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                  },
                }}
              >
                Speak to an expert
              </Button>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <img
                src={experts}
                alt=""
                maxWidth="100%"
                height="323px"
                style={{ marginTop: "26px", width: "100%" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default HappyTohelpYou;
