import React from "react";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export default function DisclaimerOfWarranties() {
  return (
    <Container>
      <Box sx={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
        <Typography
          align="center"
          fontWeight={700}
          fontSize="22px"
          sx={{ marginTop: "50px" }}
        >
          Disclaimer Of Warranties
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "20px" }}>
          User expressly understands and agrees that:
        </Typography>
        <ul
          mt={2}
          style={{
            fontSize: "17px",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif"
          }}
        >
          <li style={{ marginTop: "10px" }}>
            We request You not to accept any product package that seems to be
            tampered with, opened or damaged at the time of delivery. The
            products must be returned in the same condition as delivered by the
            Company. Any products returned showing signs of any use or damage in
            any manner shall not be accepted for return.
          </li>
          <li style={{ marginTop: "10px" }}>
            You acknowledge that the Company is not the manufacturer of the
            instrument/medium/hardware and hence, any defect relating to the
            same (beyond the specified period of 48 hours) shall be reported to
            the manufacturer whose details shall be specified on the packaging
            and the Company shall not be in any manner responsible for the same.
            The Company does not provide any guarantee or warranty relating to
            the instrument/medium/hardware.
          </li>
          <li style={{ marginTop: "10px" }}>
            Use of the service is at the user's sole risk. The service is
            provided on an as is and as available basis. Veda expressly
            disclaims all warranties of any kind, whether express or implied,
            including, but not limited to the implied warranties of
            merchantability, fitness for a particular purpose and
            non-infringement.
          </li>
          <li style={{ marginTop: "10px" }}>
            Veda makes no warranty that (i) the service will meet the user’s
            requirements, (ii) the service will be uninterrupted, timely,
            secure, or error-free, (iii) the results that may be obtained from
            the use of the service will be accurate or reliable, (iv) the
            quality of any products, services, information, or other material
            purchased or obtained by the user through the service will meet
            user's expectations, and (v) any errors in the software will be
            corrected.
          </li>
          <li style={{ marginTop: "10px" }}>
            Any material downloaded or otherwise obtained through the use of the
            service is done at the user's own discretion and risk and that user
            will be solely responsible for any damage to the user's computer
            system or loss of data that results from the download of any such
            material.
          </li>
          <li style={{ marginTop: "10px" }}>
            No advice or information, whether oral or written, obtained by the
            user from Veda or through or from the service shall create any
            warranty not expressly stated in the Tos.
          </li>
        </ul>
        <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
          Limitation Of Liability
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          Under no circumstances will Veda be liable to the user for any
          indirect, incidental, consequential, special or exemplary damage
          arising out of or in connection with use of the Veda service, whether
          or not Veda has been advised of the possibility of such damages. Such
          limitation of liability shall apply (i) whether the damages arise from
          use or misuse of and reliance on the Veda service, from inability to
          use the Veda service, or from the interruption, suspension, or
          termination of the Veda service (including such damages incurred by
          third parties), and (ii) notwithstanding any failure of essential
          purpose of any limited remedy and to the fullest extent permitted by
          law. Under no circumstances shall Veda be liable to the user for any
          amount exceeding one thousand rupees.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "10px" }}
        >
          Indemnity
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          The user agrees to indemnify and hold Veda, and its subsidiaries,
          affiliates, officers, agents, co-branders or other partners, and
          employees, harmless from any claim or demand, including reasonable
          attorney's fees, made by any third party due to or arising out of
          content the user submits, posts or transmits through the service, the
          user's use of the Service, the user's connection to the Service, the
          user's violation of the ToS, or the user's violation of any rights of
          another.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "10px" }}
        >
          No Resale Of Service
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          The user agrees not to reproduce, duplicate, copy, sell, resell or
          exploit for any commercial purposes, any portion of the Service, use
          of the Service, or access to the Service.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "10px" }}
        >
          Modifications To Service
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          Veda reserves the right at any time and from time to time to modify or
          discontinue, temporarily or permanently, the service (or any part
          thereof) with or without notice. The user agrees that Veda shall not
          be liable to the user or to any third party for any modification,
          suspension or discontinuance of the service.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "10px" }}
        >
          Notice
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          Notices to the user may be made via email or regular mail. The service
          of the site may also provide notices of changes to the TOS or other
          matters by displaying notices or links to notices to the user
          generally on the service.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "10px" }}
        >
          Trademark Information
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          The site design and the service marks, other logos, products and
          service names are trademarks of Koshish Marketing Solutions Pvt. Ltd.
          The user agrees not to display or use in any manner, the trademarks of
          Veda.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "10px" }}
        >
          Violations
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          Please report any violation of the terms and conditions of this
          agreement to info@koshishindia.in
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "10px" }}
        >
          Force Majeure
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          Veda shall have no liability to the user for the matters where it has
          no control, or which are beyond its control and fall in this category.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "10px" }}
        >
          Entire Agreement
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          These Terms of Service constitute the entire agreement between the
          parties with respect to the subject matter thereof and supersedes and
          replaces all prior or contemporaneous understandings or agreements,
          written or oral, regarding such subject.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "10px" }}
        >
          Jurisdiction
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          These Terms of Service will be governed by and construed in accordance
          with the laws of India and any dispute arising out of the use of the
          site shall be subject to the exclusive jurisdiction of courts at New
          Delhi, India. If for any reason a court of competent jurisdiction
          finds any provision or portion of the Terms of Service to be
          unenforceable, the remainder of the Terms of Service will continue in
          full force and effect. The user shall not assign these Terms of
          Service or assign any rights or delegate any obligations hereunder, in
          whole or in part, whether voluntarily or by operation of law, without
          our prior written consent, any such purported assignment or delegation
          will be null and void and of no force or effect.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "10px" }}
        >
          User Privacy Policy
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          The user consents that by subscribing/using the Company’ products,
          programs or any other services, it expressly allows the Company to
          improve pedagogy, learning outcomes, predictive learning and to store,
          use, analyze, distribute, or deal with in any other manner, as the
          Company deems appropriate, from the information/data captured by the
          Company through its unique pedagogy of learn, practice and test and
          from the Customer’s usage of Company’ products, programs or any other
          services as the Company, its associates or any third party as the
          Company may authorize.
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "20px" }}>
          The user's privacy is critically important to Veda. Veda has few
          fundamental principles:
        </Typography>
        <ul
          mt={2}
          style={{
            fontSize: "17px",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
       
          }}
        >
          <li>
            Veda asks its users to supply various personal data when placing an
            order, such as name, address, credit-card information, and valid
            E-mail id.
          </li>
          <li>
            Veda uses this data only for the purpose of filling an order or
            communicating with the user about the status of an order.
          </li>
          <li>
             Veda treats this customer information as private and confidential,
            and it shall not disclose this information to other individuals or
            organizations unless required by law.
          </li>
        </ul>
      </Box>
    </Container>
  );
}
