import slider01 from "../../assets/images/jnv/slider01.jpg";
import slider02 from "../../assets/images/jnv/slider02.png";
import slider03 from "../../assets/images/jnv/slider03.png";
import slider01Mobile from "../../assets/images/jnv/slider01.jpg";
import slider02Mobile from "../../assets/images/jnv/slider02mobile.jpg";
import slider03Mobile from "../../assets/images/jnv/slider03mobile.jpg";
import course01 from "../../assets/images/jnv/course01.png";
import teach01 from "../../assets/images/jnv/teach01.png";
import teach02 from "../../assets/images/jnv/teach02.png";
import teach03 from "../../assets/images/jnv/teach03.png";
import teacher01 from "../../assets/images/jnv/shashikant_new.png";
import teacher02 from "../../assets/images/jnv/shivam.png";
import teacher03 from "../../assets/images/jnv/preeti_new.png";
import teacher04 from "../../assets/images/jnv/pratibha_new.png";
import teacher05 from "../../assets/images/jnv/neha.png";
import teacher06 from "../../assets/images/jnv/spandan.png";
import socialIcon01 from "../../assets/images/jnv/socialIcon01.png";
import socialIcon02 from "../../assets/images/jnv/socialIcon02.png";
import socialIcon03 from "../../assets/images/jnv/socialIcon03.png";
import sm01 from "../../assets/images/jnv/sm01.png";
import sm02 from "../../assets/images/jnv/sm02.png";
import sm03 from "../../assets/images/jnv/sm03.png";
import sm04 from "../../assets/images/jnv/sm04.png";
import sm05 from "../../assets/images/jnv/sm05.png";
import sm06 from "../../assets/images/jnv/sm06.png";
import wvacademy01 from "../../assets/images/jnv/wvacademy01.png";
import wvacademy02 from "../../assets/images/jnv/wvacademy02.png";
import wvacademy03 from "../../assets/images/jnv/wvacademy03.png";
import wvacademy04 from "../../assets/images/jnv/wvacademy04.png";
import wvacademy05 from "../../assets/images/jnv/wvacademy05.png";
import wvacademy06 from "../../assets/images/jnv/wvacademy06.png";
import youtubeImg from "../../assets/images/english/youtubeImg.png";
import youtubeIcon from "../../assets/images/english/youtubeIcon.png";
import UserIcon from "../../assets/images/jnv/jnvicon.png";
import StudentsImg from "../../assets/images/jnv/students.png";
import program01 from "../../assets/images/jnv/program01.png";
import program02 from "../../assets/images/jnv/program02.png";
import program03 from "../../assets/images/jnv/program03.png";
import { keyboard } from "@testing-library/user-event/dist/keyboard";

export const CourseData = [
  {
    id: 1,
    head1: "Class 6",
    head2: "Navodaya Vidyalaya",
    text1: "Lesson : 6",
    btnText: "Explore Courses",
    bgColor: "#fff",
    head1BgColor: "#FF7A7F",
    head1Color: "#fff",
    btnBgColor: "#ED1C24",
    btnHoverColor: "#9747FF",
    sprice: 159999,
    price: 199999,
    discount: 35,
    img: course01,
  },
  {
    id: 2,
    head1: "Class 9",
    head2: "Navodaya Vidyalaya",
    text1: "Lesson : 6",
    btnText: "Explore Courses",
    bgColor: "#fff",
    head1BgColor: "#FF7A7F",
    head1Color: "#fff",
    btnBgColor: "#ED1C24",
    btnHoverColor: "#9747FF",
    sprice: 159999,
    price: 199999,
    discount: 35,
    img: course01,
  },
  {
    id: 3,
    head1: "Class 6",
    head2: "Sainik School",
    text1: "Lesson : 6",
    btnText: "Explore Courses",
    bgColor: "#fff",
    head1BgColor: "#FF7A7F",
    head1Color: "#fff",
    btnBgColor: "#ED1C24",
    btnHoverColor: "#9747FF",
    sprice: 159999,
    price: 199999,
    discount: 35,
    img: course01,
  },
  {
    id: 4,
    head1: "Class 9",
    head2: "Sainik School",
    text1: "Lesson : 6",
    btnText: "Explore Courses",
    bgColor: "#fff",
    head1BgColor: "#FF7A7F",
    head1Color: "#fff",
    btnBgColor: "#ED1C24",
    btnHoverColor: "#9747FF",
    sprice: 159999,
    price: 199999,
    discount: 35,
    img: course01,
  },
];

export const sliderImages = [
  { img: slider01, mobileImg: slider01Mobile },
  { img: slider02, mobileImg: slider02Mobile },
  { img: slider03, mobileImg: slider03Mobile },
];

export const youtubeData = [
  {
    id: 1,
    head1: "We’re on YouTube!",
    text: "Check our latest videos on YouTube",
    btnText: "View us on Youtube",
    YoutubeIcon: youtubeIcon,
    img: youtubeImg,
  },
];

export const youtubeSectionData = [
  {
    id: 1,
    // img: youtubeBanner,
    img: "https://img.youtube.com/vi/N50axTf7Q2o/maxresdefault.jpg",
    caption: "Navodaya Vidyalaya",
    icon: UserIcon,
    title: "Documents List for Navodaya Vidyalaya 2024",
    text: "",
    imgIcon: UserIcon,
    videoUrl: "https://www.youtube.com/watch?v=N50axTf7Q2o&t=655s",
  },
  {
    id: 2,
    // img: youtubeBanner,
    img: "https://img.youtube.com/vi/hdbonJJNVHY/maxresdefault.jpg",
    caption: "Navodaya Vidyalaya",
    icon: UserIcon,
    title: "Navodaya Result Update 2024",
    text: "",
    imgIcon: UserIcon,
    videoUrl: "https://www.youtube.com/watch?v=hdbonJJNVHY",
  },
  {
    id: 3,
    // img: youtubeBanner,
    img: "https://img.youtube.com/vi/wTky9lLdtEA/maxresdefault.jpg",
    caption: "Navodaya Vidyalaya",
    icon: UserIcon,
    title: "Navodaya Vidyalaya Class 9 Result 2024",
    text: "",
    imgIcon: UserIcon,
    videoUrl: "https://www.youtube.com/watch?v=wTky9lLdtEA",
  },
];

export const popularCourses = [
  {
    id: 1,
    text: "Interactive Live Classes",
    icon: wvacademy01,
  },
  {
    id: 2,
    text: "Top Educators",
    icon: wvacademy02,
  },
  {
    id: 3,
    text: "1-1 Mentorship",
    icon: wvacademy03,
  },
  {
    id: 4,
    text: "Doubts & Solutions",
    icon: wvacademy04,
  },
  {
    id: 5,
    text: "Learning Report",
    icon: wvacademy05,
  },
  {
    id: 6,
    text: "Test Series",
    icon: wvacademy06,
  },
];

export const studyMaterials = [
  {
    id: 1,
    text: "Previous <br />Year <br />Question <br />Papers",
    bgColor: "#F5F5F5",
    image: sm01,
    keyboard: "/jnv/pryquestion",
  },
  {
    id: 2,
    text: "Revision <br />Notes",
    bgColor: "#FEF0CC",
    image: sm02,
    keyboard: "/jnv/revisionnote",
  },
  {
    id: 3,
    text: "Modal <br />Papers",
    bgColor: "#FDEEAC",
    image: sm03,
    keyboard: "/jnv/modalpaper",
  },
  {
    id: 4,
    text: "Guess <br />Papers",
    bgColor: "#E9DFFF",
    image: sm04,
    keyboard: "/jnv/gusspaper",
  },
  {
    id: 5,
    text: "Books",
    bgColor: "#FFD9D8",
    image: sm05,
    keyboard: "/jnv/books",
  },
  {
    id: 6,
    text: "OMR <br />Sheet",
    bgColor: "#CDDFFF",
    image: sm06,
    keyboard: "/jnv/omrsheet",
  },
];

export const teachData = [
  {
    id: 1,
    text: "Vast Teaching Experience",
    img: teach01,
  },
  {
    id: 2,
    text: "Unique style of teaching",
    img: teach02,
  },
  {
    id: 3,
    text: "Proven history of delivering results",
    img: teach03,
  },
];

export const teacherData = [
  {
    id: 1,
    name: "Shashikant Yadav",
    subject: "Mental Ability and Science Expert",
    exp: "6+ years exp",
    img: teacher01,
    sIcons: [socialIcon01, socialIcon02, socialIcon03],
  },
  {
    id: 2,
    name: "Shivam Patel",
    subject: "Arithmetic Expert",
    exp: "6+ years exp",
    img: teacher02,
    sIcons: [socialIcon01, socialIcon02, socialIcon03],
  },
  {
    id: 3,
    name: "Preeti Tiwari",
    subject: "English Language Expert",
    exp: "5+ years exp",
    img: teacher03,
    sIcons: [socialIcon01, socialIcon02, socialIcon03],
  },
  {
    id: 4,
    name: "Pratibha Saxena",
    subject: "Science Expert",
    exp: "5+ years exp",
    img: teacher04,
    sIcons: [socialIcon01, socialIcon02, socialIcon03],
  },
  {
    id: 5,
    name: "Neha",
    subject: "Maths Expert",
    exp: "5+ years exp",
    img: teacher05,
    sIcons: [socialIcon01, socialIcon02, socialIcon03],
  },
  {
    id: 6,
    name: "Spandan Singh",
    subject: "Maths Expert",
    exp: "3+ years exp",
    img: teacher06,
    sIcons: [socialIcon01, socialIcon02, socialIcon03],
  },
];

export const bottomSlider = [
  {
    id: 1,
    topText:
      "“Getting into Navodaya Vidyalaya has always been my dream. Veda Academy's comprehensive study materials and teachers made it a reality. I'm so grateful!”",
    name: "Divyanshu Shekhar",
    rank: "",
    bgColor: "#ffe6e6",
    topTextColor: "#101828",
    nameColor: "#ED1C24",
  },
  {
    id: 2,
    topText:
      "“As someone living in a remote area, I wasn't sure how I'd prepare for the       JNVST effectively. Veda Academy's online classes were a lifesaver! The quality lectures made learning convenient and engaging.”",
    name: "Niki Yadav",
    rank: "",
    bgColor: "#EAECF0",
    topTextColor: "#101828",
    nameColor: "#130160",
  },
  {
    id: 3,
    topText:
      "“I got into Navodaya Vidyalaya! Thanks to Veda Academy, the classes were fun and easy to understand. My teachers were the best!”",
    name: "Aanand Prasad Das",
    rank: "",
    bgColor: "#ffe6e6",
    topTextColor: "#101828",
    nameColor: "#ED1C24",
  },
  {
    id: 4,
    topText:
      "“I always struggled to make a study schedule. Veda Academy gave me a timetable, and I stuck to it! I think that's why I did so well.”",
    name: "Parmar Tapasya",
    rank: "",
    bgColor: "#EAECF0",
    topTextColor: "#101828",
    nameColor: "#130160",
  },
  {
    id: 5,
    topText:
      "“Live classes with visual representations helped me learn and retain all the topic better.”",
    name: "Vanshika Saini",
    rank: "",
    bgColor: "#ffe6e6",
    topTextColor: "#101828",
    nameColor: "#ED1C24",
  },
  {
    id: 6,
    topText:
      "“I had a great experience at Veda Academy. All the teachers explain things clearly, and they're always there to help.”",
    name: "Meena Sharma",
    rank: "",
    bgColor: "#EAECF0",
    topTextColor: "#101828",
    nameColor: "#130160",
  },
];

export const feedbackSliderFree = [
  {
    id: 1,
    img: StudentsImg,
    bgColor: "#fff",
  },
];

export const feedbackSlider = [
  {
    id: 1,
    img: StudentsImg,
    bgColor: "#fff",
  },
  {
    img: StudentsImg,
    bgColor: "#fff",
  },
  {
    id: 3,
    img: StudentsImg,
    bgColor: "#fff",
  },
];

export const programList = [
  {
    id: 1,
    img: program01,
    bgColor: "#fff",
  },
  {
    id: 2,
    img: program02,
    bgColor: "#fff",
  },
  {
    id: 3,
    img: program03,
    bgColor: "#fff",
  },
  {
    id: 4,
    img: program01,
    bgColor: "#fff",
  },
  {
    id: 5,
    img: program02,
    bgColor: "#fff",
  },
  {
    id: 6,
    img: program03,
    bgColor: "#fff",
  },
];

export const urlData = [
  "/jnv/pryquestion",
  "/jnv/revisionnote",
  "/jnv/modalpaper",
  "/jnv/gusspaper",
  "/jnv/books",
  "/jnv/omrsheet",
];
