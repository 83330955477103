import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const PrivacyPolicy = () => {
  return (
    <Container sx={{ pb: 5 }}>
      <Box sx={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
        <Typography
          variant="h6"
          mt={2}
          sx={{
            fontSize: "30px",
            textAlign: "initial",
            fontWeight: "700",
            lineHeight: "57px",
            fontStyle: "normal",
            color: "#1B1B1B",
          }}
        >
          Privacy Policy
        </Typography>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif",
            marginTop: "10px",
            fontSize: "17px",
          }}
        >
          {/* <li
            style={{
              marginTop: "10px"
            }}
          >
            Koshish Marketing Solutions Pvt. Ltd. (“Koshish” or the “Company”)
            is registered at 1/10712, UGF Subhash Park Hulewalal Park, Naveen
            Shahadra, Delhi North East DL 110032 IN. and Koshish Marketing
            Solutions Pvt. Ltd. Plot No, 15A, Tradex Tower, Sector 125, Noida,
            Uttar Pradesh 201303. Our Product Veda Academy (“Veda”).
          </li> */}
          <li
            style={{
              marginTop: "10px",
            }}
          >
            Veda Academy Privacy Policy belongs to Koshish Marketing Solutions
            Private Limited.
          </li>
          <li
            style={{
              marginTop: "10px",
            }}
          >
            This Privacy Policy provides you with details about the manner in
            which your data is collected, stored and used by us. You are advised
            to read this Privacy Policy carefully. By visiting Veda'
            website/applications you expressly give us consent to use and
            disclose your personal information in accordance with this Privacy
            Policy and in case of a minor, the said consent is being given by
            the minor’s legal guardian. If you do not agree to the terms of the
            policy, please do not use or access Veda website, or mobile
            applications.
          </li>
          <li
            style={{
              marginTop: "10px",
            }}
          >
            Note: Our Privacy Policy may change at any time without prior
            notification. To make sure that you are aware of any changes, kindly
            review the Privacy Policy periodically. This Privacy Policy shall
            apply uniformly across all Veda products, including its website,
            mobile applications, Tablet and other associated applications,
            products, websites and services managed by the Company.
          </li>
        </ul>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          General
        </Typography>

        <ul
          mt={2}
          style={{
            fontWeight: "400",
            fontSize: "17px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif",
            lineHeight: "25px",
            marginTop: "10px",
          }}
        >
          <li
            style={{
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "27px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            We will not sell, share or rent your personal information to any
            third party, unless, we have been expressly authorized by you either
            in writing or electronically to do so. Further, we will not use your
            email address/mobile number for unsolicited emails and/or SMS. Any
            emails and/or SMS sent by Veda academy will only be in connection
            with the provision of agreed services and products and this Privacy
            Policy.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "27px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            Periodically, we may reveal general statistical information about
            Veda academy users, such as number of visitors, number and type of
            goods and services purchased, etc. This will not have any
            identifiable information of any user.
          </li>

          <li
            style={{
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "27px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            Veda will occasionally send email notices or contact you to
            communicate about our services, products and benefits, as they are
            considered an essential part of the services/products you have
            chosen.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "27px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            We reserve the right to communicate your personal information to any
            third party that makes a legally compliant request for its
            disclosure.
          </li>

          <li
            sx={{
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "27px",
              textAlign: "justify",
              color: "#333333",
            }}
            style={{
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "27px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            If you so desire, we will provide you with means to review the
            personal information that you have provided, along with an option to
            make request for correcting/amending the information.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "27px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            We limit access to your information to authorized employees only who
            need to know the said information.
          </li>
        </ul>

        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Personal Information
        </Typography>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            fontSize: "17px",
            textAlign: "justify",
            color: "#333333",
            lineHeight: "25px",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        >
          <li
            style={{
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "27px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            Personal Information means and includes all information that can be
            linked to a specific individual or to identify any individual, such
            as
          </li>
          <ul sx="list-style-type:circle">
            <li
              style={{
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "27px",
                textAlign: "justify",
                color: "#333333",
                marginTop: "10px",
              }}
            >
              name, address, mailing address, telephone number, email ID,
              password, & your ward's educational interests;
            </li>
            <li
              style={{
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "27px",
                textAlign: "justify",
                color: "#333333",
                marginTop: "10px",
              }}
            >
              Transaction related information (such as when you make purchases,
              respond to any offers, or download or use applications from us);
            </li>
            <li
              style={{
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "27px",
                textAlign: "justify",
                color: "#333333",
                marginTop: "10px",
              }}
            >
              Information you provide us when you contact us for help.
            </li>
            <li
              style={{
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "27px",
                textAlign: "justify",
                color: "#333333",
                marginTop: "10px",
              }}
            >
              Information you enter our system when using the
              Application/Services/products, such as while asking doubts,
              participating in discussions and taking tests.
            </li>
          </ul>
        </ul>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            fontSize: "17px",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        >
          <li
            style={{
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "27px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            The said information collected from the users could be categorized
            as “Personal Information”, “Sensitive Personal Information” and
            “Associated Information”. Personal Information, Sensitive Personal
            Information and Associated Information (each as individually defined
            under this Information Technology (Reasonable security practices and
            procedures and sensitive personal data or information) Rules, 2011
            (the “Data Protection Rules”)) shall collectively be referred to as
            'Information' in this Policy. as
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "27px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            When you voluntarily send us electronic mail / fillup the form, we
            will keep a record of this information so that we can respond to
            you. We only collect information from you when you register on our
            site or fill out a form. Also, when filling out a form on our site,
            you may be asked to enter your: name, e-mail address or phone
            number. You may, however, visit our site anonymously. In case you
            have submitted your personal information and contact details, we
            reserve the rights to Call, SMS, Email or WhatsApp about our
            products and offers, even if your number has DND activated on it.
          </li>
        </ul>
        <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
          Automatically Collected Information
        </Typography>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            fontSize: "17px",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        >
          <li
            style={{
              fontWeight: "400",
              fontSize: "18px",
              lineHeight: "27px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            In addition, the Application/products/Services may collect certain
            information automatically, including, but not limited to, the type
            of mobile device you use, your mobile devices unique device ID, the
            IP address of your mobile device, your mobile operating system, the
            type of mobile Internet browsers you use, and information about the
            way you use the Application/Services/products. As is true of most
            Mobile applications, we also collect other relevant information as
            per the permissions that you provide. We use an outside credit card
            processing company to bill you for goods and services. These
            companies do not retain, share, store or use personally identifiable
            information for any other purpose.
          </li>
        </ul>
        <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
          Use of Personal Information
        </Typography>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            fontSize: "17px",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
          }}
        >
          <li
            style={{
              marginTop: "10px",
            }}
          >
            We use personal information to provide you with services and
            products you explicitly requested for, to resolve disputes,
            troubleshoot concerns, help promote safe services, collect money,
            measure consumer interest in our services, inform you about offers,
            products, services, updates, customize your experience, detect, and
            protect us against error, fraud, and other criminal activity,
            enforce our terms and conditions, etc.
          </li>
          <li
            style={{
              marginTop: "10px",
            }}
          >
            We also use your contact information to send you offers based on
            your previous orders and interests. We may occasionally ask you to
            complete optional online surveys. These surveys may ask you for
            contact information and demographic information (like zip code, age,
            gender, etc.). We use this data to customize your experience at
            Veda. Your data may periodically be transmitted to external service
            providers to help us improve the application, products, and our
            services. We will share your information with third parties only in
            the ways that are described in this Privacy Policy.
          </li>
          <li
            style={{
              marginTop: "10px",
            }}
          >
            We do not sell, trade, or share your Information to any third party
            (except subsidiaries/affiliates of the Company for related
            offerings) unless, we have been expressly authorized by you either
            in writing or electronically to do so. We may at times provide
            aggregate statistics about our customers, traffic patterns, and
            related site information to reputable third parties, however this
            information when disclosed will be in an aggregate form and does not
            contain any of your Personally Identifiable Information
          </li>
          <li
            style={{
              marginTop: "10px",
              fontSize: "17px",
            }}
          >
            We may disclose Information:
          </li>
          <ul sx="list-style-type:circle">
            <li
              style={{
                fontWeight: "400",
                fontSize: "17px",
                lineHeight: "25px",
                textAlign: "justify",
                color: "#333333",

                marginTop: "10px",
              }}
            >
              as required by law, such as to comply with a subpoena, or similar
              legal process;
            </li>
            <li style={{ marginTop: "10px" }}>
              to enforce applicable terms of use, including investigation of
              potential violations thereof; when we believe in good faith that
              disclosure is necessary to protect our rights.
            </li>
            <li style={{ marginTop: "10px" }}>
              protect your safety or the safety of others, investigate fraud,
              address security or technical issues or respond to a government
              request;
            </li>
            <li style={{ marginTop: "10px" }}>
              with our trusted services providers who work on our behalf, do not
              have an independent use of the information we disclose to them,
              and have agreed to adhere to the rules set forth in this Policy;
            </li>
            <li style={{ marginTop: "10px" }}>
              to protect against imminent harm to the rights, property or safety
              of the Application/Website or its users or the public as required
              or permitted by law;
            </li>
            <li style={{ marginTop: "10px" }}>
              with third party service providers in order to personalize the
              Application/Website/Services/products for a better user experience
              and to perform behavioral analysis;
            </li>
            <li style={{ marginTop: "10px" }}>
              Any portion of the Information containing personal data relating
              to minors provided by you shall be deemed to be given with the
              consent of the minor's legal guardian. Such consent is deemed to
              be provided by your registration with us.
            </li>
          </ul>
        </ul>

        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          Cookies
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          A "cookie" is a small piece of information stored by a web server on a
          web browser so it can be later read back from that browser. Veda uses
          cookies and tracking technology depending on the features offered. No
          personal information will be collected via cookies and other tracking
          technology; however, if you have previously provided personally
          identifiable information, cookies may be tied to such information.
          This is applicable only for website.
        </Typography>

        {/* --------------------------------------------------------------Added Arae */}

        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          Links To Other Site
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          Our site provides links to other third-party websites that may collect
          personally identifiable information about you. We are not responsible
          for the privacy practices or the content of those linked websites, and
          you are solely responsible for visiting such third-party websites.
        </Typography>

        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          Public Forums
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          When you use certain features on our website like the discussion
          forums and you post or share your personal information such as
          comments, messages, files, photos, will be available to all users, and
          will be in the public domain. All such sharing of information is done
          at your own risk. Please keep in mind that if you disclose personal
          information in your profile or when posting on our forums this
          information may become publicly available.
        </Typography>

        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          Security
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          Veda Academy has stringent security measures in place to protect the
          loss, misuse, and alteration of the information under our control.
          Whenever you change or access your account information, we offer the
          use of a secure server. Once your information is in our possession we
          adhere to strict security guidelines, protecting it against
          unauthorized access. Please be aware that, although we endeavor to
          provide reasonable security for information we process and maintain,
          no security system can prevent all potential security breaches. <br />
          <br />
          It is your responsibility to control access to your mobile device and
          the Veda Academy application on your device, including keeping your
          password(s) and/or PIN confidential and not sharing it with anyone. It
          is also your responsibility to alert Veda Academy if you believe that
          the security of the information in the Veda Academy application has
          been compromised.
        </Typography>

        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          Duration of Retention of Date
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          Veda academy shall retain user data during the term an account is
          active and for at least three years thereafter. The Company may alter
          this practice according to legal and business requirements.
        </Typography>

        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          Constent
        </Typography>

        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          By using Veda academy and/or by registration on Veda academy, you
          consent to the collection and use of the information you disclose on
          Veda in accordance with this Privacy Policy, including but not limited
          to your consent for sharing your information as per this Privacy
          Policy.
        </Typography>
        <Typography sx={{ fontSize: "17px", marginTop: "10px" }}>
          At all times, you shall also have the right to withdraw your consent
          given earlier to us to use your personal information. Such withdrawal
          of the consent is required to be sent in writing to us at the contact
          details provided in this Policy below. Provided further that, in case
          you withhold your consent and/or withdraw the same, we shall have the
          right not to provide you access to our products and services.
        </Typography>

        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          Changes to this Privacy Policy
        </Typography>
        <Typography sx={{ fontSize: "17px" }}>
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page. These changes are effective immediately after they are posted on
          this page.
        </Typography>

        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          Contact Us
        </Typography>
        <Typography sx={{ fontSize: "17px" }}>
          Should you have any grievance relating to this Privacy Policy, you may
          send in your grievances to our Grievance Officer at:
        </Typography>

        <Typography sx={{ mt: 1 }}>
          <b>Name:</b> Chandan Tiwari
        </Typography>
        <Typography sx={{ mt: 1 }}>
          <b>Address:</b> 6th Floor Plot No, 15, Tradex Tower, Sector 125,
          Noida, Gautam Buddha Nagar Uttar Pradesh 201301
        </Typography>
        <Typography sx={{ mt: 1 }}>
          <b>Email:</b> info@koshishindia.in
        </Typography>
        <Typography sx={{ fontSize: "17px", mt: 1 }}>
          In case of any other query, please feel free to reach us
          info@koshishindia.in
        </Typography>
        <Typography sx={{ fontWeight: "700", fontSize: "20px", mt: 1 }}>
          Dispute Resolution
        </Typography>
        <Typography sx={{ fontSize: "17px" }}>
          In case of any dispute, the same shall be subject to the exclusive
          jurisdiction of the courts situated at Delhi.
        </Typography>

        <Typography sx={{ fontWeight: "700", fontSize: "20px", mt: 1 }}>
          Terms & Conditions
        </Typography>

        <Typography sx={{ fontSize: "17px" }}>
          By downloading or using the app, these terms will automatically apply
          to you – you should make sure therefore that you read them carefully
          before using the app. You’re not allowed to copy, or modify the app,
          any part of the app, or our trademarks in any way. You’re not allowed
          to attempt to extract the source code of the app, and you also
          shouldn’t try to translate the app into other languages or make
          derivative versions. The app itself, and all the trademarks,
          copyright, database rights and other intellectual property rights
          related to it, still belong to Veda Academy.
        </Typography>

        <Typography sx={{ fontSize: "17px" }}>
          <b> Note:</b> Our privacy policy may change at any time without prior
          notification. To make sure that you are aware of any changes, kindly
          review the policy periodically. This Privacy Policy shall apply
          uniformly to Veda Academy applications, desktop website & mobile WAP
          site.
        </Typography>

        <Typography sx={{ fontWeight: "700", fontSize: "20px", mt: 1 }}>
          Payment Options
        </Typography>
        <Typography sx={{ fontSize: "17px" }}>
          Veda offers multiple payment modes to its customers for accepting
          payments which includes cash, cheque, debit card, credit card, PayTM,
          net banking, GooglePay, PhonePe, Razor Pay, financing options and
          swipe machines.
        </Typography>
        {/* --------------------------------------------------------------Added Arae */}
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          Families Policy
        </Typography>
        <ul>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",

              marginTop: "10px",
            }}
          >
            <b>App content:</b> Your app's content that is accessible to
            children must be appropriate for children. If your app contains
            content that is not globally appropriate, but that content is deemed
            appropriate for child users in a particular region, the app may be
            available in that region (limited regions) but will remain
            unavailable in other regions.
          </li>

          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",

              marginTop: "10px",
            }}
          >
            <b>App functionality: </b> Your app must not merely provide a
            WebView of a website or have a primary purpose of driving affiliate
            traffic to a website, regardless of ownership of the website.
            <ul sx="list-style-type:circle">
              <li>
                We are constantly exploring ways to enable new experiences for
                kids’ app developers. If you are interested in joining our
                Trusted Web App pilot for education apps, please submit your
                interest here.
              </li>
            </ul>
          </li>

          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",

              marginTop: "10px",
            }}
          >
            <b>Play Console answers: </b> You must accurately answer the
            questions in the Play Console regarding your app and update those
            answers to accurately reflect any changes to your app. This includes
            but is not limited to, providing accurate responses about your app
            in the Target Audience and Content section, Data safety section, and
            IARC Content Rating Questionnaire.
          </li>

          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            <b>Data practices: </b> You must disclose the collection of any
            personal and sensitive information from children in your app,
            including through APIs and SDKs called or used in your app.
            Sensitive information from children includes, but is not limited to,
            authentication information, microphone and camera sensor data,
            device data, Android ID, and ad usage data. You must also ensure
            that your app follows the data practices below:
            <ul sx="list-style-type:circle">
              <li style={{ marginTop: "5px" }}>
                Apps that solely target children must not transmit Android
                advertising identifier (AAID), SIM Serial, Build Serial, BSSID,
                MAC, SSID, IMEI, and/or IMSI.
              </li>
              <li style={{ marginTop: "5px" }}>
                Apps solely targeted to children should not request AD_ID
                permission when targeting Android API 33 or higher.
              </li>
              <li style={{ marginTop: "5px" }}>
                Apps that target both children and older audiences must not
                transmit AAID, SIM Serial, Build Serial, BSSID, MAC, SSID, IMEI,
                and/or IMSI from children or users of unknown age.
              </li>
              <li style={{ marginTop: "5px" }}>
                Device phone number must not be requested from Telephony Manager
                of the Android API.
              </li>
              <li style={{ marginTop: "5px" }}>
                Apps that solely target children may not request location
                permission, or collect, use, and transmit precise location.
              </li>
              <li style={{ marginTop: "5px" }}>
                Apps must use the Companion Device Manager (CDM) when requesting
                Bluetooth, unless your app is only targeting device Operating
                System (OS) versions that are not compatible with CDM.
              </li>
              <li style={{ marginTop: "5px" }}>
                APIs and SDKs: You must ensure that your app properly implements
                any APIs and SDKs.
              </li>
              <li style={{ marginTop: "5px" }}>
                Apps that solely target children must not contain any APIs or
                SDKs that are not approved for use in primarily child-directed
                services. This includes, Google Sign-In (or any other Google API
                Service that accesses data associated with a Google Account),
                Google Play Games Services, and any other API Service using
                OAuth technology for authentication and authorization.
              </li>
              <li style={{ marginTop: "5px" }}>
                Apps that target both children and older audiences must not
                implement APIs or SDKs that are not approved for use in
                child-directed services unless they are used behind a neutral
                age screen or implemented in a way that does not result in the
                collection of data from children. Apps that target both children
                and older audiences must not require users to sign-in or access
                app content through an API or SDK that is not approved for use
                in child-directed services.
              </li>
            </ul>
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            <b>APIs and SDKs:</b> You must ensure that your app properly
            implements any APIs and SDKs.
            <ul sx="list-style-type:circle">
              <li style={{ marginTop: "5px" }}>
                Apps that solely target children must not contain any APIs or
                SDKs that are not approved for use in primarily child-directed
                services.
              </li>
              <li style={{ marginTop: "5px" }}>
                For example, an API Service using OAuth technology for
                authentication and authorization whose terms of service states
                that it is not approved for use in child-directed services.
              </li>
              <li style={{ marginTop: "5px" }}>
                Apps that target both children and older audiences must not
                implement APIs or SDKs that are not approved for use in
                child-directed services unless they are used behind a neutral
                age screen or implemented in a way that does not result in the
                collection of data from children. Apps that target both children
                and older audiences must not require users to access app content
                through an API or SDK that is not approved for use in
                child-directed services.
              </li>
            </ul>
          </li>

          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            <b>Augmented Reality (AR): </b> If your app uses Augmented Reality,
            you must include a safety warning immediately upon launch of the AR
            section. The warning should contain the following:
            <ul sx="list-style-type:circle">
              <li style={{ marginTop: "5px" }}>
                An appropriate message about the importance of parental
                supervision.
              </li>
              <li style={{ marginTop: "5px" }}>
                A reminder to be aware of physical hazards in the real world
                (for example, be aware of your surroundings).
              </li>
              <li style={{ marginTop: "5px" }}>
                Your app must not require the usage of a device that is advised
                not to be used by children (for example, Daydream, Oculus).
              </li>
            </ul>
          </li>

          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            <b>Social Apps & Features: </b> If your apps allow users to share or
            exchange information, you must accurately disclose these features in
            the content rating questionnaire on the Play Console.
            <ul sx="list-style-type:circle">
              <li style={{ marginTop: "5px" }}>
                Social Apps: A social app is an app where the main focus is to
                enable users to share freeform content or communicate with large
                groups of people. All social apps that include children in their
                target audience must provide an in-app reminder to be safe
                online and to be aware of the real-world risk of online
                interaction before allowing child users to exchange freeform
                media or information. You must also require adult action before
                allowing child users to exchange personal information.
              </li>
              <li style={{ marginTop: "5px" }}>
                Social Features: A social feature is any additional app
                functionality that enables users to share freeform content or
                communicate with large groups of people. Any app that includes
                children in their target audience and has social features, must
                provide an in-app reminder to be safe online and to be aware of
                the real-world risk of online interaction before allowing child
                users to exchange freeform media or information. You must also
                provide a method for adults to manage social features for child
                users, including, but not limited to, enabling/disabling the
                social feature or selecting different levels of functionality.
                Finally, you must require adult action before enabling features
                that allow children to exchange personal information.
              </li>
              <li style={{ marginTop: "5px" }}>
                Adult action means a mechanism to verify that the user is not a
                child and does not encourage children to falsify their age to
                gain access to areas of your app that are designed for adults
                (i.e., an adult PIN, password, birthdate, email verification,
                photo ID, credit card, or SSN).
              </li>
              <li style={{ marginTop: "5px" }}>
                Social apps where the main focus of the app is to chat with
                people, they do not know must not target children. Examples
                include chat roulette style apps, dating apps, kids-focused open
                chat rooms, etc.
              </li>
            </ul>
          </li>

          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            <b>Legal compliance: </b> You must ensure that your app, including
            any APIs or SDKs that your app calls or uses, is compliant with the
            U.S. Children's Online Privacy and Protection Act (COPPA), E.U.
            General Data Protection Regulation (GDPR), and any other applicable
            laws or regulations.
          </li>
        </ul>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          Child Safety
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          PARENTS AND TEACHERS PARTICIPATION IN THE LEARNING PROCESS
        </Typography>
        <Typography
          sx={{
            mt: 2,
            fontWeight: "400",
            fontSize: "17px",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
          }}
        >
          Veda academy values the concern that a parent or teacher has towards
          their ward regarding their educational needs. Hence, Veda academy is
          constantly engaged in designing learning tools to empower all parties
          i.e. parents, teachers (mentors) and students. An active participation
          of all these groups ensures a foolproof learning solution for the
          child.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          STEPS TO START LEARNING WITH VEDA ACADEMY
        </Typography>
        <ul>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            First, register with all the details over www.Veda academy.com and
            review the demos.
          </li>

          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Ensure that your ward is well acquainted to the learning solutions
            and various tools.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Encourage your kid to do self-study.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Monitor the child's performance through reports.
          </li>
        </ul>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          WARDS WITH ACTIVELY INVOLVED PARENTS/TEACHERS
        </Typography>
        <ul>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Experience stress-free Learning experience.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Achieve consistent and long term academic success.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Adapt to a positive learning behavior.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Gain better grades in their tests and performances.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Gain analysis to their strengths & weaknesses and corrective
            measures.
          </li>
        </ul>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          INTERNET AND CHILD SAFETY
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          Internet has become an integral part of our lives and revolutionized
          our communication process. World Wide Web is a wonderful resource for
          children. At the same time, it poses challenges to your children's
          safety and privacy as well. It's important to keep updated with what
          your children see and browse on the Internet. To ensure healthy
          learning from the internet, vigilant parenting and monitored usage is
          necessary. Veda academy is committed to provide safe and enriching
          learning experience online.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          TO ENSURE CHILD SAFETY, VEDA ACADEMY AIMS TO
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          Advise parents with the basics of online safety.
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          Educate children on online safety measures.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          ADVICE TO PARENTS
        </Typography>
        <ul>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Learn: Be computer literate and learn to block objectionable
            material.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Share: Share an email account with your child to monitor messages.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Bookmark: Bookmark frequently visited sites for quick and easy
            access.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Spend time: Spend time online together to teach your children
            appropriate online behavior.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Advise: Refrain children from entering chat rooms. Be aware that
            posting messages to chat rooms reveals a user's email address to
            others.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            {" "}
            Limit: Limit their online time per day aside from a special school
            project.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Check: Check your credit card and phone bills for unfamiliar account
            charges.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Monitor: Monitor what your child did without your supervision.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Explain: Explain as how to react in case they come across
            inappropriate content.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Respond: Respond to your child when he/she reports an uncomfortable
            online experience.
          </li>
        </ul>

        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          INTERNET SAFETY TIPS
        </Typography>

        <ul>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Keep the computer in a common room of the house so that you can keep
            an eye on the computer activity.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Sit along with your children while they browse internet.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Check web history to know the sites they visit.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Switch on the Filtering and Safe-Search options.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Ask children not to share personal information (names, addresses, or
            phone numbers) on public sites.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Create a screen name to protect their real identity.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Teach children not to pirate software or other utilities.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Protect passwords and ask children not to use 'Remember me' option
            on public computers like at school or in net cafes.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Brief children about 'Report Abuse' and 'Flag' options in case they
            come across inappropriate content.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Avoid 'In-person meetings' with people they meet online.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Advise children to check the credibility and genuineness of the
            information on sites.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Install Parent Control software that helps block access to certain
            sites.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Use parent-control options to block inappropriate material.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            Follow online safety tips for a safe internet experience.
          </li>
        </ul>
        <Typography sx={{ marginTop: "15px" }}>
          It is important to show children your concern by actively
          participating in learning with Veda academy. Share the fun and
          excitement of learning in a brand new way, sit with them, appreciate
          their efforts, encourage them to explore and excel.
        </Typography>

        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          COOKIES
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          Cookie is a very small text document that includes an anonymous unique
          identifier. When a user visits a website, that site's computer asks
          user's computer for permission to store this file in his/her hard
          drive. Each website can send its own cookie to a browser if the
          browser's preferences allow it. To protect your privacy, a browser
          permits a website to access the cookies it has already sent, not the
          cookies sent by other sites.
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          Use of cookies makes web surfing easier by saving Passwords, personal
          preferences regarding use of the particular website and makes sure
          that the user don't see the same ad repeatedly. Many consider the use
          of cookies to be an industry standard. If the user chooses to have his
          own browser refuse cookies, it is possible that some areas of our Site
          will not function properly. The user may note additionally that if he
          visits Veda academy website where he is prompted to log in or that are
          customizable, he may be required to accept cookies. Advertisers and
          partners may also use cookies. Veda academy does not control use of
          these cookies and disclaim responsibility for information collected
          through them.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          HOW DO WE SECURE INFORMATION TRANSMISSIONS?
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          Email is not recognized as a secure medium of communication. For this
          reason, Veda academy requests its users to not send private
          information to it by email. This Site does not, however, provide
          facilities for secure transmission of information across the Internet.
          The user should be aware that there are inherent risks associated with
          the transmissions of Personal Information via Internet.
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          If the user does not wish to use Internet to transmit personal
          information, he can mail/phone Veda academy. Details are provided
          under 'How to Contact us'.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          HOW CAN YOU ACCESS AND CORRECT YOUR INFORMATION?
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          Email is not recognized as a secure medium of communication. Users
          may, by calling Veda academy, access their personally identifiable
          information that Veda academy collects online and maintains in its
          database.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          CERTAIN DISCLOSURES
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          Veda academy may disclose a user’s personal information if required to
          do so by law or subpoena or if it believes that such action is
          necessary to (a) conform to the law or comply with legal process
          served on it or affiliated parties; (b) protect and defend its rights
          and property, its site, the users of our site, and/or it affiliated
          parties; (c) act under circumstances to protect the safety of users of
          the site, us, or third parties.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          WHAT ABOUT OTHER WEBSITES LINKED TO OUR WEBSITE?
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          Veda academy is neither responsible for practices employed by Websites
          linked to or from the Website nor the information/content contained
          therein. Often links to other Websites are provided solely as pointers
          to information on topics that may be useful to the users of the
          Website.
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          The user should remember that when a link is used to go from Veda
          academy’ Website to another, Veda academy’ Privacy Policy is no longer
          in effect. The user’s browsing and interaction on any other Website,
          including Websites which have a link on Veda academy’ Website, is
          subject to that Website's own rules and policies. The user read over
          those rules and policies before proceeding.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          CHILDERN'S PRIVACY
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          Veda academy does not collect personal information from children below
          the age of 15. Upon realizing that a child is aged below 15, it
          deletes their personal information from the systems. Even for personal
          information of children aged 15 years and above, information is
          collected only for the purpose of educational requirement and service
          needs as provided by this website.
        </Typography>
        <Typography
          sx={{ fontWeight: "700", fontSize: "20px", marginTop: "15px" }}
        >
          SPAMMING
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          Sending unsolicited mail messages, including, without limitation,
          commercial advertising and informational announcements, is expressly
          prohibited. A user shall not use another site's mail server to relay
          mail without the express permission of the site.
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          It is contrary to Veda academy’ policy for customers to use its
          servers to effect or participate in any of the following activities:{" "}
        </Typography>
        <Typography sx={{ marginTop: "15px" }}>
          Upon learning about an alleged violation of its Acceptable Use Policy,
          Veda academy will initiate an investigation. During the investigation
          Veda academy may restrict Customer's access in order to prevent
          further possible unauthorised activity. Depending on the severity of
          the violation Veda academy may, at its sole discretion, restrict,
          suspend, or terminate Customer's account and pursue other civil
          remedies. If such violation is a criminal offence, Veda academy will
          notify the appropriate law enforcement department of such violation.{" "}
        </Typography>
        <ul>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            To post in any Usenet or other newsgroup, forum, e-mail mailing list
            or other similar groups or list articles which are off-topic
            according to the agreement or other owner-published FAQ or
            description of the group or list.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            To send unsolicited mass e-mailings, if such unsolicited e-mailings
            provoke complaints from the recipients.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            To engage in any of the foregoing activities using the service of
            another provider, but channeling such activities through Veda
            academy provided server, or using Veda academy provided server as a
            mail drop for responses.
          </li>
          <li
            style={{
              fontWeight: "400",
              fontSize: "17px",
              lineHeight: "25px",
              textAlign: "justify",
              color: "#333333",
            }}
          >
            To falsify user information provided to Veda academy or to other
            users of the service in connection with use of Veda academy service.
          </li>
        </ul>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
