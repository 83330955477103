import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Aos from "aos";
import { courseDetails } from "./data";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Banner01 from "../../assets/images/english/banner01.png";
import BannerMobile01 from "../../assets/images/english/banner-mobile.png";
import { useMediaQuery, useTheme } from "@mui/material";
import "./style.css";

function LearnEnglishOnline() {
  const theme = useTheme();
  const isSmDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [track, setTrack] = useState(5);
  const [imageSize, setImageSize] = useState({ width: 500, height: 500 });

  const changeHandle = (newValue) => {
    setTrack(newValue);
  };

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setImageSize({ width: 250, height: 250 });
      } else if (window.innerWidth <= 800) {
        setImageSize({ width: 400, height: 400 });
      } else {
        setImageSize({ width: 500, height: 500 });
      }
    };

    // Attach the event listener on component mount
    window.addEventListener("resize", handleResize);

    // Call the handleResize initially to set the initial image size
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box sx={{ position: "relative" }}>
              <Box sx={{ xs: "auto", sm: "500px", md: "550px" }}>
                <LazyLoadImage
                  alt="Slider image"
                  effect="blur"
                  src={isSmDevice ? BannerMobile01 : Banner01}
                  width="100%"
                  height={isSmDevice ? "auto" : "550px"}
                  borderRadius="16px"
                  objectFit="cover"
                />
              </Box>
              <Box
                sx={{
                  mt: { xs: -1, sm: 0 },
                  position: { xs: "relative", sm: "absolute", md: "absolute" },
                  top: { xs: 0, sm: 65, md: 45 },
                  left: { xs: 0, sm: 25, md: 30 },
                  paddingTop: { xs: "10px", sm: "10px", md: "5px" },
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: {
                    xs: "center",
                    sm: "flex-start",
                    md: "flex-start",
                  },
                  alignItems: {
                    xs: "center",
                    sm: "flex-start",
                    md: "flex-start",
                  },
                  backgroundColor: {
                    xs: "#ea5806",
                    sm: "transparent",
                    md: "transparent",
                  },
                }}
                //data-aos="fade-right"
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { xs: "32px", sm: "35px", md: "55px" },
                    lineHeight: { xs: "45px", sm: "50px", md: "62px" },
                    fontWeight: 600,
                    color: { xs: "#fff", sm: "#fff", md: "#fff" },
                    textAlign: { xs: "center", sm: "left", md: "left" },
                  }}
                >
                  Achieve Your <br />
                  <span style={{ color: "#fcde5a" }}>Language</span> Goals
                  <br />
                  Faster With <span style={{ color: "#fcde5a" }}>
                    Veda
                  </span>{" "}
                  <br />
                  <span style={{ color: "#fcde5a" }}>Academy</span>
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    my: 2,
                    color: { xs: "#fff", sm: "#fff", md: "#fff" },
                  }}
                >
                  Build English fluency effortlessly,
                  <br />
                  open doors to new opportunities
                </Typography>

                <Box
                  sx={{
                    width: { sm: "42%", md: "36%", lg: "26%" },
                    height: "48px",
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: { xs: "15px" },
                    mb: { xs: "10px" },
                  }}
                >
                  {/*<SearchIcon /> */}
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#fff",
                      mr: 1,
                      ml: 2,
                    }}
                  >
                    +91
                  </Typography>
                  <input
                    className="white-placeholder"
                    placeholder="Mobile Number"
                    style={{
                      width: "49%",
                      border: "none",
                      outline: "none",
                      backgroundColor: "transparent",
                      color: "white",
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      width: "38%",
                      background: "#fcde5a",
                      fontSize: { xs: "10px", sm: "12px", md: "12px" },
                      borderRadius: "0px 15px 15px 0px",
                      color: "black",
                      fontWeight: "600",
                      padding: {
                        xs: "14px 20px",
                        sm: "13px 20px",
                        md: "13px 20px",
                        lg: "13px 22px",
                      },
                      "&:hover": {
                        backgroundColor: "#f5dd42",
                      },
                      textTransform: "none",
                    }}
                  >
                    Join for Free
                  </Button>
                </Box>

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    lineHeight: "23px",
                    fontWeight: 400,
                    my: 0,
                    pb: { xs: 2, sm: 1, md: 0 },
                    color: "#fff",
                    color: { xs: "#fff", sm: "#fff", md: "#fff" },
                  }}
                >
                  We will send an otp for verification
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          width: "100%",
          position: "relative",
          marginTop: { xs: "10px", sm: "-45px", md: "-43px" },
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            width: { xs: "100%", md: "80%" },
            margin: "auto",
            backgroundColor: "#fcde5a",
            borderRadius: "12px",
            py: { xs: 0, sm: 2 },
            px: 2,
          }}
        >
          {courseDetails?.map((item, ind) => {
            return (
              <Grid item xs={4} sm={4} md={4}>
                <Box
                  key={ind + "bottomcontent"}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: { xs: 4, md: 2 },
                    borderRight: item.border,
                    mr: 2,
                  }}
                >
                  {/*<Box>
                    <img
                      src={item.img}
                      alt="icons"
                      width="50px"
                      height="50px"
                    />
                </Box> */}
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "23px",
                        fontWeight: "600",
                        color: "black",
                        pr: 2,
                        pt: 4,
                        pb: 4,
                      }}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}

export default LearnEnglishOnline;
