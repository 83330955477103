import React, { useEffect } from "react";
import { Container, Stack, Box } from "@mui/system";
import { Grid, Button, Typography } from "@mui/material";
import appStore from "../assets/images/appleStore.png";
import googleStore from "../assets/images/googleStore.png";
import mobileViewOne from "../assets/images/mobileViewOne.png";
import mobileViewTwo from "../assets/images/mobileViewTwo.png";
import Aos from "aos";
import "aos/dist/aos.css";
function AppStoreSection() {
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <Box sx={{ backgroundColor: "#f77831", mt: 2 }}>
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ padding: { sm: 10, xs: 1.5 } }}
            data-aos="fade-right"
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "40px",
                fontWeight: 500,
                lineHeight: "48px",
                color:"#fff"
              }}
            >
              Get the <br /> learning app
            </Typography>

            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                lineHeight: "26px",
                mt: 3,
                color:"#fff"
              }}
            >
              Download lessons and learn anytime, <br /> anywhere with the Veda
              app
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                rowGap: "3px",
                columnGap: "50px",
                mt: 3,
              }}
            >
              <a
                href="https://apps.apple.com/in/app/veda-academy/id6448278906"
                rel="noreferrer"
                target="_blank"
                style={{
                  color: "white",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <img src={appStore} width="159px" height="45px" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.koshish.veda&pli=1"
                rel="noreferrer"
                target="_blank"
                style={{
                  color: "white",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <img src={googleStore} width="159px" height="45px" />
              </a>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} data-aos="fade-up">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                columnGap: "50px",
                justifyContent: "center",
                alignItems: "end",
                flexWrap: "wrap",
                mt: 8,
              }}
            >
              <img
                src={mobileViewOne}
                width="240.88px"
                maxWidth="100%"
                maxHeight="521.58px"
              />
              <img
                src={mobileViewTwo}
                width="220.59px"
                maxWidth="100%"
                maxHeight="477.65px"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default AppStoreSection;
