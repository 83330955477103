import React from "react";
import Slider from "react-slick";
import { Grid, Card, Box, Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { feedbackSlider } from "./data";
import { useMediaQuery, useTheme } from "@mui/material";

function StudentsFeedback() {
  const theme = useTheme();
  const isSmDevice = useMediaQuery(theme.breakpoints.down("sm"));

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Grid container>
      <Grid xs={12} md={12}>
        <div
          className="slider2"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <Slider {...settings}>
            {feedbackSlider?.map((item, ind) => {
              return (
                <Box
                  key={ind + "inspire"}
                  sx={{
                    height: { xs: "auto", sm: "400px", md: "450px" },
                    borderRadius: "16px",
                    mt: 3,
                    pr: 2,
                    ml: { xs: "0px", sm: "10px" },
                    // border: "2px solid rgb(234, 234, 234)",
                  }}
                >
                  <Card
                    sx={{
                      width: { xs: "100%", sm: "97%" },
                      height: "100%",
                      borderRadius: "16px",
                      backgroundColor: item.bgColor,
                      border: "none",
                      boxShadow: "none",
                      padding: "15px",
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <LazyLoadImage
                      alt="Slider image"
                      effect="blur"
                      //   src={isSmDevice ? BannerMobile01 : Banner01}
                      src={item.img}
                      width="100%"
                      height="auto"
                      borderRadius="16px"
                      objectFit="cover"
                    />
                  </Card>
                </Box>
              );
            })}
          </Slider>
        </div>
      </Grid>
    </Grid>
  );
}

export default StudentsFeedback;
