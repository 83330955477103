import React from "react";
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


function BookdemoSection(props) {
    const { backgroundColor } = props;
    return (
        <Stack
            sx={{
                pt: 7,
                pb: 7,
                mt: 3,
                maxWidth: "100%",
                // borderTop: '1.5px solid grey', borderBottom: '1.5px solid grey'
                backgroundColor: backgroundColor,
            }}
        >
            <Container>
                <Typography
                    sx={{
                        fontFamily: "Poppins",
                        fontSize: "34px",
                        lineHeight: "45px",
                        fontWeight: 600,
                        textAlign: "center",
                    }}
                >
                    Achieve more with Veda, get{" "}
                    <Typography variant="span" sx={{ color: "#FF8331" }}>
                        Free
                    </Typography>{" "}
                    online counselling now
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "40px",
                        rowGap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                        mt: 3,
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "25.6px",
                            borderRadius: "8px",
                            color: "#FFFFFF",
                            padding: {xs:'1rem 4rem 1rem 4rem',sm:'1rem 7rem 1rem 7rem'},
                            background: "#FF8331",
                            "&:hover": { background: "#DC5F19" },
                        }}
                    >
                        Book a demo
                    </Button>
                    <Button
                        variant="outlined"
                        href="https://web.vedaacademy.org.in/register"
                        sx={{
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: "16px",
                            color: "black",
                            borderRadius: "8px",
                            padding: {xs:'1rem 4.8rem 1rem 4.8rem',sm:'1rem 7rem 1rem 7rem'},
                            border: "1px solid #ECEAEA",
                            background: "#FFFFFF",
                            "&:hover": { border: "1px solid black", background: "#FFFFFF" },
                        }}
                    >
                        Learn LIVE
                    </Button>
                </Box>
            </Container>
        </Stack>
    );
}

export default BookdemoSection;
