import React from "react";
import { Container, Stack, Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import TopCarousel from "./TopCarousel";
import { Typography } from "@mui/material";
import RightContents from "./RightContents";
import "./style.css";

function ModalPaperMaterial() {
  return (
    <Box sx={{ backgroundColor: "#f1f1f1" }}>
      <Container>
        <Grid container spacing={1}>
          <Grid xs={12} sm={8}>
            <TopCarousel />
            <Box
              sx={{
                backgroundColor: "#fff",
                ml: "11px",
                mr: "13px",
                pb: 1,
                mb: 3,
                minHeight: "88.5%",
              }}
            >
              <Box sx={{ ml: 2, pt: 4 }}>
                <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                  NCERT SOLUTIONS FOR CBSC CLASS 1 TO 12-FREE PDF DOWNLOAD
                </Typography>
                <Typography sx={{ fontSize: "15px", mt: 2 }}>
                  NCERT Solutions for Class 12 Physics – Free PDF Download
                </Typography>
                <Typography sx={{ fontSize: "15px", mt: 1, mr: 2 }}>
                  NCERT Solutions for Class 12 Physics is the best study
                  material for students who find difficulty in answering the
                  questions from the textbook. The solutions are created in a
                  comprehensive manner in order to help students grasp the
                  concepts faster. The main aim of preparing the solutions is to
                  make the exam preparation easier for the students. The subject
                  matter experts design the NCERT Solutions in an
                  easy-to-understand manner to boost confidence among students
                  to face the board exams without fear. By making use of these
                  solutions, students will be able to identify the concepts of
                  importance based on the marks weightage allotted by the CBSE
                  board.
                </Typography>
                <Typography sx={{ fontSize: "15px", mt: 1 }}>
                  The solutions of all chapters are available here for the
                  perusal of CBSE students. To access these free NCERT Physics
                  Class 12 Solutions PDF Chapters 1-15, follow the links
                  tabulated below. The solutions provided here are free to view
                  online or to download in a PDF format. To download a PDF of
                  the NCERT Solutions for Class 12 Physics for a specific
                  chapter, click the “Download PDF” button, which is provided at
                  the top of each of the chapter pages listed below.
                </Typography>
                <Typography sx={{ fontSize: "15px", mt: 3, fontWeight: 600 }}>
                  Detailed overview of NCERT Solutions
                </Typography>
              </Box>

              <Box sx={{ ml: 2, mt: 2 }}>
                <table className="table1">
                  <thead>
                    <tr>
                      <td className="left">Catogory:</td>
                      <td className="right">NCERT Solutions</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="left">Content Type:</td>
                      <td className="right">Text,Images, Videos and PDF</td>
                    </tr>
                    <tr>
                      <td className="left">Classes</td>
                      <td className="right">Class 1 to Class 12</td>
                    </tr>
                    <tr>
                      <td className="left">Accademic Session</td>
                      <td className="right">2024-25</td>
                    </tr>
                    <tr>
                      <td className="left">Medium Used</td>
                      <td className="right">Hindi and English Medium</td>
                    </tr>
                    <tr>
                      <td className="left">Subjects</td>
                      <td className="right">
                        Physics,Chemistry,Biology,Maths, Science hindi, English,
                        Hindi, Social,etc.
                      </td>
                    </tr>
                    <tr>
                      <td className="left">Available</td>
                      <td className="right">
                        &#x2022; Chapter-Wise Solutions
                        <br />
                        &#x2022; Exercise-Wise Solutions
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>

              <Box sx={{ ml: 2, mt: 10 }}>
                <table className="table2">
                  <caption
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      marginBottom: "15px",
                    }}
                  >
                    Detailed overview of NCERT Solutions
                  </caption>
                  <thead>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ height: "20px", backgroundColor: "#0C5395" }}
                      ></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="left">
                        Chapter 1: Electric charge and fields
                      </td>
                      <td className="right">
                        Chapter 7: Electric charge and fields
                      </td>
                    </tr>
                    <tr>
                      <td className="left">
                        Chapter 2: Electric charge and fields
                      </td>
                      <td className="right">
                        Chapter 8: Electric charge and fields
                      </td>
                    </tr>
                    <tr>
                      <td className="left">
                        Chapter 3: Electric charge and fields
                      </td>
                      <td className="right">
                        Chapter 9: Electric charge and fields
                      </td>
                    </tr>
                    <tr>
                      <td className="left">
                        Chapter 4: Electric charge and fields
                      </td>
                      <td className="right">
                        Chapter 10: Electric charge and fields
                      </td>
                    </tr>
                    <tr>
                      <td className="left">
                        Chapter 5: Electric charge and fields
                      </td>
                      <td className="right">
                        Chapter 11: Electric charge and fields
                      </td>
                    </tr>
                    <tr>
                      <td className="left">
                        Chapter 6: Electric charge and fields
                      </td>
                      <td className="right">
                        Chapter 12: Electric charge and fields
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={4}>
            <RightContents />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ModalPaperMaterial;
