import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { cartData } from "../booksJson";
import Grid from "@mui/material/Grid";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useMediaQuery, useTheme } from "@mui/material";

function OrderBooks({
  setProductItems,
  updateOrderItems,
  cartItems,
  setCartItems,
  updateCartItems,
}) {
  const theme = useTheme();
  const isSmDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const updatedProdData = JSON.parse(localStorage.getItem("prodData"));

  const totalSum = cartItems.reduce(
    (accumulator, item) => accumulator + item?.amount * item?.quantity,
    0
  );

  const handleCheckout = () => {
    localStorage.removeItem("prodItem");
    const updatedProdItem = JSON.parse(localStorage.getItem("prodItem"));
    updateOrderItems(updatedProdItem);
    navigate("/books/checkout");
  };

  const handleQuantity = (e, prodId) => {
    setQuantity(e.target.value);

    const updatedProductItems = cartItems?.map((product) => {
      if (product.id === prodId) {
        return { ...product, quantity: e.target.value };
      } else {
        return product;
      }
    });

    try {
      localStorage.setItem("prodData", JSON.stringify(updatedProductItems));
      const updatedData = JSON.parse(localStorage.getItem("prodData"));
      updateCartItems(updatedData);
    } catch (error) {
      console.error("Error saving data to localStorage:", error);
    }
  };

  return (
    <Box>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Typography variant="h5" sx={{ fontWeight: "600" }}>
              Shopping Cart
            </Typography>
            <Box sx={{ mb: 4, mt: 8 }}>
              <Box sx={{ width: "90%" }}>
                {updatedProdData?.length > 0 &&
                  updatedProdData?.map((item, index) => (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100px",
                        display: "flex",
                        flexDirection: "row",
                        borderBottom: "1px solid #ccc",
                        pb: 1,
                        mb: 4,
                      }}
                      key={index + "cart"}
                    >
                      <Box sx={{ width: "100px", mr: 2 }}>
                        <img
                          src={item?.img}
                          alt="thumbnail"
                          width="100%"
                          height="100%"
                          style={{
                            objectFit: "cover",
                            objectPosition: "center center",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <Typography sx={{ fontSize: "15px" }}>
                              {item?.title}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography sx={{ fontSize: "15px" }}>
                              ₹ {item?.amount * item?.quantity}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Typography sx={{ fontSize: "14px" }}>
                              Qty
                            </Typography>
                            <select
                              style={{
                                width: "50px",
                                height: "35px",
                                fontSize: "16px",
                                paddingLeft: "3px",
                              }}
                              onChange={(e) => handleQuantity(e, item.id)}
                              value={item?.quantity}
                            >
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                            </select>
                          </Box>
                          <Box>
                            <Typography sx={{ fontSize: "14px" }}>
                              Remove
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                width: "90%",
                display: "flex",
                flexDirection: "column",
                mt: 10,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography sx={{ fontWeight: "600", fontSize: "15px" }}>
                    Subtotal
                  </Typography>
                </Box>
                <Typography sx={{ fontSize: "14px" }}>₹ {totalSum}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ fontWeight: "600", fontSize: "15px", mb: 1, mt: 1 }}
                >
                  Total Items in Cart
                </Typography>
                <Box>
                  <Typography sx={{ fontSize: "14px", mt: 1, mb: 1 }}>
                    {cartItems?.length} item
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography sx={{ fontSize: "13px" }}>
              Shipping and taxes calculated at checkout.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{ width: "100%", mt: 4, mb: 2 }}
                onClick={handleCheckout}
              >
                Checkout
              </Button>
              <Link
                to="/english"
                style={{ textDecoration: "none", marginBottom: "35px" }}
              >
                Continue Shopping →
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default OrderBooks;
