import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  Menu,
  MenuItem,
  List,
  Hidden,
  Drawer,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { Container, Stack, Box } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TransperantLogo from "../assets/logo/TransperantLogo.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CallIcon from "@mui/icons-material/Call";
import MenuIcon from "@mui/icons-material/Menu";
import { urlData } from "../pages/Jnv/data";

function NavBarJNV({ urlPath }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectTab, setSelectTab] = useState("home");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSpoken, setAnchorElSpoken] = useState(null);
  const [anchorElJNV, setAnchorElJNV] = useState(null);
  const open = Boolean(anchorEl);
  const openSpoken = Boolean(anchorElSpoken);
  const openJNV = Boolean(anchorElJNV);

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSpoken = (event) => {
    setAnchorElSpoken(event.currentTarget);
    navigate("/english");
  };

  const handleClickJNV = (event) => {
    setAnchorElJNV(event.currentTarget);
    console.log("event.currentTarget...", event.currentTarget);
    window.open("/jnv", "_blank");
    // navigate("/jnv");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSpoken = () => {
    setAnchorElSpoken(null);
  };

  const handleCloseJNV = () => {
    setAnchorElJNV(null);
  };

  const contactClick = () => {
    window.open("/contact", "_blank");
    // navigate("/contact");
  };

  return (
    <Container
      sx={{
        maxWidth: {
          xs: "100%",
          lg: "1700px",
          backgroundColor: urlData?.includes(urlPath) ? "#f1f1f1" : "#fff",
        },
      }}
    >
      <AppBar
        position="static"
        sx={{
          background: urlData?.includes(urlPath) ? "#f1f1f1" : "#fff",
          // height: "73.6px",
          height: "auto",
          boxShadow: "none !important",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: { xs: "space-between", lg: "space-evenly" },
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {/* Image */}
          <img
            src={TransperantLogo}
            width="100px"
            height="90px"
            alt="logo"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
          {/* All Navbar Content but when screen size less then md then hide */}
          <Hidden mdDown>
            {/* -----------------------------------------------------------Section 01 */}
            <Stack direction="row" spacing={2}>
              <Button
                sx={{
                  textTransform: "none",
                  color: "black",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  // backgroundColor: selectTab === "home" ? "lavender" : "",
                }}
                onClick={() => {
                  navigate("/jnv");
                  setSelectTab("home");
                }}
              >
                Home
              </Button>

              <div style={{ marginBottom: "10px" }}>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  // variant="outlined"
                  // color="warning"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  sx={{
                    textTransform: "none",
                    color: "black",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: 500,
                    // border:
                    //   selectTab == "k-12" || selectTab == "english"
                    //     ? "1px solid #FF8331"
                    //     : "1px solid black",
                    border: "1px solid black",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  Courses
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate("/k-12");
                      setSelectTab("k-12");
                    }}
                    disableRipple
                  >
                    K-12
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate("/english");
                      setSelectTab("english");
                    }}
                    disableRipple
                  >
                    Spoken English
                  </MenuItem>
                </StyledMenu>
              </div>

              <Button
                sx={{
                  textTransform: "none",
                  color: "black",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  // backgroundColor: selectTab === "admitcard" ? "lavender" : "",
                }}
                onClick={() => {
                  navigate("/jnv");
                  setSelectTab("admitcard");
                }}
              >
                Admit Card
              </Button>

              <Button
                sx={{
                  textTransform: "none",
                  color: "black",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  // backgroundColor: selectTab === "results" ? "lavender" : "",
                }}
                onClick={() => {
                  navigate("/jnv");
                  setSelectTab("results");
                }}
              >
                Results
              </Button>

              <Button
                sx={{
                  textTransform: "none",
                  color: "black",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  // backgroundColor: selectTab === "form" ? "lavender" : "",
                }}
                onClick={() => {
                  navigate("/jnv");
                  setSelectTab("form");
                }}
              >
                Form
              </Button>

              <Button
                sx={{
                  textTransform: "none",
                  color: "black",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  // backgroundColor: selectTab === "study" ? "lavender" : "",
                }}
                onClick={() => {
                  // navigate("/jnv/freestudy");
                  setSelectTab("study");
                }}
              >
                Free Study Material
              </Button>

              <Button
                sx={{
                  textTransform: "none",
                  color: "black",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  // backgroundColor: selectTab === "blog" ? "lavender" : "",
                }}
                onClick={() => {
                  navigate("/jnv");
                  setSelectTab("blog");
                }}
              >
                Blog
              </Button>

              <Button
                sx={{
                  textTransform: "none",
                  color: "black",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  // backgroundColor: selectTab === "contact" ? "lavender" : "",
                }}
                onClick={() => {
                  contactClick();
                  // setSelectTab("contact");
                }}
              >
                Contact
              </Button>
            </Stack>

            <Stack direction="row" spacing={2}>
              <a
                href="https://web.vedaacademy.org.in/register"
                rel="noreferrer"
                target="_parent"
                style={{ color: "white", textDecoration: "none" }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: "black",
                    textTransform: "none",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "12px",
                    padding: "7px 20px",
                    border: "1px solid #ECEAEA",
                    "&:hover": { border: "1px solid black" },
                  }}
                >
                  Sign in
                </Button>
              </a>
              <a
                href="https://web.vedaacademy.org.in/register"
                rel="noreferrer"
                target="_parent"
                style={{ color: "white", textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    borderRadius: "8px",
                    fontSize: "12px",
                    background: "#FF8331",
                    padding: "7px 20px",
                    "&:hover": { background: "#DC5F19" },
                  }}
                >
                  Register
                </Button>
              </a>
            </Stack>
          </Hidden>

          {/* Menu Icon visible only when screen size less then md */}
          <Hidden mdUp>
            <IconButton
              edge="start"
              color="black"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>

        <Hidden mdUp>
          <div style={{ width: "300px" }}>
            <Drawer
              anchor="right"
              open={openDrawer}
              onClose={handleDrawerToggle}
              PaperProps={{ style: { width: "300px" } }}
            >
              <Box textAlign={"end"}>
                {" "}
                <HighlightOffIcon
                  onClick={handleDrawerToggle}
                  sx={{ m: 2 }}
                  color="warning"
                />
              </Box>
              <List>
                <Stack direction="column" alignItems={"center"} spacing={3}>
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 500,
                      // backgroundColor: selectTab === "home" ? "lavender" : "",
                    }}
                    onClick={() => {
                      navigate("/jnv");
                      setSelectTab("home");
                    }}
                  >
                    Home
                  </Button>

                  <div style={{ marginBottom: "10px" }}>
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      // variant="outlined"
                      // color="warning"
                      disableElevation
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                      sx={{
                        textTransform: "none",
                        color: "black",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: 500,
                        // border:
                        //   selectTab == "k-12" || selectTab == "english"
                        //     ? "1px solid #FF8331"
                        //     : "1px solid black",
                        border: "1px solid black",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      Courses
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          navigate("/k-12");
                          setSelectTab("k-12");
                        }}
                        disableRipple
                      >
                        K-12
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          navigate("/english");
                          setSelectTab("english");
                        }}
                        disableRipple
                      >
                        Spoken English
                      </MenuItem>
                    </StyledMenu>
                  </div>

                  <Button
                    sx={{
                      textTransform: "none",
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 500,
                      // backgroundColor: selectTab === "admitcard" ? "lavender" : "",
                    }}
                    onClick={() => {
                      navigate("/jnv");
                      setSelectTab("admitcard");
                    }}
                  >
                    Admit Card
                  </Button>

                  <Button
                    sx={{
                      textTransform: "none",
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 500,
                      // backgroundColor: selectTab === "results" ? "lavender" : "",
                    }}
                    onClick={() => {
                      navigate("/jnv");
                      setSelectTab("results");
                    }}
                  >
                    Results
                  </Button>

                  <Button
                    sx={{
                      textTransform: "none",
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 500,
                      // backgroundColor: selectTab === "form" ? "lavender" : "",
                    }}
                    onClick={() => {
                      navigate("/jnv");
                      setSelectTab("form");
                    }}
                  >
                    Form
                  </Button>

                  <Button
                    sx={{
                      textTransform: "none",
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 500,
                      // backgroundColor: selectTab === "study" ? "lavender" : "",
                    }}
                    onClick={() => {
                      navigate("/jnv");
                      setSelectTab("study");
                    }}
                  >
                    Free Study Material
                  </Button>

                  <Button
                    sx={{
                      textTransform: "none",
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 500,
                      // backgroundColor: selectTab === "blog" ? "lavender" : "",
                    }}
                    onClick={() => {
                      navigate("/jnv");
                      setSelectTab("blog");
                    }}
                  >
                    Blog
                  </Button>

                  <Button
                    sx={{
                      textTransform: "none",
                      color: "black",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 500,
                      // backgroundColor: selectTab === "contact" ? "lavender" : "",
                    }}
                    onClick={() => {
                      contactClick();
                      // setSelectTab("contact");
                    }}
                  >
                    Contact
                  </Button>
                </Stack>
                <Stack
                  direction="column"
                  alignItems={"center"}
                  mt={2}
                  spacing={3}
                >
                  <a
                    href="https://web.vedaacademy.org.in/register"
                    rel="noreferrer"
                    target="_parent"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        color: "black",
                        borderRadius: "8px",
                        padding: "10px 20px",
                        border: "1px solid #ECEAEA",
                        fontSize: "12px",
                        padding: "7px 20px",
                        "&:hover": { border: "1px solid black" },
                      }}
                    >
                      Sign in
                    </Button>
                  </a>
                  <a
                    href="https://web.vedaacademy.org.in/register"
                    rel="noreferrer"
                    target="_parent"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        borderRadius: "8px",
                        background: "#FF8331",
                        padding: "7px 20px",
                        fontSize: "12px",
                        mb: 2,
                        "&:hover": { background: "#DC5F19" },
                      }}
                    >
                      Register
                    </Button>
                  </a>
                </Stack>
              </List>
            </Drawer>
          </div>
        </Hidden>
      </AppBar>
    </Container>
  );
}

export default NavBarJNV;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
