import * as yup from "yup";

export const _initialValues = {
  fullname: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  pin: "",
  addressType: "",
  email: "",
};

export const _validation = yup.object().shape({
  fullname: yup.string().required("Field is required"),
  phone: yup.string().required("Field is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Field is required"),
  address: yup.string().required("Field is required"),
  city: yup.string().required("Field is required"),
  state: yup.string().required("Field is required"),
  pin: yup.string().required("Field is required"),
  addressType: yup.string().required("Field is required"),
});
