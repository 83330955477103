import React from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "./style.css";

function DemoSection(props) {
  const { backgroundColor } = props;

  const startLearn = () => {
    let url = "https://web.vedaacademy.org.in/register";
    window.location.href = url;
  };

  return (
    <Container>
      <Box
        sx={{
          pt: 3,
          pb: 3,
          mt: 3,
          maxWidth: "100%",
          backgroundImage: "linear-gradient(to top, #FF8D4D, #EA5806)",
          borderRadius: "16px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: { xs: "center", sm: "center", md: "space-between" },
          flexDirection: "row",
          alignItems: "center",
          px: 4,
        }}
      >
        <Box
        //data-aos="fade-left"
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "25px", sm: "30px" },
              lineHeight: "38px",
              fontWeight: 600,
              mb: 2,
              mt: 2,
              color: "#fff",
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            Speak to our academic counsellor
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "18px",
              lineHeight: "30px",
              mb: 2,
              color: "#fff",
            }}
          >
            Access to India’s best teachers with a record of producing top
            <br />
            rankers year on year
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              columnGap: "40px",
              rowGap: "10px",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              margin: { xs: "auto", sm: "auto", md: "0" },
              mt: { xs: "15px", sm: "20px", md: "0px" },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
                height: "48px",
                backgroundColor: "#fff",
                border: "1px solid #fff",
                borderRadius: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#000",
                  mr: 1,
                  ml: 2,
                }}
              >
                +91
              </Typography>
              <input
                className="white-placeholder"
                placeholder="Mobile Number"
                style={{
                  width: "100%",
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                  color: "#000",
                }}
              />
              <Button
                variant="contained"
                sx={{
                  width: "55%",
                  background: "green",
                  fontSize: { xs: "10px", sm: "12px", md: "12px" },
                  borderRadius: "0px 15px 15px 0px",
                  padding: {
                    xs: "14px 20px",
                    sm: "13px 20px",
                    md: "13px 20px",
                    lg: "13px 22px",
                  },
                  "&:hover": {
                    backgroundColor: "#008035",
                  },
                  textTransform: "none",
                }}
              >
                Book Now
              </Button>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ color: "#fff", fontSize: "11px" }}>
              We will send an otp for verification
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default DemoSection;
