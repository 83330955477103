import { createSlice, isAnyOf, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

const initialState = {
  paymentstatusLoader: false,
  paymentstatus: {},
};

const PaymentStatusSlice = createSlice({
  name: "Payment Status",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getPaymentStatusAsync.pending), (state) => {
      state.paymentstatusLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getPaymentStatusAsync.fulfilled),
      (state, action) => {
        state.paymentstatusLoader = false;
        state.paymentstatus = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getPaymentStatusAsync.rejected),
      (state, action) => {
        state.paymentstatusLoader = false;
      }
    );
  },
  reducers: {},
});

export const {} = PaymentStatusSlice.actions;

export default PaymentStatusSlice.reducer;

export const getPaymentStatusAsync = createAsyncThunk(
  "mobile/getPaymentStatus",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/getPaymentStatus`, payload, toolkit);
  }
);
