import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { displayDataTeacherSection as displayData } from "./constant";
import Aos from "aos";

function TeachersSection() {
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  return (
    <Container>
      <div>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "32px",
            fontWeight: 600,
            lineHeight: "40px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          All teachers teach.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "32px",
            fontWeight: 600,
            lineHeight: "40px",
            mb: 5,
          }}
        >
          Greatest&nbsp;{" "}
          <Typography variant="span" sx={{ color: "#FF8331" }}>
            teachers
          </Typography>
          &nbsp; inspire
        </Typography>
      </div>
      <Box
        sx={{
          backgroundColor: "#F8F8F8",
          display: "flex",
          flexDirection: "row",
          columnGap: "20px",
          flexWrap: "wrap",
          mb: 5,
        }}
      >
        {displayData.map((ev) => (
          <Box
            key={ev.id}
            sx={{
              display: "flex",
              flexDirection: "row",
              columnGap: "10px",
              justifyTracks: "center",
              alignItems: "center",
              p: 3,
            }}
          >
            <img src={ev.img} width="60.01px" height="49.55px" />
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                lineHeight: "22.4px",
                // maxWidth: '235.11px'
                maxWidth: "auto",
              }}
            >
              {ev.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Container>
  );
}

export default TeachersSection;
