import React from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import shImage from "../../assets/images/english/shImage.png";

function SHQuestion() {
  return (
    <Container>
      <Box
        sx={{
          backgroundColor: "#EEF3FC",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "16px",
          padding: "40px 20px 40px 20px",
        }}
      >
        <img
          src={shImage}
          alt="top image"
          width="120px"
          height="56px"
          //data-aos="fade-down"
        />
        <Typography
          //data-aos="fade-down"
          sx={{ mt: 3, fontSize: "20px", fontWeight: "600", color: "#212121" }}
        >
          Still have questions?
        </Typography>
        <Typography
          //data-aos="fade-down"
          sx={{
            mt: 2,
            mb: 3,
            fontSize: "18px",
            fontWeight: "400",
            color: "#67696C",
          }}
        >
          Can’t find the answer you’re looking for? Please chat to our friendly
          team.
        </Typography>
        <Button
          //data-aos="fade-down"
          variant="contained"
          sx={{
            background: "linear-gradient(to right, #098A4E , #9ADD00)",
            fontSize: "16px",
            lineHeight: "16px",
            padding: "15px 18px 15px 18px",
            textTransform: "none",
            marginTop: "10px",
            ":hover": {
              backgroundColor: "#098a43",
            },
          }}
        >
          Get in touch
        </Button>
      </Box>
    </Container>
  );
}

export default SHQuestion;
