import React, { useState } from "react";
import { Container, Grid, Card, Box, Typography, Button } from "@mui/material";
import Slider from "react-slick";
import { courseData } from "./data";
import "./style.css";

function CourseDetails() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#fff1eb", pt: 3, pb: 4, mt: 5 }}>
        <Container>
          <Grid container>
            <Grid xs={12} md={12}>
              <Box
                //data-aos="fade-down"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  pb: 4,
                }}
              >
                <Typography sx={{ fontSize: "36px", fontWeight: "bold" }}>
                  Our Courses!
                </Typography>
                <Typography sx={{ fontSize: "16px", mt: 1 }}>
                  Our program offers expert faculty, comprehensive study
                  material, interactive live classes, regular assessments,
                  personalized attention, and a flexible learning schedule.
                </Typography>
              </Box>
              <Box className="courseSlider">
                <Slider {...settings}>
                  {courseData?.map((item, index) => {
                    return (
                      <Grid xs={12} md={12} key={item.id}>
                        <Box
                          sx={{
                            position: "relative",
                            mb: 4,
                            width: { xs: "90%", sm: "50%", md: "35%" },
                            margin: "auto",
                          }}
                        >
                          <Card
                            key={index + "course"}
                            sx={{
                              width: "95%",
                              height: "auto",
                              borderRadius: "16px",
                              paddingBottom: "10px",
                              marginBottom: "10px",
                              backgroundColor: item.bgColor,
                              boxShadow: "none",
                            }}
                            //data-aos="fade-left"
                          >
                            <Box
                              sx={{
                                px: 3,
                                pt: 2,
                                pb: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                flexWrap: "wrap",
                                display: "relative",
                              }}
                            >
                              <img
                                src={item.img}
                                alt=""
                                width="100%"
                                style={{ borderRadius: "10px" }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  mt: 1,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    color: "#EA5806",
                                  }}
                                >
                                  {item?.text1}
                                </Typography>
                                <img
                                  src={item?.ratingIcon}
                                  alt="rate us"
                                  width="70px"
                                />
                              </Box>

                              <Box
                                sx={{
                                  width: "100%",
                                  borderBottom: "1px dotted #ccc",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    marginTop: "5px",
                                  }}
                                >
                                  {item?.text2}
                                </Typography>
                                {/*<Typography>
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "600",
                                      color: "#EA5806",
                                    }}
                                  >
                                    ₹ {item?.amount} only
                                  </span>{" "}
                                  &#160;&#160;&#160;{" "}
                                  <span
                                    style={{
                                      textDecoration: "line-through",
                                      fontSize: "12px",
                                    }}
                                  >
                                    ₹{item?.price}
                                  </span>
                                  </Typography> */}
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  mt: 1,
                                  width: "100%",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-evenly",
                                    width: "35%",
                                  }}
                                >
                                  <img
                                    src={item?.timeicon}
                                    alt="time icon"
                                    width="15px"
                                    height="15px"
                                  />
                                  <Typography
                                    sx={{ fontSize: "12px", fontWeight: "500" }}
                                  >
                                    {item?.duration}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "35%",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <img
                                    src={item?.videoicon}
                                    alt="time icon"
                                    width="20px"
                                    height="15px"
                                  />
                                  <Typography
                                    sx={{ fontSize: "12px", fontWeight: "500" }}
                                  >
                                    {item?.courseCount} Courses
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    width: "100%",
                                    mt: 2,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      textTransform: "none",
                                      background: "green",
                                      borderRadius: "15px",
                                      padding: "5px 20px",
                                      fontSize: "13px",
                                      width: "100%",
                                    }}
                                  >
                                    Join Course
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Card>
                          {/*<Box
                            sx={{
                              marginLeft: "30%",
                              marginTop: "-20px",
                              zIndex: 33371873187,
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                textTransform: "none",
                                background: "green",
                                borderRadius: "15px",
                                padding: "5px 20px",
                                fontSize: "13px",
                              }}
                            >
                              Join Course
                            </Button>
                            </Box>*/}
                        </Box>
                      </Grid>
                    );
                  })}
                </Slider>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default CourseDetails;
