import React from "react";
import { Grid, Card, Box, Typography } from "@mui/material";
import { studyMaterials, urlData } from "./data";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

function StudyMaterials() {
  const navigate = useNavigate();
  
  const studyMaterial = (keyword) => {
    const returnedUrl = urlData?.includes(keyword) ? keyword : "/jnv";
    navigate(returnedUrl);
  };

  return (
    <>
      <Grid xs={12} md={12}>
        <Box sx={{ mb: 4, mt: 4, pt: 3 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "35px", sm: "40px" },
              lineHeight: "60px",
              fontWeight: 600,
              textAlign: "center",
              color: "#fff",
            }}
          >
            Study Materials
          </Typography>
        </Box>
      </Grid>

      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        {studyMaterials?.map((item, index) => {
          return (
            <Box
              sx={{
                width: {
                  xs: "50%",
                  sm: "33%",
                  md: "14.28%",
                  cursor: "pointer",
                },
              }}
              key={index + "study"}
              onClick={() => studyMaterial(item?.keyboard)}
            >
              <Card
                sx={{
                  width: "93%",
                  borderRadius: "16px",
                  height: "auto",
                  boxSizing: "border-box",
                  // paddingLeft: "15px",
                  marginBottom: "10px",
                  backgroundColor: item.bgColor,
                  // backgroundColor: "none",
                }}
                //data-aos="fade-right"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <LazyLoadImage
                    alt="study material"
                    effect="blur"
                    src={item?.image}
                    width="100%"
                    //   height="100%"
                    objectFit="cover"
                  />

                  {/*<Typography
                    variant="body1"
                    component="div"
                    sx={{
                      mt: 2,
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "white",
                    }}
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                  <span
                    style={{
                      textAlign: "right",
                      marginRight: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <ArrowRightAltIcon
                      sx={{ color: "white", cursor: "pointer" }}
                    />
                  </span> */}
                </Box>
              </Card>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default StudyMaterials;
