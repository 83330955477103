import { createSlice, isAnyOf, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

const initialState = {
  paymentstatusLoader: false,
  paymentstatus: {},
};

const Phonepaysuccess = createSlice({
  name: "Phonepay Status",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getPhonepayPaymentStatusAsync.pending), (state) => {
      state.paymentstatusLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getPhonepayPaymentStatusAsync.fulfilled),
      (state, action) => {
        state.paymentstatusLoader = false;
        state.paymentstatus = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getPhonepayPaymentStatusAsync.rejected),
      (state, action) => {
        state.paymentstatusLoader = false;
      }
    );
  },
  reducers: {},
});

export const {} = Phonepaysuccess.actions;

export default Phonepaysuccess.reducer;

export const getPhonepayPaymentStatusAsync = createAsyncThunk(
  "mobile/getPaymentStatus",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/verifyPaymentphonepay`, payload, toolkit);
  }
);
