import React, { useState } from "react";
import { Grid, Card, Box, Typography } from "@mui/material";
import thumbnail from "../../assets/images/jnv/thumbnail.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import { bottomSlider } from "./data";
import ResponsiveModal from "../../components/Modal/ResponsiveModal";
import VideoPlayer from "components/Player/VideoPlayer";
import "./style.css";
import DialogMui from "components/Modal/DialogMui";

function InspiredStory() {
  const [open, setOpen] = useState(false);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Grid xs={12} md={12} sx={{ mt: 6 }}>
        <Box sx={{ mb: 4, mt: 4, pt: 6, ml: 1 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "33px", sm: "48px" },
              lineHeight: "60px",
              fontWeight: 600,
              textAlign: "left",
              color: "#130160",
            }}
          >
            Stories that <span style={{ color: "#ED1C24" }}>inspire</span>
          </Typography>
        </Box>
      </Grid>

      <Grid container>
        <Grid xs={12} md={5}>
          <Card
            sx={{
              width: "95%",
              height: "251px",
              borderRadius: "16px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexWrap: "wrap",
              paddingTop: "10px",
              paddingBottom: "10px",
              marginBottom: "10px",
              backgroundColor: "",
              ml: { xs: 1, sm: 0 },
            }}
            //data-aos="fade-right"
          >
            <Box onClick={() => setOpen(true)} sx={{ cursor: "pointer" }}>
              <LazyLoadImage
                alt="Slider image"
                effect="blur"
                src={thumbnail}
                width="100%"
                //   height="100%"
                objectFit="cover"
              />
            </Box>
          </Card>
        </Grid>
        <Grid xs={12} md={7}>
          <Card
            //data-aos="fade-left"
            sx={{
              width: "95%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexWrap: "wrap",
              flexDirection: "column",
              padding: "20px 10px 20px 10px",
              border: "none",
              boxShadow: "none",
              backgroundColor: "#fff",
            }}
          >
            <Typography
              sx={{ fontSize: "24px", fontWeight: "500", lineHeight: "42px" }}
            >
              “Veda Academy's teachers are incredible. They have a fantastic way
              of explaining concepts, which made learning for the JNVST smooth
              and effective. If you're aiming to crack the JNVST, I highly
              recommend Veda Academy.”
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "28px",
                mt: 4,
              }}
            >
              Kanhu Charan Panigrahi
            </Typography>
          </Card>
        </Grid>
        <Grid xs={12} md={12}>
          <div className="slider2">
            <Slider {...settings}>
              {bottomSlider?.map((item, ind) => {
                return (
                  <Box
                    key={ind + "inspire"}
                    sx={{
                      height: 200,
                      borderRadius: "16px",
                      mt: 3,
                      pr: 2,
                      ml: "10px",
                      // border: "2px solid rgb(234, 234, 234)",
                    }}
                  >
                    <Card
                      sx={{
                        width: "97%",
                        height: "100%",
                        borderRadius: "16px",
                        backgroundColor: item.bgColor,
                        border: "none",
                        boxShadow: "none",
                        padding: "15px",
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: item.topTextColor,
                          mt: 1,
                        }}
                        dangerouslySetInnerHTML={{ __html: item.topText }}
                      />
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: item.nameColor,
                        }}
                      >
                        {item.name}
                      </Typography>

                      {item?.rank && (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: item.nameColor,
                          }}
                        >
                          {item.rank}
                        </Typography>
                      )}
                    </Card>
                  </Box>
                );
              })}
            </Slider>
          </div>
        </Grid>
      </Grid>

      <DialogMui
        open={open}
        setOpen={setOpen}
        urlVid={
          "https://res.cloudinary.com/dqzv6tst7/video/upload/v1712396953/nuhxoevbyyqcgfecpbuq.mp4"
        }
        videoProvider="html5"
      />
    </>
  );
}

export default InspiredStory;
