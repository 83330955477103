import React, { useState } from "react";
import { Container, Grid, Card, Box, Typography, Button } from "@mui/material";
import { youtubeSectionData } from "./data";
import { youtubeData } from "./data";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ResponsiveModal from "../../components/Modal/ResponsiveModal";
import DialogMui from "../../components/Modal/DialogMui";
import VideoPlayer from "components/Player/VideoPlayer";
import axios from "axios";
import AlarmIcon from "../../assets/images/jnv/alarm.png";

function YoutubeSection() {
  const [open, setOpen] = useState(false);
  const [urlVid, setUrlVid] = useState("");

  const handleVideo = (videoUrl) => {
    setUrlVid(videoUrl);
    setOpen(true);
  };

  return (
    <>
      <Container>
        {youtubeData?.map((item, ind) => {
          return (
            <Box
              key={ind + "you"}
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginTop="5px"
              width="100%"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="row"
                >
                  <Typography
                    sx={{
                      fontSize: "32px",
                      fontWeight: "bold",
                    }}
                  >
                    {item.head1} {}
                    <img
                      src={item.YoutubeIcon}
                      alt="youtube image"
                      width="30px"
                    />
                  </Typography>
                </Box>
                <Typography
                  sx={{ fontSize: "14px", mt: 1, mb: 1, color: "#EE1C25" }}
                >
                  {item.text}
                </Typography>
                <Button
                  sx={{
                    backgroundColor: "#000",
                    borderRadius: "10px",
                    padding: "3px 10px",
                    color: "#fff",
                    fontSize: "10px",
                    fontWeight: "600",
                    mt: 1,
                    mb: 3,
                    "&:hover": {
                      backgroundColor: "#232b2b",
                      color: "#fff",
                    },
                  }}
                >
                  Subscribe{" "}
                  <img
                    src={AlarmIcon}
                    alt="alarm icon"
                    width="15px"
                    style={{ marginLeft: "5px" }}
                  />
                </Button>
              </Box>
            </Box>
          );
        })}
        <Grid container>
          {youtubeSectionData?.map((item, index) => {
            return (
              <Grid xs={12} sm={6} md={4} key={item.id}>
                <Card
                  key={index + "course"}
                  sx={{
                    width: "95%",
                    height: "auto",
                    borderRadius: "16px",
                    paddingBottom: "10px",
                    marginBottom: "10px",
                    backgroundColor: item.bgColor,
                  }}
                  //data-aos="fade-left"
                >
                  <Box
                    sx={{
                      px: 3,
                      pt: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    <img
                      src={item.img}
                      alt=""
                      width="100%"
                      style={{ borderRadius: "10px", cursor: "pointer" }}
                      onClick={() => handleVideo(item?.videoUrl)}
                    />

                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <img
                        src={item?.icon}
                        alt="icon"
                        width="20px"
                        height="20px"
                      />
                      <Typography
                        sx={{
                          fontSize: "12px",
                          ml: 1,
                          mr: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#FF8331",
                          }}
                        >
                          {item.caption}
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "600", fontSize: "12px" }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "500", fontSize: "12px" }}
                        >
                          {item.text}
                        </Typography>
                      </Typography>

                      {/*<MoreVertIcon sx={{ cursor: "pointer" }} /> */}
                    </Box>
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>

      {open && urlVid && (
        // <ResponsiveModal open={open} setOpen={setOpen}>
        <DialogMui
          open={open}
          setOpen={setOpen}
          urlVid={urlVid}
          videoProvider="youtube"
        />

        // <VideoPlayer src={urlVid} videoProvider="youtube" />

        // </ResponsiveModal>
      )}
    </>
  );
}

export default YoutubeSection;
