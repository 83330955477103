import React from "react";
import AppStoreSection from "components/AppStoreSection";
import HomePageSlider from "./HomePageSlider";
import BookdemoSection from "./BookdemoSection";
import CountViewSection from "./CountViewSection";
import TeachersSection from "./TeachersSection";
import ExploreMore from "./ExploreMore";
import InspireStorySection from "components/InspireStorySection";
import HappyTohelpYou from "./HappyTohelpYou";

const HomePage = () => {
  return (
    <>
      {/* <HomePageSlider /> */}
      <AppStoreSection />
      <BookdemoSection backgroundColor={"#FFFFFF"} />
      <CountViewSection />
      <TeachersSection />
      <ExploreMore />
      {/* <InspireStorySection /> */}
      {/* <HappyTohelpYou /> */}
    </>
  );
};

export default HomePage;