//For CountViewSection
import subject from '../../assets/images/subjects.png';
import dailyClasses from '../../assets/images/dailyClasses.png';
import watchedVideos from '../../assets/images/watchedVideos.png';
import personGroup from '../../assets/images/personGroup.png';
import videoCard from '../../assets/images/videoCard.png';
//For Explore More
import assessmentCenter from '../../assets/images/liveClasses.svg';
import smartClass from '../../assets/images/mentorship.svg';
import schoolProgram from '../../assets/images/test&Quizzes.svg';
// For TeachersSection
import graduationHat from '../../assets/images/graduationHat.png';
import appleBook from '../../assets/images/appleBook.png';
import sendTime from '../../assets/images/sendTime.png'

//For CountViewSection
export const leftCount = [
    { id: 1, lable: "Subjects", count: 60, img: subject },
    // { id: 2, lable: "Daily live classes", count: "1.5K", img: dailyClasses },
    { id: 3, lable: "Learning minutes", count: "6L", img: watchedVideos },
];
export const rightCount = [
    { id: 1, lable: "Questions", count: '1,00,000', img: personGroup },
    { id: 2, lable: "Topics covered", count: "6000", img: videoCard },
];


//For Explore More
export const displayDataExploreMore = [
    { id: 1, heading: "Live class", text: 'Learn, Interact, and Thrive: Unleash the Power of Live Classes in our App!', img: assessmentCenter },
    { id: 2, heading: "Mentorship", text: 'Achieve your goals with a mentor.', img: smartClass },
    { id: 3, heading: "Test and Quizzes", text: 'Test Your Knowledge, Ignite Your Success: Take Quizzes and Tests to Excel in Every Subject!', img: schoolProgram }
];


// For TeachersSection
export const displayDataTeacherSection = [
    { id: 1, text: 'From Top-tier colleges with many years of experience', img: graduationHat },
    { id: 2, text: 'Specially-trained teachers to bring out the best in every student.', img: appleBook },
    // { id: 3, text: 'Teaching experience of over 4.5 crore hours to 10 lakh students in 1000+ cities in 57 countries.', img: sendTime },
];