import React from "react";
import GussPaperMaterial from "./GussPaperMaterial";

function FreeStudyIndex() {
  return (
    <>
      <GussPaperMaterial />
    </>
  );
}

export default FreeStudyIndex;
