import React from "react";
import RevisionNoteMaterial from "./RevisionNoteMaterial";

function RevisionNoteIndex() {
  return (
    <>
      <RevisionNoteMaterial />
    </>
  );
}

export default RevisionNoteIndex;
