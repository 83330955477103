import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { leftCount, rightCount } from "./constant";
import Aos from "aos";

function CountViewSection() {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <Container sx={{ maxWidth: { xs: "100%", lg: "1300px" } }}>
      <Grid container>
        <Grid item xs={12} md={5} sx={{ pt: { sm: 15, xs: 5 } }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "40px",
              fontWeight: 500,
              lineHeight: "48px",
            }}
          >
            Start learning <br /> with Veda
          </Typography>

          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              lineHeight: "26px",
              mt: 3,
            }}
          >
            Get unlimited access to structured <br /> courses & doubt clearing
            sessions
          </Typography>

          <Button
            variant="contained"
            sx={{
              mt: 7,
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "25.6px",
              borderRadius: "8px",
              color: "#FFFFFF",
              padding: "12px 20px 12px 20px",
              background: "#FF8331",
              "&:hover": { background: "#DC5F19" },
            }}
          >
            Start learning
          </Button>
        </Grid>
        <Grid item xs={12} md={7} sx={{ pt: 5, pb: 8 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              columnGap: "50px",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "end",
                flexWrap: "wrap",
              }}
            >
              {leftCount.map((ev) => (
                <Box key={ev.id} sx={{ width: "280px", height: "185px" }}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: 500,
                      lineHeight: "30px",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    {ev.lable}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "40px",
                      lineHeight: "48px",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    {ev.count}{" "}
                    <span style={{ color: "green" }}>
                      {ev.id === 1 ? "+" : ""}
                    </span>
                  </Typography>

                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <img
                      src={ev.img}
                      width="121.89px"
                      maxWidth="100%"
                      maxHeight="189.92px"
                    />
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center !important",
                flexWrap: "wrap",
              }}
            >
              {rightCount.map((ev) => (
                <Box key={ev.id} sx={{ width: "280px", height: "185px" }}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: 500,
                      lineHeight: "30px",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    {ev.lable}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "40px",
                      lineHeight: "48px",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    {ev.count}{" "}
                    <span
                      style={{
                        color: "green",
                        fontSize: "20px",
                        fontWeight: 600,
                      }}
                    >
                      {ev.id === 1 ? "" : "+(Unique)"}
                    </span>
                  </Typography>

                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <img
                      src={ev.img}
                      width="121.89px"
                      maxWidth="100%"
                      maxHeight="189.92px"
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CountViewSection;
