import React from "react";
import Container from "@mui/material/Container";
import JVNSection from "./JVNSection";
import StudyMaterials from "./StudyMaterials";
import TeachersTeach from "./TeachersTeach";
import InspiredStory from "./InspiredStory";
import LearnAnywhere from "./LearnAnywhere";
import YoutubeSection from "./YoutubeSection";
import OurCourses from "./OurCourses";
import StudentsFeedback from "./StudentsFeedback";
import SocialPage from "./SocialPage";
import DemoSection from "./DemoSection";

function JnvIndex() {
  return (
    <>
      <JVNSection />
      <YoutubeSection />
      <OurCourses />
      <div style={{ backgroundColor: "#ea5806", paddingBottom: "60px" }}>
        <Container>
          <StudyMaterials />
          <TeachersTeach />
        </Container>
      </div>
      <Container>
        <StudentsFeedback />
      </Container>
      <div style={{ backgroundColor: "#fff", paddingBottom: "60px" }}>
        <Container>
          <InspiredStory />
        </Container>
      </div>

      <Container>
        <SocialPage />
        <LearnAnywhere />
      </Container>
      <div
        style={{ backgroundImage: "linear-gradient(to top, #FF8D4D, #EA5806)" }}
      >
        <DemoSection />
      </div>
    </>
  );
}

export default JnvIndex;
