import axios from "axios";
import { config } from "config/config";
// import { API_LINK, AxiosLog } from "../utils/path";
// import {
//   setErrorMessageRedux,
//   setSuccessMessageRedux,
//   setUnAuthMessageRedux,
// } from "./Message/message.slice";
/*
 * Axios Api Call Component
 * @type : GET POST PATCH DELETE
 * @api : Api Path
 * @payload : Payload that need to be sent to server
 * @toolkit: dispatch, fulfillWithValue, rejectWithValue
 */

const AxiosClient = async (
  type,
  api,
  payload,
  toolkit,
  content = "application/json"
) => {
  //   const { Tokenjs: { accessToken = {} } = {} } = toolkit.getState();

  const AxiosTypeString = {
    GET: "get",
    POST: "post",
    PUT: "put",
    PATCH: "patch",
    DELETE: "delete",
  };
  let token = "";
  if (Boolean(payload?.token)) {
    token = payload.token;
    delete payload.token;
  } else if (!Boolean(payload?.token)) {
    delete payload.token;
  }
  if (config.AxiosLog) {
    console.log(
      "\x1b[1m\x1b[36m%s\x1b[0m",
      `AXIOS CLIENT PAYLOAD --> ${JSON.stringify(payload)} ${api}`
    );
  }

  return await axios({
    method: AxiosTypeString[type],
    url: `${config.API_LINK}${api}`,
    data: payload,
    headers: {
      "Content-Type": content,
      Authorization: token || null,
    },
  })
    .then((response) => {
      if (config.AxiosLog) {
        console.log(
          "\x1b[1m\x1b[33m%s\x1b[0m",
          `AXIOS CLIENT SUCCESS --> ${JSON.stringify(api)}\n`
        );
        console.log("\x1b[32m%s\x1b[0m", JSON.stringify(response.data));
      }

      return toolkit.fulfillWithValue({
        ...response.data,
        success: true,
      });
    })
    .catch((error) => {
      if (config.AxiosLog) {
        console.log(
          "\x1b[1m\x1b[31m%s\x1b[0m",
          `AXIOS CLIENT ERROR -->${api}\n`
        );
        console.log(
          "\x1b[1m\x1b[31m%s\x1b[0m",
          `AXIOS CLIENT ERROR -->${error}\n${config.API_LINK}${api}`
        );
      }

      if (error.response) {
        if (config.AxiosLog) {
          console.log("\x1b[31m%s\x1b[0m", error.response.data);
        }

        if (error.response.data.status !== 401) {
          //   toolkit.dispatch(setErrorMessageRedux(error.response.data.message));
        } else {
          //   toolkit.dispatch(setUnAuthMessageRedux("Session Expired!"));
        }
      } else if (error.request) {
        // toolkit.dispatch(
        //   setErrorMessageRedux(
        //     "Please check your internet connection and try again."
        //   )
        // );
      } else {
        // toolkit.dispatch(setErrorMessageRedux(error.message));
      }

      return toolkit.rejectWithValue(error.response.data.message);
    });
};
export { AxiosClient };
