import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Checkbox, FormControlLabel } from "@mui/material";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import * as yup from "yup";
import { useFormik } from "formik";
import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import insta from "../../assets/images/Instagram.svg";
import linkin from "../../assets/images/Linkedin.svg";
import fb from "../../assets/images/Facebook.svg";
import yt from "../../assets/images/YouTube.svg";
import Aos from "aos";
import axios from "axios";

const validationSchema = yup.object({
  name: yup.string("Enter your Name").required("Name is required"),
  email: yup.string("Enter your Email").required("Email is required"),
  mobile: yup
    .number("Enter your Mobile Number")
    .required("Mobile Number is required"),
  subject: yup.string("Enter your Subject").required("Subject is required"),
  message: yup.string("Enter your Message").required("Message is required"),
});

function Form() {
  const [checked, setChecked] = useState(false);
  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
    isChecked: "",
  };
  const onSubmit = async (values, { resetForm }) => {
    const payload = {
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      subject: values.subject,
      message: values.message,
    };

    if (values?.isChecked == false) {
      toast.error(
        "Please select the below checkbox to authorize Veda academy to contact you!"
      );
      return;
    }

    await emailjs
      .send("service_80s5lsf", "template_p7icuai", payload, "JsKho86dWJyrUL0SM")
      .then(
        () => {
          resetForm();
        },
        () => {
          console.log("Email Failed!");
        }
      );

    await axios
      .post("https://api.vedaacademy.org.in/createEnquiry", payload)
      .then((res) => {
        toast.success("Your form has been submitted successfully. Thanks!");
        resetForm();
      })
      .catch((err) => {
        toast.error("Something went Wrong.");
      });
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    formik.setFieldValue("isChecked", event.target.checked);
  };

  return (
    <>
      <Box>
        <Container sx={{ my: 10 }}>
          <Box>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "40px",
                lineHeight: "60px",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              Contact Us
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                lineHeight: "30px",
                textAlign: "center",
                color: "#787A8D",
              }}
            >
              Any question or remarks? Just write us a message!
            </Typography>
          </Box>

          <Grid
            container
            sx={{
              mt: 5,
              p: { xs: 0, md: 5 },
              display: "flex",
              justifyContent: "center",
            }}
            columnSpacing={{ xs: 0, md: 3, lg: 5 }}
          >
            <Grid
              item
              xs={12}
              md={7}
              alignSelf={"center"}
              sx={{ mt: { xs: 5, md: 0 } }}
              //data-aos="fade-left"
            >
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between !important",
                  columnGap: "20px",
                  rowGap: "25px",
                }}
                onSubmit={formik.handleSubmit}
              >
                <Box
                  sx={{
                    rowGap: { xs: "25px !important", sm: "0px !important" },
                    columnGap: "20px !important",
                    display: "flex",
                    flexDirection: {
                      xs: "column !important",
                      sm: "row !important",
                    },
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      label="Enter your name*"
                      variant="outlined"
                      size="small"
                      color="warning"
                      sx={{
                        width: "100%",
                        marginRight: { xs: "0", sm: "10px !important" },
                      }}
                      {...formik.getFieldProps("name")}
                      onChange={(e) => {
                        formik.setFieldValue("name", e.target.value);
                      }}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <Typography
                        sx={{
                          textAlign: "start",
                          marginLeft: "4px",
                          fontSize: "15px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                        }}
                        color="error"
                      >
                        {formik.errors.name}
                      </Typography>
                    ) : null}
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label="Email Id*"
                      variant="outlined"
                      size="small"
                      type="email"
                      color="warning"
                      sx={{ width: "100%" }}
                      {...formik.getFieldProps("email")}
                      onChange={(e) => {
                        formik.setFieldValue("email", e.target.value);
                      }}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <Typography
                        sx={{
                          textAlign: "start",
                          marginLeft: "4px",
                          fontSize: "15px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                        }}
                        color="error"
                      >
                        {formik.errors.email}
                      </Typography>
                    ) : null}
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    rowGap: { xs: "25px !important", sm: "0px !important" },
                    columnGap: "20px !important",
                    display: "flex",
                    flexDirection: {
                      xs: "column !important",
                      sm: "row !important",
                    },
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      label="Phone Number*"
                      variant="outlined"
                      size="small"
                      type="number"
                      color="warning"
                      fullWidth
                      sx={{ width: { sm: "100%", xs: "100%" } }}
                      {...formik.getFieldProps("mobile")}
                      onChange={(e) => {
                        formik.setFieldValue("mobile", e.target.value);
                      }}
                    />
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <Typography
                        sx={{
                          textAlign: "start",
                          marginLeft: "4px",
                          fontSize: "15px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                        }}
                        color="error"
                      >
                        {formik.errors.mobile}
                      </Typography>
                    ) : null}
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label="Subject*"
                      variant="outlined"
                      size="small"
                      color="warning"
                      sx={{ width: { sm: "100%", xs: "100%" } }}
                      {...formik.getFieldProps("subject")}
                      onChange={(e) => {
                        formik.setFieldValue("subject", e.target.value);
                      }}
                    />
                    {formik.touched.subject && formik.errors.subject ? (
                      <Typography
                        sx={{
                          textAlign: "start",
                          marginLeft: "4px",
                          fontSize: "15px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                        }}
                        color="error"
                      >
                        {formik.errors.subject}
                      </Typography>
                    ) : null}
                  </FormControl>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    label="Message*"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={10}
                    maxRows={10}
                    color="warning"
                    sx={{
                      width: "100%",
                    }}
                    {...formik.getFieldProps("message")}
                    onChange={(e) => {
                      formik.setFieldValue("message", e.target.value);
                    }}
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <Typography
                      sx={{
                        textAlign: "start",
                        marginLeft: "4px",
                        fontSize: "15px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                      color="error"
                    >
                      {formik.errors.message}
                    </Typography>
                  ) : null}
                </FormControl>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      sx={{ alignSelf: "flex-start", mt: -1 }}
                      name="exampleCheckbox"
                      color="primary"
                    />
                  }
                  label="I authorize Veda Academy and its representative to contact me with updates and notifications via Email, SMS, WhatsApp and call. This will override the registry on DND / NDNC."
                />

                {formik.errors.isChecked && <Box>"There is an error"</Box>}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: { xs: "center", md: "flex-end" },
                    alignItems: "center",
                    width: "100%",
                    marginTop: "-60px !important",
                  }}
                >
                  <Button
                    type="submit"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      lineHeight: "24px",
                      borderRadius: "8px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#FFFFFF",
                      mt: 7,
                      background: "linear-gradient(#FF8331, #FF8331, #DC5F19)",
                      "&:hover": {
                        backgroundColor: "#DC5F19",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                      },
                    }}
                  >
                    Send Message
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.688256717254!2d77.28132011508005!3d28.519025582462746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce14c80888aa1%3A0x5cf9eeaa5a8df14b!2sSATYA%20MicroCapital%20Limited!5e0!3m2!1sen!2sin!4v1687241764065!5m2!1sen!2sin"
          width="100%"
          height="500px"
          style={{ border: 0, marginBottom: "-10px" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe> */}
      </Box>
      <ToastContainer position="bottom-right" />
    </>
  );
}

export default Form;
