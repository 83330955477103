import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { useFormik } from "formik";
import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Aos from "aos";
import axios from "axios";

const validationSchema = yup.object({
  name: yup.string("Enter your Name").required("Name is required"),
  mobile: yup
    .number("Enter your Mobile Number")
    .required("Mobile Number is required"),
  city: yup.string("Enter your City").required("City is required"),
  grade: yup.string("Enter your Grade").required("Grade is required"),
  email: yup.string("Enter your Email").required("Email is required"),
});

function QueryForm() {
  const initialValues = {
    name: "",
    mobile: "",
    city: "",
    grade: "",
    email: "",
  };

  const onSubmit = async (values, { resetForm }) => {
    const payload = {
      name: values.name,
      mobile: values.mobile,
      city: values.city,
      grade: values.grade,
      email: values.email,
    };
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between !important",
          columnGap: "10px",
          rowGap: "15px",
          mb: 4,
          backgroundColor: "#fff",
          padding: "20px",
          border: "1px solid rgba(0, 0, 0, 0.5)",
        }}
        onSubmit={formik.handleSubmit}
      >
        <FormControl fullWidth>
          <TextField
            label="Name"
            variant="outlined"
            size="small"
            color="warning"
            sx={{
              width: "100%",
              marginRight: { xs: "0", sm: "10px !important" },
            }}
            {...formik.getFieldProps("name")}
            onChange={(e) => {
              formik.setFieldValue("name", e.target.value);
            }}
          />
          {formik.touched.name && formik.errors.name ? (
            <Typography
              sx={{
                textAlign: "start",
                marginLeft: "4px",
                fontSize: "12px",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
              color="error"
            >
              {formik.errors.name}
            </Typography>
          ) : null}
        </FormControl>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <FormControl sx={{ width: "60%" }}>
            <TextField
              label="Mobile Number"
              variant="outlined"
              size="small"
              type="number"
              color="warning"
              fullWidth
              sx={{ width: { sm: "100%", xs: "100%" } }}
              {...formik.getFieldProps("mobile")}
              onChange={(e) => {
                formik.setFieldValue("mobile", e.target.value);
              }}
            />
            {formik.touched.mobile && formik.errors.mobile ? (
              <Typography
                sx={{
                  textAlign: "start",
                  marginLeft: "4px",
                  fontSize: "12px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                }}
                color="error"
              >
                {formik.errors.mobile}
              </Typography>
            ) : null}
          </FormControl>
          <Button
            // type="submit"
            sx={{
              fontFamily: "Poppins",
              fontSize: "15px",
              lineHeight: "24px",
              fontWeight: "500",
              borderRadius: "8px",
              paddingTop: "8px",
              paddingBottom: "8px",
              paddingLeft: { xs: "20px", sm: "15px", md: "15px", lg: "25px" },
              paddingRight: { xs: "20px", sm: "15px", md: "15px", lg: "25px" },
              color: "#FFFFFF",
              background: "linear-gradient(#8646FF, #8646FF)",
              "&:hover": {
                backgroundColor: " #8646FF",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              },
            }}
          >
            Send otp
          </Button>
        </Box>
        <FormControl fullWidth>
          <TextField
            label="City"
            variant="outlined"
            size="small"
            color="warning"
            sx={{ width: { sm: "100%", xs: "100%" } }}
            {...formik.getFieldProps("city")}
            onChange={(e) => {
              formik.setFieldValue("city", e.target.value);
            }}
          />
          {formik.touched.city && formik.errors.city ? (
            <Typography
              sx={{
                textAlign: "start",
                marginLeft: "4px",
                fontSize: "12px",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
              color="error"
            >
              {formik.errors.city}
            </Typography>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Grade?Exam"
            variant="outlined"
            size="small"
            color="warning"
            sx={{ width: { sm: "100%", xs: "100%" } }}
            {...formik.getFieldProps("grade")}
            onChange={(e) => {
              formik.setFieldValue("grade", e.target.value);
            }}
          />
          {formik.touched.grade && formik.errors.grade ? (
            <Typography
              sx={{
                textAlign: "start",
                marginLeft: "4px",
                fontSize: "12px",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
              color="error"
            >
              {formik.errors.grade}
            </Typography>
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Email Address"
            variant="outlined"
            size="small"
            type="email"
            color="warning"
            sx={{ width: "100%" }}
            {...formik.getFieldProps("email")}
            onChange={(e) => {
              formik.setFieldValue("email", e.target.value);
            }}
          />
          {formik.touched.email && formik.errors.email ? (
            <Typography
              sx={{
                textAlign: "start",
                marginLeft: "4px",
                fontSize: "12px",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
              color="error"
            >
              {formik.errors.email}
            </Typography>
          ) : null}
        </FormControl>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: { xs: "center", md: "left" },
            alignItems: "center",
            width: "100%",
            marginTop: "-60px !important",
          }}
        >
          <Button
            type="submit"
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "500",
              borderRadius: "8px",
              paddingTop: "8px",
              paddingBottom: "8px",
              paddingLeft: "25px",
              paddingRight: "25px",
              color: "#FFFFFF",
              mt: 7,
              background: "linear-gradient(#8646FF, #8646FF)",
              "&:hover": {
                backgroundColor: " #8646FF",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>

      <ToastContainer position="bottom-right" />
    </>
  );
}

export default QueryForm;
