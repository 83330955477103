import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CourseData } from "./data";
import courseIcon from "../../assets/images/jnv/courseIcon.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function OurCourses() {
  return (
    <Container>
      <Grid container>
        <Grid xs={12} md={12}>
          <Box sx={{ mb: 4, mt: 4 }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: { xs: "40px", sm: "48px" },
                lineHeight: "60px",
                fontWeight: 600,
                textAlign: "center",
                color: "#130160",
              }}
            >
              <span style={{ color: "#000" }}>Courses!</span>
            </Typography>

            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                lineHeight: "30px",
                fontWeight: 500,
                textAlign: "center",
                mt: 1,
                color: "#ED1B24",
                textShadow: "0 0 5px",
              }}
            >
              Discover our courses!
            </Typography>
          </Box>
        </Grid>
        {CourseData?.map((item, index) => {
          return (
            <Grid xs={12} md={4} lg={4} key={item.id}>
              <Card
                sx={{
                  width: "95%",
                  borderRadius: "16px",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                  flexWrap: "wrap",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  marginBottom: "30px",
                  backgroundColor: item.bgColor,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "172px",
                    pointerEvents: "none",
                    textAlign: "center",
                  }}
                >
                  <LazyLoadImage
                    alt={"Course 1"}
                    effect="blur"
                    src={item.img}
                    width="93%"
                    height="auto"
                    borderRadius="16px"
                    objectFit="cover"
                  />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "10px",
                    mx: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      color: item.head1Color,
                      fontSize: "14px",
                      fontWeight: "600",
                      width: "105px",
                      height: "25px",
                      backgroundColor: item.head1BgColor,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                      marginRight: "25px",
                    }}
                  >
                    {item.head1}
                  </div>

                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      marginRight: "45px",
                    }}
                  >
                    {item.head2}
                  </p>
                </Box>

                <Box
                  sx={{
                    mt: 5,
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      width: "60%",
                      display: "flex",
                      justifyContent: "flex-start",
                      ml: 3,
                    }}
                  >
                    <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                      ₹ {item?.sprice}
                    </Typography>
                    <Typography sx={{ fontSize: "12px", ml: 2 }}>
                      ₹{" "}
                      <span style={{ textDecoration: "line-through" }}>
                        {item?.price}
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "40%",
                      display: "flex",
                      justifyContent: "flex-end",
                      mr: 3,
                    }}
                  >
                    <Typography sx={{ fontSize: "12px", color: "#ED1B24" }}>
                      {item?.discount}% OFF
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    mt: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: item.btnBgColor,
                      ml: 3,
                      fontSize: "14px",
                      textTransform: "none",
                      padding: "4px 10px",
                      borderRadius: "10px",
                      ":hover": {
                        backgroundColor: item.btnHoverColor,
                      },
                    }}
                  >
                    Explore courses
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: item.btnBgColor,
                      fontSize: "14px",
                      textTransform: "none",
                      padding: "4px 10px",
                      borderRadius: "10px",
                      mr: 3,
                      ":hover": {
                        backgroundColor: item.btnHoverColor,
                      },
                    }}
                  >
                    Buy Now
                  </Button>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

export default OurCourses;
