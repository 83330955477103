import React from "react";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
export default function TermsAndServices() {
  return (
    <Container sx={{mb:5}}>
      <Box sx={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
        <Typography
          sx={{ fontSize: "20px", marginTop: "40px", fontWeight: "500" }}
        >
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THE
          EDTECH IMPACT WEBSITE
        </Typography>
        <Typography
          sx={{ fontSize: "21px", fontWeight: "700", marginTop: "15px" }}
        >
          Terms and Conditions
        </Typography>
        <Typography sx={{ fontSize: "18px" }}>
          Your use of this website (vedaacademy.org.in) and all associated web
          pages, the “Site” and the services offered and supplied via the Site
          (the “Services”), whether as a guest or a registered user, is subject
          to the following terms and conditions between you and Koshish
          Marketing Solutions Private Limited. Koshish Marketing Solutions
          Private Limited registered office is 1/10712, UGF Subhash Park
          Hulewalal Park, Naveen Shahadra, Delhi North-East DL 110032. Koshish
          Marketing Solutions Private Limited is registered in India, with
          company registration number U74999DL2018PTC339624.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Use of the Site includes accessing, browsing, or registering to use
          the Site. Please read these terms and conditions carefully before you
          start to use the Site, as these will apply to your use of the Site and
          by continuing to use the Site you are deemed to have accepted them. If
          you do not wish to be bound by these terms and conditions, you should
          not continue to use or access the Site or use any of the Services.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          The Koshish Marketing Solutions Private Limited Blog is hosted by the
          blog hosting platform vedaacademy.org.in.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          These terms and conditions are only in the English language. Koshish
          Marketing Solutions Private Limited considers that these terms and
          conditions (as may be updated from time to time), together with the
          Privacy Policy relating to Koshish Marketing Solutions Private
          Limited, your request to register as a user of the Site, all other
          operating rules, policies and procedures that may be published from
          time to time on the Site, and any other terms relating to fees or
          other matters which are agreed to in writing by you and Koshish
          Marketing Solutions Private Limited (collectively “Terms”), constitute
          the whole agreement between you and . Koshish Marketing Solutions
          Private Limited.
        </Typography>
        <Typography
          sx={{ fontSize: "21px", fontWeight: "700", marginTop: "15px" }}
        >
          Definitions
        </Typography>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif",
            marginTop: "10px",
            fontSize: "18px"
          }}
        >
          <li>
            The term 'Content' means any content, including (but not limited to)
            awards, badges, videos, audio clips, text, software, pictures,
            forms, reviews, comments and other features and information which
            Koshish Marketing Solutions Private Limited has made available as
            part of the Services, including content which you have uploaded to
            the Site in accordance with the Terms.
          </li>
          <li style={{ marginTop: "5px" }}>
            The term ‘Educator’ means any person aged 18 or over who uses the
            Site to find or review educational products or services
          </li>
          <li style={{ marginTop: "5px" }}>
            The term “Supplier” means any supplier of education technology
            products who is accessing or using the Services;
          </li>
          <li style={{ marginTop: "5px" }}>
            The term “Reviewer” means any person aged 18 or over who submits a
            review to the Site.
          </li>
          <li style={{ marginTop: "5px" }}>
            The term ‘Product Listing’ means any product approved by Koshish
            Marketing Solutions Private Limited and posted on the Site.
          </li>
          <li style={{ marginTop: "5px" }}>
            The “Site” means www.edtechimpact.com and associated web pages.
          </li>
          <li style={{ marginTop: "5px" }}>
            “Terms” means these terms and conditions (as may be updated from
            time to time), our Guidelines, the Privacy Policy, your request to
            register as a user of the Site, all other operating rules, policies
            and procedures that may be published from time to time on the Site,
            and any other terms relating to fees or other matters which are
            agreed to in writing by you and Koshish Marketing Solutions Private
            Limited.
          </li>
        </ul>
        <Typography
          sx={{ fontSize: "21px", fontWeight: "700", marginTop: "15px" }}
        >
          Accepting the Terms
        </Typography>

        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          By accessing or using the Site or Services, you agree to be bound by
          these Terms and the documents referred to in them. If you do not agree
          to the Terms, you must not use the Site or otherwise use the Services.
          No changes to these Terms are valid or have any effect unless agreed
          by Koshish Marketing Solutions Private Limited in writing. Koshish
          Marketing Solutions Private Limited updated terms will be displayed on
          the Site and by continuing to use the Services and access the Site
          following such changes, you agree to be bound by any variation made by
          Koshish Marketing Solutions Private Limited. It is your responsibility
          to check these Terms from time to time to verify such variations. In
          the event of any inconsistency or conflict between these Terms, the
          Privacy Policy, and/or any other terms relating to fees or other
          matters which are agreed to in writing by you and Koshish Marketing
          Solutions Private Limited, these Terms shall have priority.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          The Services are available only to individuals who are at least 18
          years old. You represent and warrant that you are of legal age to form
          a binding contract, and that all registration information you submit
          is truthful and correct. Koshish Marketing Solutions Private Limited
          reserves the right to: (a) refuse the Services to anyone at any time
          without notice and for any reason; and (b) change its eligibility
          criteria at any time without notice and for any reason. You may not
          use the Services and may not accept the Terms if: (a) you are not of
          legal age to form a binding contract with Koshish Marketing Solutions
          Private Limited or (b) you are a person not allowed to receive or use
          the Services under the laws of India or the country in which you are
          resident or from which you use the Services.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          For the steps you need to take to register your product, please see{" "}
          <b>vedaacademy.org.in.</b>
        </Typography>

        <Typography
          sx={{ fontSize: "21px", fontWeight: "700", marginTop: "15px" }}
        >
          Changes to the Services
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited reserves the right to
          change, suspend or discontinue the Services at any time, by posting on
          the Site or by sending you an email. You agree that Koshish Marketing
          Solutions Private Limited may limit the Services or restrict your
          access to parts or all of the Services without notice or liability. It
          is your responsibility to check the Site periodically for changes, as
          they are binding on you. You agree that in continuing to use the
          Services following the posting of any changes such continued use
          constitutes acceptance of those changes. Registration
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          In order to use parts of the Services, you may be required to register
          your product with Koshish Marketing Solutions Private Limited.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You shall not:
        </Typography>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif",
            marginTop: "10px",
            fontSize: "18px"
          }}
        >
          <li>
            create or use as a company / product name, the name of another
            company / product with the intention of impersonating that company /
            product;
          </li>
          <li style={{ marginTop: "5px" }}>
            use as a company / product name, a name which is subject to any
            rights of a person other than you without appropriate authorisation;
            or
          </li>
          <li style={{ marginTop: "5px" }}>
            use as a company / product name, a name which is offensive or
            inflammatory as deemed by Koshish Marketing Solutions Private
            Limited in its sole discretion.
          </li>
        </ul>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited reserves the right to
          refuse registration of or to cancel a company / product listing in its
          sole discretion and to disable any company profile, at any time, if in
          its reasonable opinion you have failed to comply with any of the
          provisions of the Terms.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          If you become aware of any unauthorized use of your company / product
          name, you agree to notify Koshish Marketing Solutions Private Limited
          immediately by email to info@koshishindia.in.
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Conduct
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You agree to abide by and be bound by the <b>Acceptable Use Policy</b>
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You agree to use the Site and all Services responsibly and legally and
          not to use the Services for any purpose that is prohibited by the
          Terms. The Services are provided solely for Educators to use in
          finding, reviewing and evaluating edtech products. If you are a
          Supplier, for the purpose of collecting reviews and building your
          profile (described further below). You are responsible for all of your
          activity in connection with the Services.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Under the Terms you are prohibited from taking any action in relation
          to your use of the Services which:
        </Typography>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif",
            marginTop: "10px",
            fontSize: "18px"
          }}
        >
          <li style={{ marginTop: "5px" }}>
            infringes any patent, trade mark, copyright, right of publicity or
            other such right of any other person or entity or violates any law
            or contractual duty;
          </li>
          <li style={{ marginTop: "5px" }}>
            {" "}
            is false, misleading, inaccurate, slanderous or defamatory
          </li>
          <li style={{ marginTop: "5px" }}>
            is obscene, offensive, harassing (or advocating harassment),
            threatening, inflammatory, unlawful in any way or otherwise
            objectionable;
          </li>
          <li style={{ marginTop: "5px" }}>
            impersonates any person or entity or otherwise misrepresents your
            affiliation with a person or entity, including (but not limited to)
            the Site, Koshish Marketing Solutions Private Limited or any of its
            employees or representatives;
          </li>
          <li style={{ marginTop: "5px" }}>
            constitutes advertising or commercial activities which has not been
            authorised in writing by Koshish Marketing Solutions Private Limited
            or constitutes spam, junk mail or political campaigning;
          </li>
          <li style={{ marginTop: "5px" }}>
            constitutes, encourages, promotes or provides instructions for a
            conduct of an illegal activity, criminal offense or give rise to
            civil liability, or provides instructional information about illegal
            activities such as making or buying illegal weapons, violating
            someone's privacy, or providing or creating computer viruses;
          </li>
          <li style={{ marginTop: "5px" }}>
            results in software viruses, trojans, worms, logic bombs or other
            material which is malicious or technologically harmful interfering
            with, damaging or destroying the proper function of the Services;
          </li>
          <li style={{ marginTop: "5px" }}>
            in connection with use related to the Koshish Marketing Solutions
            Private Limited Blog, is otherwise in breach of the Automattic User
            Guidelines;
          </li>
          <li style={{ marginTop: "5px" }}>
            in the sole judgment of Koshish Marketing Solutions Private Limited,
            (a) violates any of the above restrictions herein, (b) is
            objectionable, (c) which restricts or inhibits any other person from
            using or enjoying the Site, or (d) which may expose Koshish
            Marketing Solutions Private Limited or its affiliates or its users
            to any harm or liability of any type.
          </li>
        </ul>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Additionally, you agree not to:
        </Typography>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif",
            marginTop: "10px",
            fontSize: "18px"
          }}
        >
          <li style={{ marginTop: "5px" }}>
            take any action that imposes or may impose (as determined by Koshish
            Marketing Solutions Private Limited in its sole discretion) an
            unreasonable or disproportionately large load on EdTech Impact’s
            infrastructure;
          </li>
          <li style={{ marginTop: "5px" }}>
            engage in any activity that interferes with or disrupts the Services
            (or the servers and networks which are connected to the Services),
            including (but not limited to) attacking the Site via a
            denial-of-service attack or a distributed denial-of service attack;
          </li>
          <li style={{ marginTop: "5px" }}>
            access (or attempt to access) any of the Services by any means other
            than through the interface that is provided by Koshish Marketing
            Solutions Private Limited, unless you have been specifically allowed
            to do so in a separate agreement with Koshish Marketing Solutions
            Private Limited. You specifically agree not to access (or attempt to
            access) any of the Services through any automated means (including
            use of scripts or web crawlers) and agree not to run any form of
            spam or auto-responder on the Services.
          </li>
        </ul>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          or auto-responder on the Services. If you breach this provision, you
          may be committing a criminal offence. Koshish Marketing Solutions
          Private Limited will report any such breach to the relevant law
          enforcement authorities and Koshish Marketing Solutions Private
          Limited will co-operate with those authorities by disclosing your
          identity to them. In the event of such a breach, your right to use the
          Site will cease immediately.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited does not guarantee that
          any Content will be made available on or through the Services. Koshish
          Marketing Solutions Private Limited has no obligation to monitor the
          Services or Content and takes no responsibility and assumes no
          liability for any Content posted, uploaded by you or any third party
          or any loss or damage thereto.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited reserves the right, in its
          sole discretion to edit, modify or remove any Content from the Site at
          any time, without notice to you and for any reason (including, but not
          limited to, upon receipt of claims or allegations from third parties
          or authorities relating to such Content or if Koshish Marketing
          Solutions Private Limited is concerned that you may have violated the
          Terms).
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You are responsible for configuring your information technology,
          computer programmes and platform in order to access the Site. You
          should use your own virus protection software.
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Use of the Site
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited acts as an intermediary
          between Educators and Suppliers and other users of the Site.
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Reviews and User-Generated Content
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You hereby grant us the worldwide, non-exclusive, perpetual,
          irrevocable, royalty-free right and license to publish, display,
          reproduce, modify, create derivative works of and commercially exploit
          any material, information, notifications, reviews, articles or other
          types of communication (hereinafter referred to as the "User-Generated
          Content" or "UGC") which you create on the Website or submit via our
          review form. We may freely use and transfer the UGC and disclose the
          UGC to third parties.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Reviewers are liable for the UGC they publish on the Website.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Reviewers warrant that all UGC posted on the Website is correct and
          true (where they state facts) or genuinely held (where they state
          opinions).
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          When you write a review on Koshish Marketing Solutions Private
          Limited, you represent, warrant and undertake to Koshish Marketing
          Solutions Private Limited and all users of the Site that:
        </Typography>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif",
            marginTop: "10px",
            fontSize: "18px"
          }}
        >
          <li style={{ marginTop: "5px" }}> you are who you say you are</li>
          <li style={{ marginTop: "5px" }}>
            you are a genuine user of the product / service that you are
            reviewing
          </li>
          <li style={{ marginTop: "5px" }}>
            {" "}
            you have not been paid to write the review
          </li>
          <li style={{ marginTop: "5px" }}>
            {" "}
            you are 18 years of age or older;
          </li>
        </ul>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          UGC must relate to a company or organization from which the Reviewers
          has purchased or can otherwise document using the company's or
          organization's products or services.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You may not publish UGC regarding companies to which you have personal
          or professional relations
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Reviewers must not, and must not allow any third party to, publish UGC
          on the website which:
        </Typography>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif",
            marginTop: "10px",
            fontSize: "18px"
          }}
        >
          <li style={{ marginTop: "5px" }}>
            {" "}
            is of a marketing nature or has marketing purposes,
          </li>
          <li style={{ marginTop: "5px" }}>
            is unlawful, deceptive, misleading, fraudulent, threatening,
            abusive, harassing, libelous, defamatory, tortious, obscene,
            pornographic or profane, has sexist, political or racial character,
            violates other people's rights, including any intellectual property
            rights, rights of privacy and/or rights of publicity,
          </li>
          <li style={{ marginTop: "5px" }}>
            is offensive or in any way breaches any applicable local, national
            or international law or regulation,
          </li>
          <li style={{ marginTop: "5px" }}>
            violates these User Terms, including the User Guidelines, reveals
            any personal information about another individual, including another
            person's name, address, phone number, email address, credit card
            information or any other information that could be used to track,
            contact or impersonate that person,
          </li>
          <li style={{ marginTop: "5px" }}>
            has a disloyal or unlawful purpose and/or content (or promotes
            unlawful purposes), or
          </li>
          <li style={{ marginTop: "5px" }}>
            is technically harmful (including without limitation computer
            viruses, logic bombs, Trojan horses, worms, harmful components,
            corrupted data or other malicious software, harmful data or
            conduct).
          </li>
        </ul>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          BEFORE you upload any images or videos to the Site which identify
          individuals under 18, you must obtain parental consent for such images
          or videos to be uploaded and for EdTech Impact to use them for
          promotional and marketing purposes in relation to your Project and the
          Koshish Marketing Solutions Private Limited. The signed consents must
          be made available to EdTech Impact on request. Please do not include
          personally identifiable information about students in any images or
          videos.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Contributors of UGC warrant in every context that the UGC is lawful
          and in compliance with the User Terms. If Koshish Marketing Solutions
          Private Limited receives notice or otherwise becomes aware that UGC
          violates current legislation and/or the User Terms, we may delete the
          UGC without any notice, and we - dependent on the character of the
          violation - may inform the violated party and/or the authorities of
          the violation. Our right to delete will not be conditioned on an
          explanation, although we will strive to inform the Reviewer about the
          deletion and the reason hereof.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          The Reviewer hereby grants us the right to initiate and take any legal
          actions which we deem necessary in case of infringement of the
          Reviewer’s UGC.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          The Reviewer must guarantee to indemnify us for any claims which may
          be made against us as a consequence of the Reviewer's violation of the
          User Terms or current legislation. The Registered User must indemnify
          and hold us harmless from and against any claim or loss due to third
          party claims against us resulting from the UGC of the Reviewer.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          We may at any time request information about the UGC from the
          Reviewer, including documentation supporting the information included
          in the UGC, e.g. documentation evidencing that the UGC is based on an
          actual buying experience in an actual customer relation to the company
          to which the UGC relates.  
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Interaction between Educators and Suppliers
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited shall not be liable for
          your interactions with any organisations, suppliers or individuals
          found on or through use of the Services or the Site. Koshish Marketing
          Solutions Private Limited does not exercise control over the timings
          or performance of suppliers in any way. Koshish Marketing Solutions
          Private Limited is not responsible for any damage or loss incurred as
          a result of any such dealings in relation to the suppliers or
          otherwise. All dealings are solely between you and such organisations
          and/or individuals as the case may be.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited is under no obligation to
          become involved in disputes between Educators and Suppliers, or
          between Site members and any third party. In the event of a dispute,
          you agree not to pursue Koshish Marketing Solutions Private Limited,
          its officers, employees, agents or their successors in relation to any
          claims arising out of or related to such disputes and agree that
          Koshish Marketing Solutions Private Limited, its officers, employees,
          agents, and their successors are exempt from and not subject to such
          claims. Suppliers are solely responsible for fulfilling obligations
          both implied and stated in any listing they create.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited reserves the right to
          remove a listing / supplier from the Services at any time for any
          reason.
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Product Listing
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          As part of the Services, Suppliers may submit a request for a Product
          Listing in respect of education technology products they market or
          sell. Product Listings can be browsed and reviewed by other users of
          Koshish Marketing Solutions Private Limited. They aim to make it
          easier for Educators to find the education technology products they
          would like to purchase.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited reserves the right to
          refuse a request to list a product for any reason in its sole
          discretion.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited reserves the right in its
          sole discretion to remove any Product Listing from the Site or prevent
          its use on the Site at any time. In such circumstances, Koshish
          Marketing Solutions Private Limited will notify the Supplier who shall
          immediately stop using and promoting the use of the Koshish Marketing
          Solutions Private Limited.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You can delete your Koshish Marketing Solutions Private Limited user
          account by notifying us at{" "}
          <b style={{ color: "blue" }}>
            <u>info@koshishindia.in</u>
          </b>
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Deleting your user account means that you will no longer be able to
          manage your profile page or interact with reviewers. The product
          listing profile will remain available on Koshish Marketing Solutions
          Private Limited and can be searched for by users.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          We don’t delete a product listing from Koshish Marketing Solutions
          Private Limited unless the company has ceased trading. It is our goal
          to create a community of trustworthy and transparent reviews for the
          benefit of educators.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Product listings are added to Koshish Marketing Solutions Private
          Limited when a user searches for the solution, either by name or by
          using the web address of the solution.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Each review is user-generated content owned by the reviewer (the
          author in this instance). Once a product has been reviewed on Koshish
          Marketing Solutions Private Limited, we do not remove reviews from a
          product listing unless we're made aware that there's a breach of our
          community rules.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You confirm that any information, content or images provided by you
          when listing a product is/are complete and accurate and that you have
          obtained all required consents for such information, content and/or
          images to be used in accordance with the Content licence from you
          described below.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited will use reasonable skill
          and care when preparing a Product Listing requested by you. Koshish
          Marketing Solutions Private Limited makes no other warranties or
          representations in relation to Product Listings and, in particular but
          without limitation, makes no warranty or representation that any
          Product Listing will result in any increased interest in or sales of
          Supplier products.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited is not responsible for any
          information, content and/or images in Product Listings that have been
          provided by a Supplier, including (but not limited to) the accuracy or
          completeness of such information, content and/or images. If you are an
          Educator, you and your school (and, where relevant, parents)
          acknowledge and agree that Koshish Marketing Solutions Private Limited
          is not responsible or liable for any products or services that may be
          listed on the Site. It is your responsibility to ensure the
          suitability, safety, quality and reliability of such products.
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Awards & Certification
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          A request to get your product certified, will be subject to the fees
          set out in the list your product page: vedaacademy.org.in. The fees
          are exclusive of GST and other taxes which must be paid in addition by
          the Supplier. The Fees must be paid by the Supplier within 30 days of
          receipt of invoice in the manner stated on the invoice.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Except as otherwise stated in these Terms, the Fee is non-refundable.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited reserves the right to
          refuse to certify a product for any reason in its sole discretion.
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Content
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You acknowledge that all Content which you may have access to as part
          of, or through your use of, the Services are the sole responsibility
          of the person from which such content originated.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You should be aware that Content presented to you as part of the
          Services may be protected by intellectual property rights which are
          owned by those who provide Content to Koshish Marketing Solutions
          Private Limited (or by other persons or companies on their behalf).
          You may not modify, rent, lease, loan, sell, distribute or create
          derivative works based on this Content (either in whole or in part)
          unless you have been specifically told that you may do so by Koshish
          Marketing Solutions Private Limited or by the owners of that Content,
          in a separate agreement.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited reserves the right (but
          shall have no obligation) to pre-screen, review, flag, filter, modify,
          refuse or remove any or all Content from any part of the Services. You
          understand that by using the Services you may be exposed to Content
          that you may find offensive, indecent or objectionable and that, in
          this respect, you use the Services at your own risk. Koshish Marketing
          Solutions Private Limited takes no responsibility and assumes no
          liability for any Content posted, stored or uploaded by you or any
          third party, or for any loss or damage thereto, nor is Koshish
          Marketing Solutions Private Limited liable for any mistakes,
          defamation, slander, libel, omissions, falsehoods, obscenity or
          profanity you may encounter.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Amongst other things, the Site enables individuals to post reviews
          about products and services. Koshish Marketing Solutions Private
          Limited is not liable for any statements, representations or Content
          provided by users in any such reviews or otherwise on the Site.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You agree that you are solely responsible for (and that Koshish
          Marketing Solutions Private Limited has no responsibility to you or to
          any third party for) any Content that you create, transmit or display
          while using the Services and for the consequences of your actions
          (including any loss or damage which Koshish Marketing Solutions
          Private Limited may suffer) by doing so. In particular, Koshish
          Marketing Solutions Private Limited will not be responsible, or liable
          to any third party, for the content or accuracy of any Content posted
          by you or any other user of the Site.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Whenever you make use of a feature that allows you to upload content
          to the Site, or to make contact with other users of the Site, you must
          comply with the Terms. You warrant that any such contribution does
          comply with the Terms, and you will be liable to Koshish Marketing
          Solutions Private Limited and indemnify Koshish Marketing Solutions
          Private Limited for any breach of this warranty. This means you will
          be responsible for any loss or damage which Koshish Marketing
          Solutions Private Limited suffers as a result of your breach of
          warranty.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          No Content is intended to amount to advice on which you should rely.
          You must obtain professional or specialist advice before taking, or
          refraining from, any action on the basis of the Content on the Site.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          The views expressed by other users on the Site do not represent
          Koshish Marketing Solutions Private Limited views or values.
        </Typography>

        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Review Insights
        </Typography>

        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          The Account may allow the Supplier to access Koshish Marketing
          Solutions Private Limited review insights service, which provides
          certain analytics to Suppliers based on their user reviews and
          behaviour. The Supplier agrees that Review Insights are provided on an
          "as is" basis only and any information contained in Review Insights
          are not business advice or the opinion of Koshish Marketing Solutions
          Private Limited. The Supplier is solely responsible for any business
          decisions it makes using Review Insights.
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Content licence from you
        </Typography>

        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You retain copyright and any other rights you already hold in Content
          which you submit, post or display on or through, the Services. By
          submitting, posting or displaying the Content you give Koshish
          Marketing Solutions Private Limited a perpetual, irrevocable,
          worldwide, royalty-free, and non-exclusive licence to reproduce,
          adapt, modify, translate, publish, publicly perform, publicly display
          and distribute any Content which you submit, post or display on, or
          through, the Services, and you warrant that where such Content
          includes images or videos of individuals aged under 18, you have
          obtained parental consent for those images to be displayed as Content
          and to be used by Koshish Marketing Solutions Private Limited for
          promotional and marketing purposes in relation to the EdTech Impact
          programme. This licence is for the sole purpose of enabling Koshish
          Marketing Solutions Private Limited to display, distribute and promote
          the Services and the Koshish Marketing Solutions Private Limited
          programme as a whole.
        </Typography>

        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You understand that Koshish Marketing Solutions Private Limited, in
          performing the required technical steps to provide the Services to our
          users, may make such changes to your Content as are necessary to
          conform and adapt that Content to the technical requirements of
          connecting networks, devices, services or media. You agree that this
          licence shall permit Koshish Marketing Solutions Private Limited to
          take these actions.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You confirm and warrant to Koshish Marketing Solutions Private Limited
          that you have all the rights, power and authority necessary to grant
          the above licence. Other than as set out in these Terms, Koshish
          Marketing Solutions Private Limited acknowledges and agrees that it
          obtains no right, title or interest from you under these Terms in or
          to any Content that you submit, post, transmit or display on, or
          through, the Services, including any intellectual property rights
          which subsist in that Content (whether those rights happen to be
          registered or not, and wherever in the world those rights may exist).
          Unless you have agreed otherwise in writing with Koshish Marketing
          Solutions Private Limited, you agree that you are responsible for
          protecting and enforcing those rights and that Koshish Marketing
          Solutions Private Limited has no obligation to do so on your behalf.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You agree to Koshish Marketing Solutions Private Limited (and others
          permitted by Koshish Marketing Solutions Private Limited to act on its
          behalf) doing the following in connection with the provision of the
          Services:
        </Typography>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif",
            marginTop: "10px",
            fontSize: "18px"
          }}
        >
          <li style={{ marginTop: "5px" }}>
            using and performing the Services on your behalf (including but not
            limited to displaying, utilising and promoting the Content together
            with any other such information as is required in order for the
            Services to function effectively);
          </li>
          <li style={{ marginTop: "5px" }}>
            {" "}
            allowing others to utilise the Content as part of the Services; and
            You hereby agree to Koshish Marketing Solutions Private Limited
            using, and displaying as appropriate your trademarks, logos or
            similar proprietary rights solely in connection with the Services.
            None of the above affects your ownership rights in relation to
            Content you submit, post, transmit or display on, or through, the
            Site.
          </li>
        </ul>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Intellectual property rights
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          The Supplier is only permitted to use Koshish Marketing Solutions
          Private Limited logos, graphics and trademarks ("Koshish Marketing
          Solutions Private Limited Designs"), Score and reviews to the extent
          expressly granted in the Signup Form. The Supplier may only use
          Koshish Marketing Solutions Private Limited Designs that are made
          available to them. The Guidelines contain information for the Supplier
          about which Koshish Marketing Solutions Private Limited Designs the
          Supplier may use, how EdTech Impact Designs must be displayed and in
          what context.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          The Supplier agrees that Koshish Marketing Solutions Private Limited
          may use the Supplier 's name and logo on Koshish Marketing Solutions
          Private Limited websites, emails, newsletters, social media, all
          marketing materials and as a part of a general list of Koshish
          Marketing Solutions Private Limited Suppliers or Recommended Suppliers
          if they get certified.
        </Typography>

        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Linking to the Site
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You may link to any page of the Site, provided you do so in a way that
          is fair and legal and does not damage Koshish Marketing Solutions
          Private Limited’s or the Koshish Marketing Solutions Private Limited
          programme’s reputation or take advantage of it. You must not establish
          a link in such a way as to suggest any form of association, approval
          or endorsement on Koshish Marketing Solutions Private Limited part
          where none exists.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You must not establish a link to the Site in any website that is not
          owned by you. The Site must not be framed on any other site. Koshish
          Marketing Solutions Private Limited reserves the right to withdraw
          linking permission without notice. The website in which you are
          linking must comply in all respects with the content requirements set
          out in these Terms. If you wish to make any use of content on the Site
          other than that set out above, please contact{" "}
          <b style={{ color: "blue" }}>
            <u>info@koshishindia.in</u>
          </b>
        </Typography>

        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Copyright infringement
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          It is Koshish Marketing Solutions Private Limited 's policy to respond
          to notices of alleged copyright infringement that comply with
          applicable international intellectual property law and to terminate
          the accounts of repeat infringers.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You must not reproduce the Koshish Marketing Solutions Private Limited
          logo, Koshish Marketing Solutions Private Limited’s Awards or Koshish
          Marketing Solutions Private Limited intellectual property without our
          express written permission.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          If you wish to make an inquiry or report a copyright infringement, you
          can do so by contacting us either by post to do so by contacting us by
          email to{" "}
          <b style={{ color: "blue" }}>
            <u>info@koshishindia.in.</u>
          </b>
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Third party websites
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          The Site may contain links to other websites or resources on the
          Internet, and other websites or resources may contain links to the
          Site. You acknowledge that Koshish Marketing Solutions Private Limited
          is not responsible or liable for the content or any aspect of such
          third party websites and you use them at your own risk. The fact that
          Koshish Marketing Solutions Private Limited provides links to certain
          third party websites does not in any way constitute endorsement of, or
          association with such websites by Koshish Marketing Solutions Private
          Limited. Furthermore, you acknowledge and agree that Koshish Marketing
          Solutions Private Limited shall not be responsible or liable for any
          damage or loss caused by your use of any such website or resource.
        </Typography>

        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Termination
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited reserves the right to
          terminate your company listing, product listing or restrict access to
          all or any part of the Services including restricting access to or
          terminating any review or Product Listing at any time without notice
          or cause and such termination, restriction and/or removal will be
          effective immediately. This may result in the forfeiture and
          destruction of all information and/or Content associated with your
          membership. If you wish to terminate your account at any time, please
          contact us by email at{" "}
          <b style={{ color: "blue" }}>
            <u>info@koshindia.in</u>
          </b>{" "}
          Koshish Marketing Solutions Private Limited will terminate your
          account, and remove your information from the Site, as soon as
          reasonably possible.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited reserves the right to
          terminate your company listing, product listing or restrict access to
          all or any part of the Services including restricting access to or
          terminating any review or Product Listing at any time without notice
          or cause and such termination, restriction and/or removal will be
          effective immediately. This may result in the forfeiture and
          destruction of all information and/or Content associated with your
          membership. If you wish to terminate your account at any time, please
          contact us by email at{" "}
          <b style={{ color: "blue" }}>
            <u>info@koshindia.in</u>
          </b>{" "}
          Koshish Marketing Solutions Private Limited will terminate your
          account, and remove your information from the Site, as soon as
          reasonably possible.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          If a Supplier terminates their subscription with Koshish Marketing
          Solutions Private Limited, or downgrades to a free plan, the Supplier
          must cease all use of all paid for Koshish Marketing Solutions Private
          Limited designs, scores and widgets. Awards for previous years, that
          have been paid for, can still be used.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          When the supply of the Services to you is terminated, for whatever
          reason, certain legal rights, obligations and liabilities that you and
          Koshish Marketing Solutions Private Limited have benefited from and
          have been subject to, which have accrued over time whilst the Terms
          have been in force, or which are expressed to continue indefinitely,
          shall be unaffected by this cessation and shall continue to have full
          force and effect. These include (but are not limited to) your
          obligations in relation to conduct, Content, intellectual property
          rights, payment and indemnification, and Koshish Marketing Solutions
          Private Limited’s rights in relation to payment, Content, intellectual
          property rights, the warranty disclaimer below, limitation of
          liability and indemnification. The “General legal terms” section near
          the end of this document shall also continue to have full force and
          effect.
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Warranty Disclaimer
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You agree and acknowledge that Koshish Marketing Solutions Private
          Limited has no special relationship with you nor owes any fiduciary
          duty to you. You acknowledge that EdTech Impact cannot control, nor
          has it any obligation to act in relation to:
        </Typography>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif",
            marginTop: "10px",
            fontSize: "18px"
          }}
        >
          <li style={{ marginTop: "5px" }}>
            {" "}
            the way in which you or any other user of the Site utilise the
            Content;
          </li>
          <li style={{ marginTop: "5px" }}>
            the way in which you or any other user of the Site utilise the
            Content;
          </li>
          <li style={{ marginTop: "5px" }}>
            {" "}
            what Content you access via the Site;
          </li>
          <li style={{ marginTop: "5px" }}>
            {" "}
            the impact the Content may or may not have on you;
          </li>
          <li style={{ marginTop: "5px" }}>
            {" "}
            which users gains access to the Site; or
          </li>
          <li style={{ marginTop: "5px" }}>
            what actions you may take as a result of having been exposed to the
            Content.
          </li>
        </ul>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You agree that EdTech Impact is not liable for you having acquired or
          not acquired Content through the Site. Koshish Marketing Solutions
          Private Limited does not guarantee that the Site, or any Content on
          it, will always be available or be uninterrupted. Access to the Site
          is permitted on a temporary basis. Koshish Marketing Solutions Private
          Limited may suspend, withdraw, discontinue or change all or any part
          of the Site without notice. Koshish Marketing Solutions Private
          Limited will not be liable to you if for any reason the Site is
          unavailable at any time or for any period. The Services are provided
          "as is" and "as available" and are without warranty of any kind,
          express or implied, including, but not limited to, the implied
          warranties of title, non-infringement and fitness for a particular
          purpose, and any warranties implied by any course of performance or
          usage of trade, are expressly disclaimed.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited, and its directors,
          employees, agents, suppliers, partners and content providers do not
          warrant that: (a) the Services will be secure or available at any
          particular time or location; (b) the Site, or any Content on it, will
          be free from errors or omissions; (c) the Content on the Site is
          accurate, complete or up-to-date; (d) any defects or errors will be
          corrected; (e) any content or software available at or through the
          Services is free of viruses or other harmful components; or (f) the
          results of using the Services will meet your requirements. Your use of
          the Site and the Services is solely at your own risk.
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Indemnification
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          In the event that any action you take in relation to the Services
          constitutes an infringement by you or anyone else using your account
          of any intellectual property rights whatsoever, or constitutes
          defamation or the abuse of another user’s privacy, or if you are an
          Educator or Supplier and you do or fail to do anything which results
          in Koshish Marketing Solutions Private Limited suffering reputational
          or other damage, and such action results in claims, liabilities and
          expenses relating to such action, you shall defend, indemnify and hold
          unaccountable Koshish Marketing Solutions Private Limited, its
          employees, directors and all other parties associated with Koshish
          Marketing Solutions Private Limited from such claims, liabilities and
          expenses. This means you will be responsible for any loss or damage
          which Koshish Marketing Solutions Private Limited and/or suffer as a
          result of any such action. Koshish Marketing Solutions Private Limited
          reserves the right to assume the exclusive defence and control of any
          matter otherwise subject to indemnification by you, in which event you
          will assist and cooperate fully with Koshish Marketing Solutions
          Private Limited in asserting any available defences.
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Limitation of Liability
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Nothing in these Terms excludes or limits Koshish Marketing Solutions
          Private Limited’s liability for death or personal injury arising from
          Koshish Marketing Solutions Private Limited negligence, or its fraud
          or fraudulent misrepresentation, or any other liability that cannot be
          excluded or limited by India law.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          If using the Site for any commercial or business purposes, Koshish
          Marketing Solutions Private Limited has no liability to you whether in
          contract, tort (including negligence), breach of statutory duty or
          otherwise for any loss of profit, loss of business, business
          interruption, or loss of business opportunity.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited is not responsible for any
          loss or damage that is not foreseeable. Loss or damage is foreseeable
          if it was an obvious consequence of Koshish Marketing Solutions
          Private Limited breach of the Terms or if it was contemplated by you
          and Koshish Marketing Solutions Private Limited at the time we entered
          into this contract. Koshish Marketing Solutions Private Limited shall
          not be liable for any damage whether in contract, tort (including
          negligence), breach of statutory duty or otherwise, even if
          foreseeable, arising under or in connection with:
        </Typography>
        <ul
          mt={2}
          style={{
            fontWeight: "400",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif",
            marginTop: "10px",
            fontSize: "18px"
          }}
        >
          <li style={{ marginTop: "5px" }}>
            use of, or inability to use, the Site;
          </li>
          <li style={{ marginTop: "5px" }}>
            {" "}
            use of or reliance on any Content displayed on the Site;
          </li>
          <li style={{ marginTop: "5px" }}> loss of reputation or goodwill;</li>
          <li style={{ marginTop: "5px" }}>
            data loss, cost of procurement of substitute goods or services,
            consequential damages of any kind whatsoever, substitute goods or
            services (however arising); or
          </li>
          <li style={{ marginTop: "5px" }}>
            a virus, distributed denial-of-service attack, or other
            technologically harmful material that may infect your computer
            equipment, computer programs, data or other proprietary material due
            to your use of the Site or to your downloading of any content on it,
            or on any website linked to it.
          </li>
        </ul>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          If the Koshish Marketing Solutions Private Limited is found liable for
          any loss or damage that arises out of or is in any way connected with
          your use of the Site or Services, then its total liability (whether
          arising in contract, tort (including negligence), breach of statutory
          duty or otherwise) for all claims will in no event exceed the greater
          of: (a) the sums paid by you to Koshish Marketing Solutions Private
          Limited for the transaction(s) on the Site giving rise to the claim;
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Electronic notification policy
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          By using the Services, you consent to receiving from Koshish Marketing
          Solutions Private Limited all communications including notices,
          agreements, legally required disclosures, newsletters or other
          information in connection with the Services (collectively, "Contract
          Notices") electronically. Additionally, Koshish Marketing Solutions
          Private Limited reserves the right to contact Educators regarding
          their review and Suppliers regarding their use of the Services, by
          telephone or email. Koshish Marketing Solutions Private Limited may
          provide such electronic Contract Notices by posting them on the Site
          or by emailing an Educator or Supplier directly using the email
          address held on account or otherwise provided. If you desire to
          withdraw your consent to receive Contract Notices electronically, you
          must discontinue your use of both the Site and the Services. You can
          unsubscribe from the newsletter at any time without affecting your use
          of the Site or Services.
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Contact us about this Site
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          If you wish to make an inquiry or complaint, you can do so by email at
          &nbsp;
          <b style={{ color: "blue" }}>
            <u>info@koshishindia.in</u>
          </b>
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          General legal terms
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You agree that Koshish Marketing Solutions Private Limited may provide
          you with notices, including those regarding changes to the Terms, by
          email, or postings on the Site.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Koshish Marketing Solutions Private Limited may transfer its rights
          and obligations under the Terms to another organisation, and Koshish
          Marketing Solutions Private Limited will always notify you in writing
          if this happens, but this will not affect your rights or Koshish
          Marketing Solutions Private Limited obligations under the Terms.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          You agree that if Koshish Marketing Solutions Private Limited does not
          exercise or enforce, or delays in exercising or enforcing, any legal
          right or remedy which is contained in the Terms (or which Koshish
          Marketing Solutions Private Limited has the benefit of under any
          applicable law), this will not be taken to be a formal waiver Koshish
          Marketing Solutions Private Limited rights and that those rights or
          remedies will still be available to Koshish Marketing Solutions
          Private Limited (and does not mean that you do not have to comply with
          your obligations). If Koshish Marketing Solutions Private Limited does
          waive a default by you, Koshish Marketing Solutions Private Limited
          will only do so in writing, and that will not mean that Koshish
          Marketing Solutions Private Limited will automatically waive any later
          default by you.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          This contract is between you and Koshish Marketing Solutions Private
          Limited. A person who is not a party to the Terms has no right to rely
          on or enforce any of the Terms.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Each of the paragraphs of the Terms operates separately. If any court
          or relevant authority decides that any of them are unlawful, the
          remaining paragraphs will remain in full force and effect.
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          Where the words "in writing" appear in the Terms, this includes email.
          If you wish to contact Koshish Marketing Solutions Private Limited in
          writing, or if any provision in the Terms requires you to give EdTech
          Impact notice in writing, you can send this to Koshish Marketing
          Solutions Private Limited by e-mail to{" "}
          <b style={{ color: "blue" }}>
            <u>info@koshishindia.in</u>
          </b>
        </Typography>
        <Typography
          sx={{ fontSize: "21px", marginTop: "15px", fontWeight: "700" }}
        >
          Governing Law
        </Typography>
        <Typography sx={{ fontSize: "18px", marginTop: "10px" }}>
          The Terms, and your relationship with Koshish Marketing Solutions
          Private Limited under the Terms (including the subject-matter of the
          Terms and the formation of the contract, and any contractual or
          non-contractual dispute associated with your use of the Services), are
          governed by India law. You and Koshish Marketing Solutions Private
          Limited agree that the courts of Delhi shall have non-exclusive
          jurisdiction to resolve any such dispute. Notwithstanding this, you
          agree that Koshish Marketing Solutions Private Limited shall still be
          allowed to apply for injunctive remedies (or an equivalent type of
          urgent legal relief) in any jurisdiction.
        </Typography>
      </Box>
    </Container>
  );
}
