import React from 'react'
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const images = [
    {
        label: 'San Francisco – Oakland Bay Bridge, United States',
        imgPath:
            'https://cdn.pixabay.com/photo/2023/06/04/20/19/college-student-8040860_1280.jpg',
    },
    {
        label: 'Bird',
        imgPath:
            'https://cdn.pixabay.com/photo/2019/05/14/21/50/storytelling-4203628_1280.jpg',
    },
    {
        label: 'Bali, Indonesia',
        imgPath:
            'https://cdn.pixabay.com/photo/2016/01/19/01/42/library-1147815_1280.jpg',
    },
];

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function HomePageSlider() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Container sx={{ maxWidth: { xs: "95%", lg: "1300px" } }}>
            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                    bgcolor: 'background.default',
                }}
            >
            </Paper>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                component="img"
                                sx={{
                                    height: "414px",
                                    display: 'block',
                                    maxWidth: "100%",
                                    overflow: 'hidden',
                                    width: '100%',
                                    borderRadius: '20px'
                                }}
                                src={step.imgPath}
                                alt={step.label}
                            />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
            />
        </Container>
    );
}

export default HomePageSlider;
