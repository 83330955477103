import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  Menu,
  MenuItem,
  List,
  Hidden,
  Drawer,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { Container, Stack, Box } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TransperantLogo from "../assets/logo/TransperantLogo.svg";
import WhiteVedaLogo from "../assets/logo/headerWhiteLogo.png";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CallIcon from "@mui/icons-material/Call";
import MenuIcon from "@mui/icons-material/Menu";

function NavBar() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const [selectTab, setSelectTab] = useState("home");

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSpoken, setAnchorElSpoken] = React.useState(null);
  const [anchorElJNV, setAnchorElJNV] = React.useState(null);
  const open = Boolean(anchorEl);
  const openSpoken = Boolean(anchorElSpoken);
  const openJNV = Boolean(anchorElJNV);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSpoken = (event) => {
    setAnchorElSpoken(event.currentTarget);
    window.open("/english", "_blank");
    navigate("/english");
  };

  const handleClickJNV = (event) => {
    setAnchorElJNV(event.currentTarget);
    window.open("/jnv", "_blank");
    // navigate("/jnv");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSpoken = () => {
    setAnchorElSpoken(null);
  };

  const handleCloseJNV = () => {
    setAnchorElJNV(null);
  };

  return (
    <Container
      sx={{
        maxWidth: { xs: "100%", lg: "1700px" },
      }}
    >
      <AppBar
        position="static"
        sx={{
          background: "#FFFFFF",
          height: "73.6px",
          boxShadow: "none !important",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: { xs: "space-between", lg: "space-evenly" },
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {/* Image */}
          <img
            src={TransperantLogo}
            width="100px"
            height="90px"
            alt="logo"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
          {/* All Navbar Content but when screen size less then md then hide */}
          <Hidden mdDown>
            {/* -----------------------------------------------------------Section 01 */}
            <Stack direction="row" spacing={2}>
              <Button
                sx={{
                  color: "black",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  // backgroundColor: selectTab === "home" ? "lavender" : "",
                }}
                onClick={() => {
                  navigate("/");
                  setSelectTab("home");
                }}
              >
                Home
              </Button>

              <div>
                <Button
                  id="demo-customized-button"
                  aria-controls={
                    openSpoken ? "demo-customized-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openSpoken ? "true" : undefined}
                  // variant="outlined"
                  // color="warning"
                  disableElevation
                  onClick={(e) => handleClickSpoken(e)}
                  // endIcon={<KeyboardArrowDownIcon />}
                  sx={{
                    color: "#FF8331",
                    fontFamily: "Poppins",
                    fontWeight: 700,
                  }}
                >
                  Spoken English
                </Button>
                {/* <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorElSpoken}
                  open={openSpoken}
                  onClose={handleCloseSpoken}
                >
                  <MenuItem
                    onClick={() => {
                      handleCloseSpoken();
                      navigate("/k-12");
                    }}
                    disableRipple
                    sx={{ color: "#FF8331", fontFamily: "Poppins" }}
                  >
                    List 1
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseSpoken();
                      navigate("/english");
                    }}
                    disableRipple
                    sx={{ color: "#FF8331", fontFamily: "Poppins" }}
                  >
                    List 2
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseSpoken();
                      navigate("/english");
                    }}
                    disableRipple
                    sx={{ color: "#FF8331", fontFamily: "Poppins" }}
                  >
                    List 3
                  </MenuItem> 
                </StyledMenu>*/}
              </div>
              <div>
                <Button
                  id="demo-customized-button"
                  aria-controls={openJNV ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openJNV ? "true" : undefined}
                  // variant="outlined"
                  // color="warning"
                  disableElevation
                  onClick={handleClickJNV}
                  // endIcon={<KeyboardArrowDownIcon />}
                  sx={{
                    color: "#FF8331",
                    fontFamily: "Poppins",
                    fontWeight: 700,
                  }}
                >
                  JNV
                </Button>
                {/* <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorElJNV}
                  open={openJNV}
                  onClose={handleCloseJNV}
                >
                   <MenuItem
                    onClick={() => {
                      handleCloseJNV();
                      navigate("/k-12");
                    }}
                    disableRipple
                    sx={{ color: "#FF8331", fontFamily: "Poppins" }}
                  >
                    JNV List 1
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseJNV();
                      navigate("/english");
                    }}
                    disableRipple
                    sx={{ color: "#FF8331", fontFamily: "Poppins" }}
                  >
                    JNV List 2
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseJNV();
                      navigate("/english");
                    }}
                    disableRipple
                    sx={{ color: "#FF8331", fontFamily: "Poppins" }}
                  >
                    JNV List 3
                  </MenuItem> 
                </StyledMenu>*/}
              </div>

              <Button
                sx={{
                  color: "black",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  // backgroundColor: selectTab === "about" ? "lavender" : "",
                }}
                onClick={() => {
                  navigate("/about");
                  setSelectTab("about");
                }}
              >
                About
              </Button>

              <Button
                sx={{
                  color: "black",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  // backgroundColor: selectTab === "contact" ? "lavender" : "",
                }}
                onClick={() => {
                  navigate("/contact");
                  setSelectTab("contact");
                }}
              >
                Contact
              </Button>

              <div>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  // variant="outlined"
                  // color="warning"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  sx={{
                    color: "#000",
                    // border:
                    //   selectTab == "k-12"
                    //     ? "1px solid #FF8331"
                    //     : "1px solid black",
                    border: "1px solid black",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  Courses
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate("/k-12");
                      setSelectTab("k-12");
                    }}
                    disableRipple
                  >
                    K-12
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      // navigate("/english");
                    }}
                    disableRipple
                  >
                    Spoken English
                  </MenuItem>
                </StyledMenu>
              </div>
            </Stack>
            {/* Call section hide when screen size less then lg */}
            <Hidden lgDown>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{ opacity: "0" }}
              >
                <Box
                  sx={{
                    backgroundColor: "#e0e0e0",
                    height: "40px",
                    width: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "20px",
                  }}
                >
                  <CallIcon sx={{ color: "black" }} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      lineHeight: "15.6px",
                      fontWeight: 275,
                      color: "GrayText",
                    }}
                  >
                    Talk To Our Experts
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      lineHeight: "21px",
                      fontWeight: 600,
                      color: "black",
                    }}
                  >
                    7771046878
                  </Typography>
                </Box>
              </Stack>
            </Hidden>
            <Stack direction="row" spacing={2}>
              <a
                href="https://web.vedaacademy.org.in/register"
                rel="noreferrer"
                target="_parent"
                style={{ color: "white", textDecoration: "none" }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    color: "black",
                    borderRadius: "8px",
                    padding: "7px 20px",
                    border: "1px solid #ECEAEA",
                    "&:hover": { border: "1px solid black" },
                  }}
                >
                  Sign in
                </Button>
              </a>
              <a
                href="https://web.vedaacademy.org.in/register"
                rel="noreferrer"
                target="_parent"
                style={{ color: "white", textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    borderRadius: "8px",
                    background: "#FF8331",
                    "&:hover": { background: "#DC5F19" },
                  }}
                >
                  Register
                </Button>
              </a>
            </Stack>
          </Hidden>

          {/* Menu Icon visible only when screen size less then md */}
          <Hidden mdUp>
            <IconButton
              edge="start"
              color="black"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>

        <Hidden mdUp>
          <div style={{ width: "300px" }}>
            <Drawer
              anchor="right"
              open={openDrawer}
              onClose={handleDrawerToggle}
              PaperProps={{ style: { width: "300px" } }}
            >
              <Box textAlign={"end"}>
                {" "}
                <HighlightOffIcon
                  onClick={handleDrawerToggle}
                  sx={{ m: 2 }}
                  color="warning"
                />
              </Box>
              <List>
                <Stack direction="column" alignItems={"center"} spacing={3}>
                  <Button
                    sx={{
                      color: "black",
                      fontFamily: "Poppins",
                      fontWeight: 700,
                    }}
                    onClick={() => navigate("/")}
                  >
                    Home
                  </Button>
                  <div>
                    <Button
                      id="demo-customized-button"
                      aria-controls={
                        openSpoken ? "demo-customized-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openSpoken ? "true" : undefined}
                      // variant="outlined"
                      // color="warning"
                      disableElevation
                      onClick={handleClickSpoken}
                      // endIcon={<KeyboardArrowDownIcon />}
                      sx={{
                        color: "#FF8331",
                        fontFamily: "Poppins",
                        fontWeight: 700,
                      }}
                    >
                      Spoken English
                    </Button>
                    {/*<StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorElSpoken}
                      open={openSpoken}
                      onClose={handleCloseSpoken}
                    >
                      <MenuItem
                        onClick={() => {
                          handleCloseSpoken();
                          navigate("/k-12");
                        }}
                        disableRipple
                        sx={{ color: "#FF8331", fontFamily: "Poppins" }}
                      >
                        List 1
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseSpoken();
                          navigate("/english");
                        }}
                        disableRipple
                        sx={{ color: "#FF8331", fontFamily: "Poppins" }}
                      >
                        List 2
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseSpoken();
                          navigate("/english");
                        }}
                        disableRipple
                        sx={{ color: "#FF8331", fontFamily: "Poppins" }}
                      >
                        List 3
                      </MenuItem> 
                    </StyledMenu>*/}
                  </div>
                  <div>
                    <Button
                      id="demo-customized-button"
                      aria-controls={
                        openJNV ? "demo-customized-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openJNV ? "true" : undefined}
                      // variant="outlined"
                      // color="warning"
                      disableElevation
                      onClick={handleClickJNV}
                      endIcon={<KeyboardArrowDownIcon />}
                      sx={{
                        color: "#FF8331",
                        fontFamily: "Poppins",
                        fontWeight: 700,
                      }}
                    >
                      JNV
                    </Button>
                    {/*<StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorElJNV}
                      open={openJNV}
                      onClose={handleCloseJNV}
                    >
                      <MenuItem
                        onClick={() => {
                          handleCloseJNV();
                          navigate("/k-12");
                        }}
                        disableRipple
                        sx={{ color: "#FF8331", fontFamily: "Poppins" }}
                      >
                        JNV List 1
                      </MenuItem>
                       <MenuItem
                        onClick={() => {
                          handleCloseJNV();
                          navigate("/english");
                        }}
                        disableRipple
                        sx={{ color: "#FF8331", fontFamily: "Poppins" }}
                      >
                        JNV List 2
                      </MenuItem> 
                      <MenuItem
                        onClick={() => {
                          handleCloseJNV();
                          navigate("/english");
                        }}
                        disableRipple
                        sx={{ color: "#FF8331", fontFamily: "Poppins" }}
                      >
                        JNV List 3
                      </MenuItem>
                    </StyledMenu>*/}
                  </div>
                  <Button
                    sx={{
                      color: "black",
                      fontFamily: "Poppins",
                      fontWeight: 700,
                    }}
                    onClick={() => navigate("/about")}
                  >
                    About
                  </Button>
                  <Button
                    sx={{
                      color: "black",
                      fontFamily: "Poppins",
                      fontWeight: 700,
                    }}
                    onClick={() => navigate("/contact")}
                  >
                    Contact
                  </Button>
                  <div>
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      // variant="outlined"
                      // color="warning"
                      disableElevation
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                      sx={{
                        color: "#000",
                        border: "1px solid black",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      Courses
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          navigate("/k-12");
                        }}
                        disableRipple
                      >
                        K-12
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          navigate("/english");
                        }}
                        disableRipple
                      >
                        Spoken English
                      </MenuItem>
                    </StyledMenu>
                  </div>
                </Stack>
                <Stack
                  direction="column"
                  alignItems={"center"}
                  mt={2}
                  spacing={3}
                >
                  <a
                    href="https://web.vedaacademy.org.in/register"
                    rel="noreferrer"
                    target="_parent"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        color: "black",
                        borderRadius: "8px",
                        padding: "10px 20px",
                        border: "1px solid #ECEAEA",
                        "&:hover": { border: "1px solid black" },
                      }}
                    >
                      Sign in
                    </Button>
                  </a>
                  <a
                    href="https://web.vedaacademy.org.in/register"
                    rel="noreferrer"
                    target="_parent"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        borderRadius: "8px",
                        background: "#FF8331",
                        "&:hover": { background: "#DC5F19" },
                      }}
                    >
                      Register
                    </Button>
                  </a>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  mt={4}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ opacity: "0" }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#e0e0e0",
                      height: "40px",
                      width: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "20px",
                    }}
                  >
                    <CallIcon sx={{ color: "black" }} />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        lineHeight: "15.6px",
                        fontWeight: 275,
                        color: "GrayText",
                      }}
                    >
                      Talk To Our Experts
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      7771046878
                    </Typography>
                  </Box>
                </Stack>
              </List>
            </Drawer>
          </div>
        </Hidden>
      </AppBar>
    </Container>
  );
}

export default NavBar;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
