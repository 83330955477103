import { createSlice, isAnyOf, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

const initialState = {
  paymentInitStatusLoader: false,
  paymentInitStatus: {},
  verifyPaymentLoader: false,
  verifyPayment: {},
};

const paymentSlice = createSlice({
  name: "Payment",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(initStorePaymentAsync.pending), (state) => {
      state.paymentInitStatusLoader = true;
    });
    builder.addMatcher(
      isAnyOf(initStorePaymentAsync.fulfilled),
      (state, action) => {
        state.paymentInitStatusLoader = false;
        state.paymentInitStatus = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(initStorePaymentAsync.rejected),
      (state, action) => {
        state.paymentInitStatusLoader = false;
      }
    );

    builder.addMatcher(isAnyOf(verifyStorePaymentAsync.pending), (state) => {
      state.verifyPaymentLoader = true;
    });
    builder.addMatcher(
      isAnyOf(verifyStorePaymentAsync.fulfilled),
      (state, action) => {
        state.verifyPaymentLoader = false;
        state.verifyPayment = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(verifyStorePaymentAsync.rejected),
      (state, action) => {
        state.verifyPaymentLoader = false;
      }
    );
  },
  reducers: {},
});

export const {} = paymentSlice.actions;

export default paymentSlice.reducer;

export const initStorePaymentAsync = createAsyncThunk(
  "mobile/initStorePayment",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/initStorePayment`, payload, toolkit);
  }
);

export const verifyStorePaymentAsync = createAsyncThunk(
  "mobile/verifyStorePayment",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/verifyStorePayment`, payload, toolkit);
  }
);
