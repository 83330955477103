import React from "react";
import Form from "./Form";

function contactUs() {
  return (
    <>
      <Form />
    </>
  );
}

export default contactUs;
