import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { displayDataExploreMore as displayData } from "./constant";
import orangeDot from "../../assets/images/orangeDot.svg";
import liveClassPlatform from "../../assets/images/liveClassPlatform.svg";
import Detailed from "../../assets/images/detailedReport.svg";
import Aos from "aos";

function ExploreMore() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mx: { xs: 3, md: 0 },
        mb: 3,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "32px",
            fontWeight: 600,
            lineHeight: "40px",
          }}
        >
          OUR{" "}
          <Typography variant="span" sx={{ color: "#FF693D" }}>
            USP
          </Typography>
        </Typography>

        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "16px",
            lineHeight: "26px",
            mt: 3,
            textAlign: "center",
          }}
        >
          Download lessons and learn anytime, anywhere with the <br /> Veda app
        </Typography>
      </div>
      <Box
        sx={{
          backgroundColor: "#FF8331",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "row",
          columnGap: "10px",
          rowGap: 2,
          justifyContent: "center",
          alignItems: "flex-start",
          flexWrap: { xs: "wrap", lg: "nowrap" },
          padding: { xs: 3, sm: 7 },
          maxWidth: { xs: "90%", lg: "1195px" },
          mt: 3,
        }}
      >
        {displayData.map((ev) => (
          <Card
            key={ev.id}
            //data-aos="fade-up"
            sx={{
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              maxWidth: "206px",
              borderRadius: "16px",
              padding: "20px",
              minWidth: "200px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={ev.img} width="155.95px" height="113.41px" />
            </Box>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "23px",
                textAlign: "start",
              }}
            >
              {ev.heading}
            </Typography>

            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "18px",
                textAlign: "start",
                mt: 1.5,
              }}
            >
              {ev.text}
            </Typography>
            <a
              href="https://web.vedaacademy.org.in/register"
              rel="noreferrer"
              target="_parent"
              style={{
                color: "white",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "19px",
                  color: "#FF8331",
                  mt: 1.5,
                }}
              >
                Explore
              </Typography>
            </a>
          </Card>
        ))}

        <Card
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            padding: "20px",
          }}
          //data-aos="fade-up"
        >
          <Grid container>
            <Grid item xs={12} md={5}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                {/* <Box display="flex" flexDirection="row" alignItems="flex-start" >
                                    <img src={orangeDot} width="16px" height="16px" />
                                    <Typography sx={{
                                        fontFamily: "sans-serif",
                                        fontSize: '14px',
                                        lineHeight: "19.7px",
                                        color: '#FF8331',
                                        ml: 1.5
                                    }}>Live</Typography>
                                </Box> */}
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: 700,
                    lineHeight: "23px",
                  }}
                >
                  Detailed report
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    lineHeight: "18px",
                    mt: 2,
                  }}
                >
                  Insights That Drive Excellence: Uncover Your Performance with
                  Detailed Test Reports in Our App!
                </Typography>
                <a
                  href="https://web.vedaacademy.org.in/register"
                  rel="noreferrer"
                  target="_parent"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "19.7px",
                      color: "#FF8331",
                      mt: 1.5,
                    }}
                  >
                    Explore
                  </Typography>
                </a>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <img src={Detailed} style={{ maxWidth: "100%" }} />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Box>
  );
}

export default ExploreMore;
