import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import { dispalyDataChooseBoardSection as dispalyData } from './constant'
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarRateIcon from '@mui/icons-material/StarRate';
import appStore from '../../assets/images/appleStore.png';
import googleStore from '../../assets/images/googleStore.png';
import scholarOnBook from '../../assets/images/ScholarOnBook.svg';
import Aos from 'aos';

function ChooseYourBoard() {
    const navigate = useNavigate();
    const [track, setTrack] = useState(5)
    const [imageSize, setImageSize] = useState({ width: 500, height: 500 });

    const changeHandle = (newValue) => {
        setTrack(newValue)
    }


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 500) {
                setImageSize({ width: 250, height: 250 });
            } else if (window.innerWidth <= 800) {
                setImageSize({ width: 400, height: 400 });
            } else {
                setImageSize({ width: 500, height: 500 });
            }
        };

        // Attach the event listener on component mount
        window.addEventListener('resize', handleResize);

        // Call the handleResize initially to set the initial image size
        handleResize();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        Aos.init({ duration: 500 });
    }, []);

    return (
        <Container>
            {/* Top Half Section */}
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap-reverse' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }} data-aos='fade-right' >
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: { sm: '48px', xs: '40px' },
                            lineHeight: { sm: "72px", xs: '53px' },
                            fontWeight: 600
                        }}
                    >
                        Choose Your Board And <br />
                        Grade Level to <Typography variant='span' sx={{ color: '#FF8331' }}>Begin Your</Typography>
                        <br />  <Typography variant='span' sx={{ color: '#FF8331' }}>Learning Journey</Typography>
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: '20px',
                            lineHeight: "32px",
                            fontWeight: 400
                        }}
                    >
                        Gives you with the most recent online learning framework and <br />fabric that offer assistance your information developing.
                    </Typography>

                    <Box sx={{
                        display: 'flex', flexDirection: 'row',
                        columnGap: '30px', rowGap: '5px', justifyContent: 'center',
                        alignItems: 'center', flexWrap: 'wrap', mt: 3
                    }} data-aos='fade-left'>
                        <Button
                            href="https://web.vedaacademy.org.in/register"
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: '18px',
                                lineHeight: "28px",
                                fontWeight: 600,
                                borderRadius: '8px',
                                paddingTop: '15px',
                                paddingBottom: '15px',
                                paddingLeft: '15px',
                                paddingRight: '15px',
                                color: '#FFFFFF',
                                backgroundColor: '#FF8331',
                                '&:hover': {
                                    backgroundColor: '#DC5F19',
                                }
                            }}
                        >Get Started</Button>

                        <Button
                            onClick={() => {
                                window.scrollTo(0, 0)
                                navigate("/contact")
                            }}
                            sx={{
                                fontFamily: 'Poppins',
                                fontSize: '18px',
                                lineHeight: "28px",
                                fontWeight: 600,
                                borderRadius: '8px',
                                paddingTop: '15px',
                                paddingBottom: '15px',
                                paddingLeft: '15px',
                                paddingRight: '15px',
                                color: '#FF8331',
                                backgroundColor: '#FFE0CC',
                                '&:hover': {
                                    color: '#DC5F19',
                                    backgroundColor: '#FFE0CC',
                                }
                            }}
                        >Talk to Experts
                        </Button>
                    </Box>
                    <Box sx={{
                        display: 'flex', flexDirection: 'row',
                        columnGap: '20px', justifyContent: 'center',
                        alignItems: 'center', flexWrap: 'wrap', mt: 3
                    }} >
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Poppins',
                                    fontSize: '21.4px',
                                    lineHeight: "32.1px",
                                    fontWeight: 500,
                                    color: '#787A8D'
                                }}
                            >
                                STUDENT APP
                            </Typography>

                            <Rating value={track} onChange={changeHandle}
                                size='medium' precision={0.5}
                                // How It Looks Before Filled UP
                                emptyIcon={<StarOutlineIcon />}
                                // How It Looks after Filled UP
                                icon={<StarRateIcon fontSize='inherit' color='gold' />}
                                // To Just See
                                readOnly
                            />
                        </Box>
                        <Box sx={{
                            display: "flex", flexDirection: 'row', justifyContent: 'center',
                            alignItems: 'center', flexWrap: 'wrap', rowGap: '3px', columnGap: '10px'
                        }} data-aos='fade-right'>
                            <img src={appStore} width="159px" height="45px" />
                            <a
                                href="https://play.google.com/store/apps/details?id=com.koshish.veda&pli=1"
                                rel="noreferrer"
                                target="_parent"
                                style={{ color: "white", textDecoration: "none", cursor: 'pointer' }}
                            >
                                <img src={googleStore} width="159px" height="45px" />
                            </a>
                        </Box>
                    </Box>
                </Box>
                <img src={scholarOnBook} style={{ width: `${imageSize.width}px`, height: `${imageSize.height}px` }} data-aos='fade-left' />
            </Box>

            {/* Bottom Half Section */}
            <Grid container sx={{ my: 9 }}>
                <Grid item xs={12} md={8} >
                    <Grid container sx={{ columnGap: 7 }} >
                        {
                            dispalyData.map((ev) => (
                                <Grid item xs={12} sm={5} key={ev.id} data-aos='fade-right'>
                                    <img src={ev.img} width="37px" height="37px" style={{ marginTop: '15px' }} />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: '24px',
                                            lineHeight: "30px",
                                            fontWeight: 700,
                                            color: '#787A8D',
                                            mt: 1
                                        }}
                                    >
                                        {ev.headingOne} <br />
                                        {ev.headingTwo}
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            lineHeight: "24px",
                                            color: '#787A8D',
                                            mt: 1
                                        }}
                                    >
                                        {ev.textOne} <br />
                                        {ev.textTwo} <br />
                                        {ev.textThree} <br />
                                        {ev.textFour}
                                    </Typography>
                                </Grid>

                            ))
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} sx={{ mt: { xs: 3, md: 0 } }} data-aos='fade-left'>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: '40px',
                            lineHeight: "48px",
                            fontWeight: 600,
                        }}
                    >
                        Discover the <br /> Benefits We  <br />offer
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: '20px',
                            lineHeight: "32px",
                            mt: 2
                        }}
                    >
                        Get unlimited access to structured <br /> lessons & doubt clearing sessions
                    </Typography>

                    <Button
                        href="https://web.vedaacademy.org.in/register"
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: '18px',
                            lineHeight: "28px",
                            fontWeight: 600,
                            borderRadius: '8px',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                            paddingLeft: '15px',
                            paddingRight: '15px',
                            color: '#FFFFFF',
                            backgroundColor: '#FF8331',
                            mt: 2,
                            '&:hover': {
                                color: '#FFFFFF',
                                backgroundColor: '#DC5F19',
                            }
                        }}
                    >Start learning
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ChooseYourBoard;
