import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { courseDetails, sliderTop } from "../data";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useMediaQuery, useTheme } from "@mui/material";
import AddressForm from "../forms/AddressForm";
import { useFormik } from "formik";
import { _initialValues, _validation } from "./utils";
import CartItems from "./CartItems";
import ConfirmDialog from "../../../components/paymentPopup";
import { v4 as uuidv4 } from "uuid";
import {
  initStorePaymentAsync,
  verifyStorePaymentAsync,
} from "redux/phonepayPayment/phonepepayment";
import axios from "axios";
import sha256 from "crypto-js/sha256";

function Checkout({ productItems, setProductItems, cartItems, setCartItems }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    paymentInitStatusLoader,
    paymentInitStatus,
    verifyPaymentLoader,
    verifyPayment,
  } = useSelector((state) => state?.payment);

  const [open, setOpen] = useState(false);
  const [packageDetails, setPackageDetails] = useState({});
  const [quantity, setQuantity] = useState(1);

  const productsData = productItems ? productItems : cartItems;

  const totalSum = productsData?.reduce(
    (accumulator, item) => accumulator + item?.amount * item?.quantity,
    0
  );

  const onSubmit = async (values) => {
    let updatedProductsData = productsData.map((product) => {
      const { img, ratingIcon, ...rest } = product;
      return rest;
    });

    let orderPackage = {
      userName: values?.fullname,
      phone: values?.phone,
      email: values?.email,
      type: "Book",
      totalAmount: totalSum,
      address: {
        fullAddress: values?.address,
        city: values?.city,
        state: values?.state,
        pincode: values?.pin,
        addressType: values?.addressType,
      },
      products: updatedProductsData,
    };

    setPackageDetails(orderPackage);
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: _initialValues,
    onSubmit,
    validationSchema: _validation,
  });

  const proceedToPay = async (packageDetails) => {
    if (packageDetails?.products?.length !== 0) {
      dispatch(initStorePaymentAsync(packageDetails)).then((res) => {
        if (res?.payload?.success == true) {
          const UAT_PAY_API_URL =
            "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";

          const response = axios.post(
            UAT_PAY_API_URL,
            {
              request: res?.payload?.base64Payload,
            },
            {
              headers: {
                accept: "application/json",
                "Content-Type": "application/json",
                "X-VERIFY": res?.payload?.checksum,
              },
            }
          );

          console.log("response...", response);

          // setOpen(false);
          // let payload = { merchantTransactionId: res?.payload?.ref_id };
          // dispatch(verifyStorePaymentAsync(payload));
        }
      });
    }
  };

  return (
    <Box>
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Typography
                sx={{ fontSize: "18px", mt: 4, mb: 2, fontWeight: "600" }}
              >
                Personal Details
              </Typography>
              <AddressForm formik={formik} />
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography
                sx={{ fontSize: "18px", mt: 4, mb: 2, fontWeight: "600" }}
              >
                Product Details
              </Typography>
              <CartItems
                productItems={productItems}
                productsData={productsData}
                cartItems={cartItems}
                setCartItems={setCartItems}
                quantity={quantity}
                setQuantity={setQuantity}
                setProductItems={setProductItems}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  flexDirection: "column",
                  mt: 4,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    background: "#008035",
                    color: "#fff",
                    width: { xs: "90%", sm: "90%" },
                    mb: 2,
                    "&:hover": {
                      border: "1px solid #008035",
                      background: "#fff",
                      color: "#008035",
                    },
                  }}
                >
                  Order Now
                </Button>
                <Link
                  to="/english"
                  style={{ textDecoration: "none", marginBottom: "35px" }}
                >
                  Continue Shopping →
                </Link>
              </Box>
            </Grid>
          </Grid>
          {/*<Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                background: "#008035",
                color: "#fff",
                width: { xs: "50%", sm: "25%" },
                mb: 2,
                "&:hover": {
                  border: "1px solid #008035",
                  background: "#fff",
                  color: "#008035",
                },
              }}
            >
              Submit
            </Button>
            </Box> */}
        </form>
      </Container>
      <ConfirmDialog
        open={open}
        onClose={() => setOpen(false)}
        content={`Total (Inc. of all taxes) ₹${totalSum || 0} `}
        action={
          <Button
            fullWidth
            size="small"
            type="submit"
            variant="contained"
            onClick={() => proceedToPay(packageDetails)}
            sx={{
              borderRadius: "60px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 26px",
              height: "44px",
              width: { xs: "90%", sm: "45%" },
              color: "#ffff",
              backgroundColor: "green",
              mr: 2,
              mb: 2,
              mt: 4,
              "&:hover": {
                backgroundColor: "#009933",
              },
            }}
          >
            Proceed to Pay
          </Button>
        }
      />
    </Box>
  );
}

export default Checkout;
