import React from "react";
import OmrSheetMaterial from "./OmrSheetMaterial";

function OmrSheetIndex() {
  return (
    <>
      <OmrSheetMaterial />
    </>
  );
}

export default OmrSheetIndex;
