import React from 'react'
import { Container, Stack, Box } from "@mui/system";
import { Grid, Button, Typography, Rating } from "@mui/material";


function WraperCard(props) {
    return (
        <Box sx={{
            fontFamily: 'Poppins',
            fontWeight: 400
        }}>
            {props.children}
        </Box>
    )
}

export default WraperCard;
