import React from "react";
import Count from "./Count";
import AboutVeda from "./AboutVeda";
import LifeAtVeda from "./LifeAtVeda";
import JoinToday from "./JoinToday";
import ChooseYourBoard from "./ChooseYourBoard";
import MissionPurposeValue from "./MissionPurposeValue";

function AboutUsTwo() {
  return (
    <>
      <ChooseYourBoard />
      {/* <Count />
      <AboutVeda />
      <MissionPurposeValue />
      <LifeAtVeda />
      <JoinToday /> */}
    </>
  );
}

export default AboutUsTwo;
