import React from "react";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';




export default function RefundCancellation() {
  return (
    <Container>
      <Box sx={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
        <Typography align="center" fontWeight={700} fontSize="22px" sx={{marginTop:"50px"}}>
          Refund, Cancellation & Pack Expiration Policy
        </Typography>
        <Typography sx={{ fontSize: "18px",marginTop:"20px" }}>
          No refund or cancellation is permitted once the user has completed a
          transaction at vedaacademy.org.in for study packs etc (Valid only for
          purchases made through vedaacademy.org.in).
        </Typography>
        <ul
          mt={2}
          style={{
            fontSize: "17px",
            lineHeight: "25px",
            textAlign: "justify",
            color: "#333333",
            fontFamily: "Arial, Helvetica, sans-serif"
          }}
        >
          <li style={{ marginTop: "10px" }}>
            User needs to contact customer support through the registered mobile
            number or e-mail to enable effective provision of Services. Request
            from alternate details will not be entertained.
          </li>
          <li style={{ marginTop: "10px" }}>
            It is Your sole responsibility to ensure that the account
            information provided by You is accurate, complete and latest.
          </li>
          <li style={{ marginTop: "10px" }}>
            You acknowledge that Your ability to use Your account is dependent
            upon external factors such as internet service providers and
            internet network availability and the Company cannot guarantee
            accessibility to the Application at all times. In addition to the
            disclaimers set forth in the Terms, the Company shall not be liable
            to You for any damages arising from Your inability to log into Your
            account and access the services of the Application at any time.
          </li>
          <li style={{ marginTop: "10px" }}>
            Cancellation can happen when query is raised only with
            info@koshishindia.in.
          </li>
          <li style={{ marginTop: "10px" }}>
            Free look up period before applying for cancellation upgraded orders
            will be solely on primary order and not on upgraded order.
          </li>
          <li style={{ marginTop: "10px" }}>
            Your Subscribed package will continue until terminated. Unless you
            cancel your subscription before your payment, you authorize us to
            charge the package amount for the next billing cycle to your Payment
            Method.
          </li>
          <li style={{ marginTop: "10px" }}>
            Once you cancel your subscription, package amount will not be
            refunded.
          </li>
          <li style={{ marginTop: "10px" }}>
            In case of subscription of packages of duration less than one year,
            the fees paid shall be non-refundable in all circumstances including
            but not limited to cancellation of subscription by the user, etc.
          </li>
          <li style={{ marginTop: "10px" }}>
            In case of subscription of packages of duration of one year or one
            year above, the user shall be entitled to free look period of 7 days
            post the activation/delivery of Hardware. During such 7 days free
            look period, the User shall be entitled to cancel and/or amend/alter
            his/her subscription without any charges. Post the 7 days free look
            period, no request for cancellation and/or amendment/alteration will
            be entertained by the Company/Veda. Provided further that the
            Hardware and the Textbooks provided to the user are non-returnable
            and non-refundable and in case of cancellation for any reason
            whatsoever, including cancellation during the free look period, Veda
            shall be entitled to deduct charges for the Hardware and the
            Textbooks. At present the non-refundable cost of Hardware and
            textbook is as follows.
          </li>
          <li style={{ marginTop: "10px" }}>
            At,Veda the Pack expires as per the validity suggested at the time
            they are purchased. The subscription price will be made clear to you
            on our sign up pages or otherwise during the sign up process and may
            vary from time to time. You agree to pay at the rates notified to
            you at the time of your sign up. Sign up to premium content on our
            app is generally for a year. We may however choose to offer monthly
            payment options. If you choose to pay monthly, your subscription
            will continue until you tell us that you no longer wish to receive
            it, in which case you will stop paying the monthly amounts.
          </li>
        </ul>
      </Box>
    </Container>
  );
}
