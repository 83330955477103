import React from "react";
import LearnEnglishOnline from "./LearnEnglishOnline";
import AppStoreSection from "./AppStoreSection";
import EnglishCourseDetailsSection from "./EnglishCourseDetailsSection";
import BookdemoSection from "./BookdemoSection";
import VideoSection from "pages/K-12/VideoSection";
import ClassroomEnvironment from "./ClassroomEnvironment";
import YoutubeSection from "./YoutubeSection";
import CourseDetails from "./CourseDetails";
import BooksDetails from "./BooksDetails";
import Testimonial from "./Testimonial";
import Faq from "./Faq";
import SHQuestion from "./SHQuestion";
import SocialPage from "./SocialPage";

function Others({ updateCartItems, updateOrderItems }) {
  return (
    <>
      <LearnEnglishOnline />
      <YoutubeSection />
      <CourseDetails />
      <BooksDetails
        updateCartItems={updateCartItems}
        updateOrderItems={updateOrderItems}
      />
      <SocialPage />
      <AppStoreSection />
      {/*<EnglishCourseDetailsSection /> */}
      {/* <VideoSection /> */}
      <BookdemoSection backgroundColor={"#ffe6e6"} />
      {/*<ClassroomEnvironment />*/}
      {/*<Testimonial />*/}
      {/*<Faq />*/}
      {/*<SHQuestion />*/}
    </>
  );
}

export default Others;
