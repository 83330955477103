import React from "react";
import { Button, Typography, TextField, InputAdornment } from "@mui/material";
import { Container, Stack, Box } from "@mui/system";

import TransperantLogo from "../assets/logo/TransperantLogo.svg";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  return (
    <Stack
      sx={{
        backgroundColor: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        // mb:{xs:25,sm:0}
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          color: "white",
          borderTop: "2px solid white",
          maxWidth: { md: "95%", lg: "1300px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            rowGap: 2,
            mb: 4,
            mt: 3,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "18px",
              lineHeight: "26px",
              fontWeight: 600,
              mb: 2,
            }}
          >
            Company
          </Typography>

          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/");
            }}
          >
            Home
          </Typography>

          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/about");
            }}
          >
            About
          </Typography>

          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/contact");
            }}
          >
            Contact
          </Typography>
        </Box>

        {/* Third Section Start */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            rowGap: 2,
            mb: 4,
            mt: 3,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "18px",
              lineHeight: "26px",
              fontWeight: 600,
              mb: 2,
            }}
          >
            Legal
          </Typography>

          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/privacy-policy");
            }}
          >
            Privacy Policy
          </Typography>

          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/terms-and-service");
            }}
          >
            Terms & Services
          </Typography>

          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/refund-cancellation");
            }}
          >
            Refund Policy
          </Typography>
        </Box>

        {/* Fourth Section Start */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            rowGap: 2,
            mb: 4,
            mt: 3,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "18px",
              lineHeight: "26px",
              fontWeight: 600,
              mb: 2,
            }}
          >
            Quick Links
          </Typography>

          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/k-12");
            }}
          >
            K-12
          </Typography>

          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/english");
            }}
          >
            Spoken English
          </Typography>

          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            <br />
          </Typography>
        </Box>
      </Container>
      <Box sx={{ borderTop: "2px solid #fff", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ml: 3,
            mr: 3,
            mt: 2,
            mb: 2,
          }}
        >
          <Typography sx={{ color: "#fff", fontSize: "12px", mb: 2 }}>
            Copyright © KOSHISH MARKETING SOLUTIONS PVT LTD. All rights
            reserved.
          </Typography>
          <img src={TransperantLogo} alt="" width="100px" height="60px" />
        </Box>
      </Box>
    </Stack>
  );
}

export default Footer;
