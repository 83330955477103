import React from "react";
import PryQuestionMaterial from "./PryQuestionMaterial";

function PryQuestionIndex() {
  return (
    <>
      <PryQuestionMaterial />
    </>
  );
}

export default PryQuestionIndex;
