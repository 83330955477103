// For ChooseBoardSection
import circleRight from '../../assets/images/circleRight.svg';
import intractiveStudyMaterial from '../../assets/images/interactiveStudyMaterial.svg';
import unlimitedPractice from '../../assets/images/unlimitedPractice.svg';
import analysisReport from '../../assets/images/analysisReport.svg';

//For ClassRoomEnvironment
import eLiveClass from '../../assets/images/eLiveClass.svg';
import eDoubts from '../../assets/images/eDoubts.svg';
import eQuiz from '../../assets/images/eQuiz.svg';
import eReports from '../../assets/images/eReports.svg';
import eSyllabus from '../../assets/images/eSyllabus.svg';
import eHomeWork from '../../assets/images/eHomeWork.svg';

// For ChooseBoardSection
export const dispalyDataChooseBoardSection = [
    {
        id: 1,
        headingOne: 'Comprehensive',
        headingTwo: "coverage K-12",
        textOne: "Full access to K-12",
        textTwo: 'Study material covering all boards,',
        textThree: "classes and  subjects",
        textFour: '',
        img: circleRight
    },
    {
        id: 2,
        headingOne: 'Intelligently',
        headingTwo: "Consider Courses",
        textOne: "Media-rich outwardly locks in",
        textTwo: 'recordings in understanding with',
        textThree: "the syllabus",
        textFour: '',
        img: intractiveStudyMaterial
    },
    {
        id: 3,
        headingOne: 'Unlimited',
        headingTwo: "Practice Content",
        textOne: "Access practice content divided into",
        textTwo: 'categories such as score improvement',
        textThree: "questions, NCERT solutions and",
        textFour: 'trending content',
        img: unlimitedPractice
    },
    {
        id: 4,
        headingOne: 'Reports',
        headingTwo: "and Analytics",
        textOne: "In-depth reports to track your",
        textTwo: 'advance after tests and',
        textThree: "evaluations",
        textFour: '',
        img: analysisReport
    },
];


//For ClassRoomEnvironment
export const dispalyDataClassRoomEnvironment = [
    {
        id: 1,
        heading: 'Learn, Interact, and Thrive',
        textOne: "Unleash the Power of Live Classes in our App!",
        textTwo: "",
        img: eLiveClass,
        animationStyle: 'fade-right'
    },
    {
        id: 2,
        heading: 'Unlock Clarity',
        textOne: "Get Instant Doubt Solving Solutions,",
        textTwo: "Empowering Your Learning Journey!",
        img: eDoubts,
        animationStyle: 'fade-right'
    },
    {
        id: 3,
        heading: 'Test Your Knowledge, Ignite Your Success',
        textOne: "Take Quizzes and Tests to Excel in Every Subject!",
        textTwo: "",
        img: eQuiz,
        animationStyle: 'fade-right'
    },
    {
        id: 4,
        heading: 'Insights That Drive Excellence',
        textOne: " Uncover Your Performance with Detailed",
        textTwo: "Test Reports in Our App!",
        img: eReports,
        animationStyle: 'fade-left'
    },
    {
        id: 5,
        heading: 'Comprehensive Mastery',
        textOne: "Unleash Your Potential with Full",
        textTwo: "Syllabus Coverage in Our App!",
        img: eSyllabus,
        animationStyle: 'fade-left'

    },
    {
        id: 6,
        heading: 'Accelerate Your Progress',
        textOne: " Excel with Homework and Worksheets,",
        textTwo: "Your Pathway to Success!",
        img: eHomeWork,
        animationStyle: 'fade-left'

    },
];