import React from "react";
import { Grid, Card, Box, Typography, Button } from "@mui/material";
import mobileImg from "../../assets/images/jnv/mobile.png";
import appStore from "../../assets/images/appleStore.png";
import googleStore from "../../assets/images/googleStore.png";
import rightIcon from "../../assets/images/jnv/rightIcon.png";

function LearnAnywhere() {
  return (
    <>
      <Grid container sx={{ mt: 8, mb: 8 }}>
        <Grid xs={12} md={6}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
            height="409px"
            //data-aos="fade-left"
            sx={{ padding: "0px 16px" }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: { xs: "30px", sm: "48px" },
                lineHeight: "60px",
                fontWeight: 600,
                textAlign: "left",
                color: "#000",
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              Start your learning journey{" "}
              <span style={{ color: "#000" }}>now!</span>
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "36px",
                mt: 4,
                mb: 4,
              }}
            >
              <img
                src={rightIcon}
                alt="right icon"
                width="20px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />{" "}
              Live and recorded classes
              <br />
              <img
                src={rightIcon}
                alt="right icon"
                width="20px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />{" "}
              Free study material
              <br />
              <img
                src={rightIcon}
                alt="right icon"
                width="20px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />{" "}
              Quick doubt resolution
            </Typography>

            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              Download the app
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                rowGap: "3px",
                columnGap: "20px",
                mt: 2,
              }}
            >
              <a
                href="https://apps.apple.com/in/app/veda-academy/id6448278906"
                rel="noreferrer"
                target="_blank"
                style={{
                  color: "white",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <img src={appStore} width="150px" height="45px" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.koshish.veda&pli=1"
                rel="noreferrer"
                target="_blank"
                style={{
                  color: "white",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                <img src={googleStore} width="150px" height="45px" />
              </a>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={6}>
          <Box display="flex" justifyContent="center">
            <img src={mobileImg} alt="mobile image" height="409" />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default LearnAnywhere;
