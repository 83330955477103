import { configureStore } from "@reduxjs/toolkit";
import PaymentStatusSlice from "redux/PaymentStatus";
import PhonepayStatusSlice from "redux/PaymentStatus";
import paymentSlice from "redux/phonepayPayment/phonepepayment";

const store = configureStore({
  reducer: {
    paymentstatus: PaymentStatusSlice,
    phonepaystatus: PhonepayStatusSlice,
    payment: paymentSlice,
  },
});

export default store;
