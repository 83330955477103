import React from "react";
import { Container, Stack, Box } from "@mui/system";
import SearchImg from "../../../assets/images/Search.png";
import { programList } from "../data";
import QueryForm from "./QueryForm";
import "./style.css";

function RightContents() {
  return (
    <Box
      sx={{
        width: "95%",
        mt: { xs: "5px", sm: "290px" },
        ml: { xs: "15px", sm: "0" },
        mr: { xs: "15px", sm: "0" },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100px",
          backgroundColor: "#fff",
          border: "1px solid rgba(52, 52, 52, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box
          sx={{
            width: "60%",
            height: "35px",
            border: "1px solid rgba(52, 52, 52, 0.5)",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input type="text" placeholder="Type your search" />
          <img src={SearchImg} alt="search box" width="20px" />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {programList?.map((item, index) => {
          return (
            <Box
              key={index + "kkk"}
              sx={{ padding: "25px 40px", backgroundColor: "#fff", mb: 2 }}
            >
              <img src={item?.img} alt="" width="100%" height="auto" />
            </Box>
          );
        })}
      </Box>
      <QueryForm />
    </Box>
  );
}

export default RightContents;
