import React from "react";
import ReactDOM from "react-dom/client";
import AppRouter from "AppRouter";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import store from "redux/store";
import "assets/main.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  </ReduxProvider>
);
