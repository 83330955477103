import React, { useEffect } from 'react'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {dispalyDataClassRoomEnvironment as dispalyData} from './constant'
import Aos from 'aos';

function ClassroomEnvironment() {
    useEffect(() => {
        Aos.init({ duration: 500 });
    }, []);

    return (
        <Container>
            <Box sx={{
                display: 'flex', flexDirection: 'column',
                justifyContent: 'center', alignItems: 'center',
                my: 5
            }} data-aos='fade-down'>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: '40px',
                        lineHeight: "60px",
                        fontWeight: 600
                    }}
                >
                    Create a Classroom Environment at Home
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        lineHeight: "32px",
                        color: '#787A8D'
                    }}
                >
                    The impressive results of this intervention have lifelong impacts.
                </Typography>
                <Grid container sx={{ mt: 3 }}>
                    {
                        dispalyData.map((ev) => (
                            <Grid item xs={12} sm={6} md={4} key={ev.id} data-aos={ev.animationStyle}>
                                <Box
                                    sx={{
                                        display: 'flex', flexDirection: 'column',
                                        justifyContent: 'center', alignItems: 'center',
                                    }}
                                >
                                    <img src={ev.img} alt='' style={{ maxWidth: '100%', marginTop: '10px' }} />

                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: '20px',
                                            lineHeight: "32px",
                                            fontWeight: 600,
                                            color: '#787A8D',
                                            mt: 3,
                                            textAlign: 'center'
                                        }}
                                    >
                                        {ev.heading}
                                    </Typography>

                                    <Typography
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: '14px',
                                            lineHeight: "22.4px",
                                            color: '#787A8D',
                                            textAlign: 'center',
                                            mt: { xs: 3, md: ev.id === 3 ? 1.5 : 3 }
                                        }}
                                    >
                                        {ev.textOne} <br />
                                        {ev.textTwo}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        </Container>
    )
}

export default ClassroomEnvironment;
