import React, { useState } from "react";
import { Container, Grid, Card, Box, Typography, Button } from "@mui/material";
import Slider from "react-slick";
import { booksData } from "./data";
import "./style.css";
import { useNavigate } from "react-router-dom";

function BooksDetails({ updateCartItems, updateOrderItems }) {
  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cartHandler = (item) => {
    let prodArray = JSON.parse(localStorage.getItem("prodData")) || [];

    if (!Array.isArray(prodArray)) {
      prodArray = [];
    }

    const isItemExists = prodArray.some((prodItem) => prodItem.id === item.id);
    if (isItemExists) {
      // localStorage.removeItem("prodData");

      localStorage.removeItem("prodItem");
      const updatedProdItem = JSON.parse(localStorage.getItem("prodItem"));

      updateOrderItems(updatedProdItem);
      alert("Product is already added");
    } else {
      localStorage.removeItem("prodItem");
      const updatedProdItem = JSON.parse(localStorage.getItem("prodItem"));

      updateOrderItems(updatedProdItem);

      prodArray.push(item);
      localStorage.setItem("prodData", JSON.stringify(prodArray));
      const updatedProd = JSON.parse(localStorage.getItem("prodData"));
      updateCartItems(updatedProd);
      alert("Item added to cart");
    }
  };

  const orderHandler = (item) => {
    let prodArray = JSON.parse(localStorage.getItem("prodItem")) || [];

    if (!Array.isArray(prodArray)) {
      prodArray = [];
    }

    prodArray = [item];
    localStorage.setItem("prodItem", JSON.stringify(prodArray));
    const updatedProd = JSON.parse(localStorage.getItem("prodItem"));
    updateOrderItems(updatedProd);
    if (updatedProd?.length > 0) {
      navigate("/books/checkout");
    }
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#fff", pt: 4, pb: 4, mt: 1 }}>
        <Container>
          <Grid container>
            <Grid xs={12} md={12}>
              <Box
                //data-aos="fade-down"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  pb: 4,
                }}
              >
                <Typography sx={{ fontSize: "36px", fontWeight: "bold" }}>
                  Books
                </Typography>
                <Typography sx={{ fontSize: "16px", mt: 1 }}>
                  Explore our curated collection of books to help you learn and
                  improve your English skills.
                </Typography>
              </Box>
              <Box className="courseSlider">
                <Slider {...settings}>
                  {booksData?.map((item, index) => {
                    return (
                      <Grid xs={12} md={12} key={item.id}>
                        <Box
                          sx={{
                            position: "relative",
                            mb: 4,
                            width: { xs: "90%", sm: "90%", md: "38%" },
                            margin: "auto",
                          }}
                        >
                          <Card
                            key={index + "course"}
                            sx={{
                              width: { xs: "95%", sm: "90%", md: "85%" },
                              height: "auto",
                              borderRadius: "16px",
                              marginBottom: "10px",
                              backgroundColor: item.bgColor,
                              boxShadow: "none",
                              border: "1px solid #ccc",
                            }}
                          >
                            <Box
                              sx={{
                                px: 3,
                                pt: 2,
                                pb: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                flexWrap: "wrap",
                                display: "relative",
                                backgroundColor: "lightgoldenrodyellow",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={item.img}
                                  alt=""
                                  width="60%"
                                  style={{ borderRadius: "10px" }}
                                />
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                width: "100%",
                                backgroundColor: "#F3F3F3",
                                pt: 2,
                                pb: 2,
                                pl: 2,
                                pr: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  mt: 1,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: "600",
                                    color: "#EA5806",
                                  }}
                                >
                                  {item?.title}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                  }}
                                >
                                  {item?.authorName}
                                </Typography>
                                <Typography sx={{ mb: 2, mt: 1 }}>
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      color: "#EA5806",
                                    }}
                                  >
                                    Rs {item?.amount} only
                                  </span>{" "}
                                  &#160;&#160;&#160;{" "}
                                  <span
                                    style={{
                                      textDecoration: "line-through",
                                      fontSize: "11px",
                                    }}
                                  >
                                    Rs {item?.price}
                                  </span>
                                </Typography>

                                <Button
                                  variant="contained"
                                  sx={{
                                    textTransform: "none",
                                    background: "#ef6415",
                                    padding: "5px 20px",
                                    fontSize: "10px",
                                    color: "#fff",
                                  }}
                                  // onClick={() => cartHandler(item)}
                                >
                                  Add to Cart
                                </Button>
                                <Button
                                  variant="contained"
                                  sx={{
                                    textTransform: "none",
                                    background: "green",
                                    padding: "5px 20px",
                                    fontSize: "10px",
                                    ml: { xs: 8, sm: 12, md: 12, lg: 12 },
                                  }}
                                  // onClick={() => orderHandler(item)}
                                >
                                  Order Now
                                </Button>
                              </Box>
                            </Box>
                          </Card>
                          <Box
                            sx={{
                              position: "absolute",
                              top: {
                                xs: "18px",
                                sm: "18px",
                                md: "18px",
                                lg: "18px",
                              },
                              right: {
                                xs: "15px",
                                sm: "65px",
                                md: "54px",
                                lg: "65px",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: "#ea5806",
                                padding: "2px 10px",
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "13px", color: "#fff" }}
                              >
                                Sale
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Slider>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default BooksDetails;
