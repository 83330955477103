import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { dispalyDataLeftSide, dispalyDataRightSide } from "./constant";
import englishBoyBook from "../../assets/images/englishBoyBook.svg";
import Aos from "aos";
import Card from "@mui/material/Card";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CourseData, sliderImages, popularCourses } from "./data";
import { useMediaQuery, useTheme } from "@mui/material";
import Banner01 from "../../assets/images/jnv/banner01.png";
import BannerMobile01 from "../../assets/images/jnv/banner-mobile.png";
import Phoneicon from "../../assets/images/jnv/phoneicon.png";
import iconTop from "../../assets/images/jnv/icontop.png";
import "./style.css";

function EnglishCourseDetailsSection() {
  const theme = useTheme();
  const isSmDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentSlide, setCurrentSlide] = useState(0);
  const [imageSize, setImageSize] = useState({ width: 500, height: 500 });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setImageSize({ width: 250, height: 250 });
      } else if (window.innerWidth <= 800) {
        setImageSize({ width: 400, height: 400 });
      } else {
        setImageSize({ width: 303.91, height: 415.34 });
      }
    };

    // Attach the event listener on component mount
    window.addEventListener("resize", handleResize);

    // Call the handleResize initially to set the initial image size
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          paddingBottom: "0px",
          marginTop: "15px",
        }}
      >
        <Grid xs={12} md={12}>
          <Box sx={{ position: "relative" }}>
            <Box sx={{ xs: "auto", sm: "500px", md: "550px" }}>
              <LazyLoadImage
                alt="Slider image"
                effect="blur"
                src={isSmDevice ? BannerMobile01 : Banner01}
                width="100%"
                height={isSmDevice ? "auto" : "550px"}
                borderRadius="16px"
                objectFit="cover"
              />
            </Box>

            <Box
              sx={{
                mt: { xs: -1, sm: 0 },
                pt: 3,
                position: { xs: "relative", sm: "absolute", md: "absolute" },
                top: { xs: 0, sm: 65, md: 45 },
                left: { xs: 0, sm: 25, md: 30 },
                paddingTop: { xs: "10px", sm: "10px", md: "5px" },
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: {
                  xs: "center",
                  sm: "flex-start",
                  md: "flex-start",
                },
                alignItems: {
                  xs: "center",
                  sm: "flex-start",
                  md: "flex-start",
                },
                backgroundColor: {
                  xs: "#ea5806",
                  sm: "transparent",
                  md: "transparent",
                },
              }}
              //data-aos="fade-right"
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: { xs: "28px", sm: "35px", md: "55px" },
                  lineHeight: { xs: "40px", sm: "45px", md: "62px" },
                  fontWeight: 600,
                  color: { xs: "#fff", sm: "#fff", md: "#fff" },
                  textAlign: { xs: "center", sm: "left", md: "left" },
                }}
              >
                <span style={{ color: "#fcde5a" }}>Crack</span>{" "}
                <img src={iconTop} width="70px" alt="" />
                <br />
                Navodaya Vidyalaya <span style={{ fontSize: "23px" }}>&</span>
                <br />
                Sainik School Entrance{" "}
                <span style={{ fontSize: "23px" }}>With us</span>
                <br />
              </Typography>
              <Typography
                sx={{
                  color: "#fcde5a",
                  fontSize: { xs: "20px", sm: "23px", md: "25px" },
                  textAlign: { xs: "center", sm: "left", md: "left" },
                }}
              >
                Navodaya Ki Taiyaari, Veda Ki Zimmedari
              </Typography>

              <Box
                sx={{
                  width: { sm: "42%", md: "36%", lg: "26%" },
                  height: "48px",
                  backgroundColor: "transparent",
                  border: "1px solid #fff",
                  borderRadius: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: { xs: "15px" },
                  mb: { xs: "10px" },
                }}
              >
                {/*<SearchIcon /> */}
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#fff",
                    mr: 1,
                    ml: 2,
                  }}
                >
                  +91
                </Typography>
                <input
                  className="white-placeholder"
                  placeholder="Mobile Number"
                  style={{
                    width: "49%",
                    border: "none",
                    outline: "none",
                    backgroundColor: "transparent",
                    color: "white",
                  }}
                />
                <Button
                  variant="contained"
                  sx={{
                    width: "38%",
                    background: "#fcde5a",
                    fontSize: { xs: "10px", sm: "12px", md: "12px" },
                    borderRadius: "0px 15px 15px 0px",
                    color: "black",
                    fontWeight: "600",
                    "&:hover": {
                      backgroundColor: "#f5dd42",
                    },
                    padding: {
                      xs: "14px 20px",
                      sm: "13px 20px",
                      md: "13px 20px",
                      lg: "13px 22px",
                    },
                    textTransform: "none",
                  }}
                >
                  Join for Free
                </Button>
              </Box>

              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  lineHeight: "23px",
                  fontWeight: 400,
                  my: 0,
                  pb: { xs: 2, sm: 1, md: 0 },
                  color: "#fff",
                  color: { xs: "#fff", sm: "#fff", md: "#fff" },
                }}
              >
                We will send an otp for verification
              </Typography>
            </Box>

            <Box
              sx={{
                position: {
                  xs: "relative",
                  sm: "absolute",
                  md: "absolute",
                },
                top: { xs: "-359px", sm: "20px" },
                right: { xs: "129px", sm: "80px" },
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box sx={{ display: "flex", gap: 1 }}>
                <img
                  src={Phoneicon}
                  alt="icon phone"
                  width="20px"
                  height="20px"
                />
                <Typography
                  sx={{ fontSize: "12px", textAlign: "center", color: "#fff" }}
                >
                  <span style={{ fontWeight: "400" }}>
                    Need help? talk to our team
                  </span>
                  <br /> <span style={{ fontWeight: "600" }}>7895305976</span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Container>
          <Grid container>
            <Box
              sx={{
                width: "92%",
                backgroundColor: "#fcde5a",
                borderRadius: "10px",
                paddingLeft: "10px",
                paddingBottom: "10px",
                position: "relative",
                top: { xs: "-45px", sm: "-30px", md: "-50px" },
                left: { xs: "10px", sm: "15px", md: "40px" },
              }}
            >
              <Grid xs={12} md={12}>
                <Box sx={{ mb: 4 }}>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "35px", sm: "46px", md: "48px" },
                      lineHeight: "48px",
                      fontWeight: 600,
                      textAlign: "center",
                      mt: 2,
                      pr: 1,
                      color: "#000",
                    }}
                  >
                    Why choose Veda Academy?
                  </Typography>
                </Box>
              </Grid>
              <Grid container>
                {popularCourses?.map((item, index) => {
                  return (
                    <Grid xs={6} sm={4} md={2} key={item.id}>
                      <Card
                        sx={{
                          width: "92%",
                          height: "auto",
                          borderRadius: "16px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          marginBottom: "10px",
                          backgroundColor: "white",
                        }}
                        //data-aos="fade-left"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "140px",
                          }}
                        >
                          <img
                            src={item?.icon}
                            alt="icon"
                            width="60px"
                            height="60px"
                          />
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "14px",
                              fontWeight: "600",
                              fontFamily: "Poppins",
                              mt: 1,
                            }}
                          >
                            {item?.text}
                          </Typography>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default EnglishCourseDetailsSection;
