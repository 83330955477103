import React from 'react'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

import './ErrorPage.css'

function PageNotFound() {
    const navigate = useNavigate();

    return (
        <Box className='backgroundImage'>
            <Container>
                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: { xs: '40px', sm: '48px', md: '100px' },
                            lineHeight: { sm: "72px", xs: '53px' },
                            fontWeight: 600
                        }}
                    >
                        4
                        <Typography variant='span' sx={{ color: '#FF8331' }}>0</Typography>
                        4
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: { xs: '40px', sm: '48px' },
                            lineHeight: { sm: "72px", xs: '53px' },
                            fontWeight: 600
                        }}
                    >
                        Page&nbsp;
                        <Typography variant='span' sx={{ color: '#FF8331' }}>Not&nbsp;</Typography>
                        Found
                    </Typography>

                    <Button
                        variant='contained'
                        onClick={() => {
                            window.scrollTo(0, 0)
                            navigate("/")
                        }}
                        sx={{
                            color: '#FFFFFF', mt: 3,
                            borderRadius: '8px',
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            lineHeight: "25.6px",
                            fontWeight: 600,
                            paddingTop: 1.3,
                            paddingBottom: 1.3,
                            paddingLeft: 2,
                            paddingRight: 2,
                            boxShadow: 7,
                            backgroundColor: "#FF8331",
                            '&:hover': {
                                backgroundColor: "#DC5F19",
                            },
                        }}
                    >
                        GO Back To Home Page
                    </Button>

                </Box>

            </Container>
        </Box>
    )
}

export default PageNotFound;
