import sicon01 from "../../assets/images/english/sicon01.png";
import youtubeIcon from "../../assets/images/english/youtubeIcon.png";
import youtubeImg from "../../assets/images/english/youtubeImg.png";
import fIcon01 from "../../assets/images/english/fIcon01.png";
import fIcon02 from "../../assets/images/english/fIcon02.png";
import fIcon03 from "../../assets/images/english/fIcon03.png";
import fIcon04 from "../../assets/images/english/fIcon04.png";
import fIcon05 from "../../assets/images/english/fIcon05.png";
import fIcon06 from "../../assets/images/english/fIcon06.png";
import testImg from "../../assets/images/english/testImg.png";
import slider01 from "../../assets/images/english/slider01.jpg";
import slider02 from "../../assets/images/english/slider02.png";
import UserIcon from "../../assets/images/english/userIcon.png";
import CourseIcon from "../../assets/images/english/courseicon.jpg";
import RatingIcon from "../../assets/images/english/rating.png";
import TimeIcon from "../../assets/images/english/time-icon.png";
import VideoIcon from "../../assets/images/english/video-icon.png";
import BookImg from "../../assets/images/english/book.jpg";

export const courseDetails = [
  {
    id: 1,
    topText: "",
    text: "Top Quality Content",
    img: sicon01,
    border: "1px solid #fff",
  },
  {
    id: 2,
    topText: "",
    text: "Learn Anytime, Anywhere",
    img: sicon01,
    border: "1px solid #fff",
  },
  {
    id: 3,
    topText: "",
    text: "In-Depth Analytics",
    img: sicon01,
    border: "none",
  },
];

export const youtubeData = [
  {
    id: 1,
    head1: "We’re on YouTube!",
    text: "Check our latest videos on YouTube",
    btnText: "View us on Youtube",
    YoutubeIcon: youtubeIcon,
    img: youtubeImg,
  },
];

export const sliderTop = [
  { id: 1, img: slider01, isOpen: false, cursor: "auto" },
  { id: 1, img: slider02, isOpen: true, cursor: "pointer" },
];

export const youtubeSectionData = [
  {
    id: 1,
    // img: youtubeBanner,
    img: "https://img.youtube.com/vi/N50axTf7Q2o/maxresdefault.jpg",
    caption: "Mathematics",
    icon: UserIcon,
    title: "Food: Variety, Materials and Sources",
    text: "Master the Concepts",
    imgIcon: UserIcon,
    videoUrl: "https://www.youtube.com/watch?v=N50axTf7Q2o&t=655s",
  },
  {
    id: 2,
    // img: youtubeBanner,
    img: "https://img.youtube.com/vi/hdbonJJNVHY/maxresdefault.jpg",
    caption: "Mathematics",
    icon: UserIcon,
    title: "Food: Variety, Materials and Sources",
    text: "Master the Concepts",
    imgIcon: UserIcon,
    videoUrl: "https://www.youtube.com/watch?v=hdbonJJNVHY",
  },
  {
    id: 3,
    // img: youtubeBanner,
    img: "https://img.youtube.com/vi/wTky9lLdtEA/maxresdefault.jpg",
    caption: "Mathematics",
    icon: UserIcon,
    title: "Food: Variety, Materials and Sources",
    text: "Master the Concepts",
    imgIcon: UserIcon,
    videoUrl: "https://www.youtube.com/watch?v=wTky9lLdtEA",
  },
];

export const courseData = [
  {
    id: 1,
    img: CourseIcon,
    ratingIcon: RatingIcon,
    text1: "Spoken English",
    text2: "Basics of Spoken English",
    sellingPrice: "",
    price: "",
    duration: "17 Hours 28 mins",
    courseCount: 19,
    timeicon: TimeIcon,
    videoicon: VideoIcon,
  },
];

export const booksData = [
  {
    id: 1,
    img: BookImg,
    ratingIcon: RatingIcon,
    title: "English",
    authorName: "By: Preeti Tiwari",
    amount: "499",
    price: "999",
    quantity: 1,
  },
];

export const dispalyDataClassRoomEnvironment = [
  {
    id: 1,
    heading: "Learn, Interact",
    textOne: "Unleash the Power of Live ",
    textTwo: "Classes in our App!",
    img: fIcon01,
    animationStyle: "fade-right",
  },
  {
    id: 2,
    heading: "Unlock Clarity",
    textOne: "Get Instant Doubt Solving Solutions,",
    textTwo: "Empowering Your Learning Journey!",
    img: fIcon02,
    animationStyle: "fade-right",
  },
  {
    id: 3,
    heading: "Ignite Your Success",
    textOne: "Take Quizzes and Tests to Excel",
    textTwo: "in Every Subject!",
    img: fIcon03,
    animationStyle: "fade-right",
  },
  {
    id: 4,
    heading: "Insights Excellence",
    textOne: "Uncover Your Performance with",
    textTwo: "Detailed Test Reports in Our App!",
    img: fIcon04,
    animationStyle: "fade-left",
  },
  {
    id: 5,
    heading: "Comprehensive Mastery",
    textOne: "Unleash Your Potential with Full Syllabus",
    textTwo: "Coverage in Our App!",
    img: fIcon05,
    animationStyle: "fade-left",
  },
  {
    id: 6,
    heading: "Accelerate Progress",
    textOne: "Excel with Homework and Worksheets,Your",
    textTwo: "Pathway to Success!",
    img: fIcon06,
    animationStyle: "fade-left",
  },
];

export const testimonial = [
  {
    text1: "Testimonials",
    text2:
      "Veda Academy empowered my child, boosting confidence and communication skills. A transformative language journey.",
    name: "Nitin Agrawal",
    address: "Noida, India",
    img: testImg,
  },
];

export const accordion = [
  {
    id: 1,
    title: "Is there a free trial available?",
    text: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    id: 2,
    title: "Can I change my plan later?",
    text: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    id: 3,
    title: "What is your cancellation policy?",
    text: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    id: 4,
    title: "Can other info be added to an invoice?",
    text: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    id: 5,
    title: "How does billing work?",
    text: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    id: 6,
    title: "How do I change my account email?",
    text: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
];
