import React from "react";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
export default function VedaAcademyDesc() {
  return (
    <Container sx={{mb:7}}>
      <Box
        sx={{ fontFamily: "Arial, Helvetica, sans-serif", marginTop: "50px" }}
      >
        <Typography sx={{ fontSize: "30px", marginTop: "10px" }}>
          VEDA Academy - तमसो मा ज्योतिर्गमय
        </Typography>
        <hr />
        <Typography sx={{ fontSize: "20px", marginTop: "10px" }}>
          VEDA is one of the leading online learning platform designed to make
          learning immersive and increase retention for students of both Hindi &
          English medium in class 6th to 12th. Students will find Topic wise
          structured sessions, instant solutions to all your doubts. Get PDF and
          video solutions of all the subjects. Everyone has the right to
          education, education is not only right but a passport to human
          development that can build prosperous, healthy and equitable
          societies. Our goal is to ensure Inclusive, Equitable and Quality
          Education along with lifelong learning opportunities for all.
        </Typography>
      </Box>
    </Container>
  );
}
