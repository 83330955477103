import {
  Grid,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  FormControl,
} from "@mui/material";

export default function AddressForm({ formik }) {
  return (
    // <form onSubmit={formik.handleSubmit}>
    <Grid container spacing={0}>
      <Grid item xs={12} md={6}>
        <FormControl sx={{ width: "92%", ml: 2, mb: 2 }}>
          <Typography>Name</Typography>
          <TextField
            name="fullname"
            {...formik.getFieldProps("fullname")}
            onChange={formik.handleChange}
            error={formik.touched.fullname && formik.errors.fullname}
            inputProps={{ style: { height: "10px" } }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl sx={{ width: "92%", ml: 2, mb: 2 }}>
          <Typography>Mobile No</Typography>
          <TextField
            name="phone"
            type="number"
            inputProps={{ maxLength: 10, style: { height: "10px" } }}
            {...formik.getFieldProps("phone")}
            onChange={(e) => {
              const allowedChars = /^[0-9\b]+$/;
              if (allowedChars.test(e.target.value) || e.target.value === "") {
                if (String(e.target.value).length <= 10) {
                  formik.handleChange(e);
                }
              }
            }}
            error={Boolean(formik.touched.phone && formik.errors.phone)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl sx={{ width: "92%", ml: 2, mb: 2 }}>
          <Typography>Email</Typography>
          <TextField
            name="email"
            type="email"
            inputProps={{ style: { height: "10px" } }}
            {...formik.getFieldProps("email")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            error={Boolean(formik.touched.email && formik.errors.email)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={12}>
        <FormControl sx={{ width: "96%", ml: 2, mb: 2 }}>
          <Typography>Address</Typography>
          <TextField
            name="address"
            multiline
            rows={2}
            {...formik.getFieldProps("address")}
            onChange={formik.handleChange}
            error={formik.touched.address && formik.errors.address}
            inputProps={{ style: { height: "10px" } }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl sx={{ width: "92%", ml: 2, mb: 2 }}>
          <Typography>City</Typography>
          <TextField
            name="city"
            {...formik.getFieldProps("city")}
            onChange={formik.handleChange}
            error={formik.touched.city && formik.errors.city}
            inputProps={{ style: { height: "10px" } }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl sx={{ width: "92%", ml: 2, mb: 2 }}>
          <Typography>State</Typography>
          <TextField
            name="state"
            {...formik.getFieldProps("state")}
            onChange={formik.handleChange}
            error={formik.touched.state && formik.errors.state}
            inputProps={{ style: { height: "10px" } }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl sx={{ width: "92%", ml: 2, mb: 2 }}>
          <Typography>Pin Code</Typography>
          <TextField
            name="pin"
            type="number"
            inputProps={{ maxLength: 6, style: { height: "10px" } }}
            {...formik.getFieldProps("pin")}
            onChange={(e) => {
              const allowedChars = /^[0-9\b]+$/;
              if (allowedChars.test(e.target.value) || e.target.value === "") {
                if (String(e.target.value).length <= 6) {
                  formik.handleChange(e);
                }
              }
            }}
            error={Boolean(formik.touched.pin && formik.errors.pin)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={12}>
        <Typography sx={{ fontSize: "18px", mt: 2, mb: 2, fontWeight: "600" }}>
          Address Type
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} sx={{ mb: 4 }}>
        <FormControl sx={{ width: "92%", ml: 2, mb: 2 }}>
          <Typography sx={{ fontSize: "12px" }}>Select Address type</Typography>
          <RadioGroup>
            <Box sx={{ display: "flex" }}>
              <FormControlLabel
                value="Office"
                control={
                  <Radio
                    checked={formik.values.addressType === "Office"}
                    sx={{
                      color:
                        formik.touched.addressType && formik.errors.addressType
                          ? "red"
                          : "",
                    }}
                  />
                }
                label="Office"
                name="addressType"
                onChange={formik.handleChange}
                sx={{
                  color:
                    formik.touched.addressType && formik.errors.addressType
                      ? "red"
                      : "",
                }}
              />

              <FormControlLabel
                value="Home"
                control={
                  <Radio
                    checked={formik.values.addressType === "Home"}
                    sx={{
                      color:
                        formik.touched.addressType && formik.errors.addressType
                          ? "red"
                          : "",
                    }}
                  />
                }
                label="Home"
                name="addressType"
                onChange={formik.handleChange}
                sx={{
                  color:
                    formik.touched.addressType && formik.errors.addressType
                      ? "red"
                      : "",
                }}
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
    // </form>
  );
}
