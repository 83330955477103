import React from "react";
import ModalPaperMaterial from "./ModalPaperMaterial";

function GussPaperIndex() {
  return (
    <>
      <ModalPaperMaterial />
    </>
  );
}

export default GussPaperIndex;
