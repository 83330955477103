import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import peopleGroup from "../../assets/images/peopleGroup.svg";
import Aos from "aos";

function ChooseYourBoard() {
  const [imageSize, setImageSize] = useState({ width: 500, height: 500 });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setImageSize({ width: 250, height: 250 });
      } else if (window.innerWidth <= 800) {
        setImageSize({ width: 400, height: 400 });
      } else {
        setImageSize({ width: 500, height: 500 });
      }
    };

    // Attach the event listener on component mount
    window.addEventListener("resize", handleResize);

    // Call the handleResize initially to set the initial image size
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            columnGap: "20px",
            alignItems: "center",
            flexWrap: "wrap-reverse",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              mt: { xs: "-60px", lg: "0px" },
              mb: { xs: 8, lg: 0 },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: { sm: "48px", xs: "40px" },
                lineHeight: { sm: "72px", xs: "53px" },
                fontWeight: 600,
              }}
            >
              Choose Your Board And <br />
              Grade Level to{" "}
              <Typography
                variant="span"
                sx={{
                  background: `linear-gradient(#FF7432, #D35A1E)`,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Begin Your
              </Typography>
              <br />{" "}
              <Typography
                variant="span"
                sx={{
                  background: `linear-gradient(#FF7432, #D35A1E)`,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Learning Journey
              </Typography>
            </Typography>

            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                lineHeight: "32px",
                fontWeight: 400,
              }}
            >
              Provides you with the latest online learning system and <br />
              material that help your knowledge growing.
            </Typography>
          </Box>
          <img
            src={peopleGroup}
            alt=""
            style={{
              width: `${imageSize.width}px`,
              height: `${imageSize.height}px`,
            }}
          />
        </Box>
      </Container>
    </>
  );
}

export default ChooseYourBoard;
