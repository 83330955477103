import React from "react";
import { Grid, Card, Box, Typography } from "@mui/material";
import { teachData, teacherData } from "./data";
import Slider from "react-slick";
import Button from "@mui/material/Button";
import zIndex from "@mui/material/styles/zIndex";

function TeachersTeach() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Grid xs={12} md={12}>
        <Box sx={{ mb: 4, mt: 4 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "35px", sm: "40px" },
              lineHeight: "60px",
              fontWeight: 600,
              textAlign: "center",
              color: "#fff",
            }}
          >
            Learn from India’s No 1 JNV Team
          </Typography>
        </Box>
      </Grid>

      <Grid xs={12} md={12}>
        <div className="slider2">
          <Slider {...settings}>
            {teacherData?.map((item, index) => {
              return (
                <Box
                  key={index + "social"}
                  display="flex"
                  sx={{
                    width: { xs: "100%", md: "30%" },
                    height: "430px",
                  }}
                >
                  <Box
                    alignSelf="flex-end"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "90%",
                      height: "100%",
                      backgroundColor: "transparent",
                      borderRadius: "16px",
                    }}
                  >
                    <Box sx={{ position: "relative" }}>
                      <img
                        src={item?.img}
                        width="167px"
                        height="251px"
                        alt="teacher image"
                        style={{ zIndex: 535, position: "relative" }}
                      />
                      <Box
                        sx={{
                          width: "290px",
                          height: "85%",
                          backgroundColor: "#f5b196",
                          position: "absolute",
                          top: "49px",
                          left: "-60px",
                          borderRadius: "10px 10px 0px 0px",
                        }}
                      ></Box>
                    </Box>

                    <Card
                      sx={{
                        width: "85%",
                        height: "50%",
                        borderRadius: "16px",
                        alignSelf: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#193540",
                        mb: 0,
                        zIndex: "122",
                        color: "white",
                      }}
                    >
                      <Button
                        sx={{
                          backgroundColor: "#DEEEFD",
                          color: "#000",
                          borderRadius: "15px",
                          padding: "2px 15px",
                          fontSize: "12px",
                          mt: 2,
                          mb: 2,
                          textTransform: "none",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#DEEEFD",
                          },
                        }}
                      >
                        {item.exp}
                      </Button>
                      <Typography sx={{ fontSize: "18px", mb: 1 }}>
                        {item.name}
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        {item.subject}
                      </Typography>
                    </Card>
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </div>
      </Grid>
    </>
  );
}

export default TeachersTeach;
