import React from "react";
import { Container, Grid, Card, Box, Typography, Button } from "@mui/material";

import "./style.css";
import CoursebgIcon from "../../assets/images/english/coursebgIcon.png";

function SocialPage() {
  return (
    <>
      <Container>
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: "-20px",
              left: "-20px",
            }}
          >
            <img src={CoursebgIcon} alt="join community" width="70px" />
          </Box>
          <Box
            sx={{
              width: "100%",
              height: { xs: "120px", sm: "150px", md: "143px" },
              mt: 2,
              backgroundImage: "linear-gradient(to top, #FF8D4D, #EA5806)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: { xs: "wrap", sm: "nowrap", md: "nowrap" },
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "space-around",
                },
                alignItems: "center",
                paddingLeft: { xs: "0px", sm: "0px", md: "20px" },
                paddingTop: { xs: "0px", sm: "0px", md: "0px" },
                width: { xs: "100%", sm: "95%", md: "85%" },
                flexDirection: { xs: "column", sm: "column", md: "row" },
              }}
            >
              <Typography
                sx={{
                  mb: { xs: "10px" },
                  mt: { sx: "10px" },
                  mr: { xs: "0px", xs: "0px", md: "40px" },
                  color: "#fff",
                  fontSize: "16px",
                }}
              >
                Free What's App Community
              </Typography>

              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  background: "#fff",
                  color: "black",
                  borderRadius: "15px",
                  padding: "5px 20px",
                  fontSize: "13px",
                  width: { xs: "140px", sm: "160px", md: "140px" },
                  mb: { xs: "10px" },
                  mt: { xs: 2, sm: 2, md: 0 },
                  ":hover": {
                    backgroundColor: "green",
                    color: "#fff",
                  },
                }}
              >
                Join Now
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default SocialPage;
