import React, { useState } from "react";
import WraperCard from "components/WraperCard";
import DisclaimerOfWarranties from "pages/DisclaimerOfWarranties";
import HomePage from "pages/HomePage/index";
import KTweleve from "pages/K-12/K-12";
import PaymentResponse from "pages/PaymentResponse";
import PrivacyPolicy from "pages/PrivacyPolicy";
import RefundCancellation from "pages/RefundCancellation";
import Support from "pages/Support";
import TermsAndServices from "pages/TermsAndServices";
import VedaAcademyDesc from "pages/VedaAcademyDesc";
import { Routes, Route } from "react-router-dom";
import About from "pages/About/About";
import ContactUs from "pages/ContactUs/contactUs";
import English from "pages/SpokenEnglish/SpokenEnglish";
import AddToCart from "pages/SpokenEnglish/cart/AddToCart";
// import JNVSection from "pages/Jnv/JVNSection";
import JnvIndex from "pages/Jnv/JnvIndex";

import NavBar from "components/NavBar";
import NavBarJNV from "components/NavBarJNV";
import NavBarEnglish from "components/NavBarEnglish";
import Footer from "components/Footer";
import PageNotFound from "pages/ErrorPage/PageNotFound";
import PhonepayPaymentResponse from "pages/PaymentResponse/phonepaysuccess";
import { useLocation } from "react-router-dom";
import Checkout from "pages/SpokenEnglish/checkout/Checkout";
import PryQuestionIndex from "pages/Jnv/pryquestion/PryQuestionIndex";
import RevisionNoteIndex from "pages/Jnv/revisionnote/RevisionNoteIndex";
import ModalPaperIndex from "pages/Jnv/modalpaper/ModalPaperIndex";
import GussPaperIndex from "pages/Jnv/gusspaper/GussPaperIndex";
import BooksIndex from "pages/Jnv/books/BooksIndex";
import OmrSheetIndex from "pages/Jnv/omrsheet/OmrSheetIndex";

const AppRouter = () => {
  const location = useLocation();

  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("prodData")) || []
  );

  const [productItems, setProductItems] = useState(
    JSON.parse(localStorage.getItem("prodItem")) || []
  );

  const updateCartItems = (newCartItems) => {
    setCartItems(newCartItems);
  };

  const updateOrderItems = (newProdItems) => {
    setProductItems(newProdItems);
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <WraperCard>
        {location?.pathname && location?.pathname?.includes("/jnv") ? (
          <NavBarJNV urlPath={location?.pathname} />
        ) : location?.pathname === "/english" ||
          location?.pathname === "/books/cart" ||
          location?.pathname === "/books/checkout" ? (
          <NavBarEnglish
            cartItems={cartItems}
            updateOrderItems={updateOrderItems}
          />
        ) : (
          <NavBar />
        )}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/k-12" element={<KTweleve />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route
            path="/english"
            element={
              <English
                updateCartItems={updateCartItems}
                updateOrderItems={updateOrderItems}
              />
            }
          />
          <Route
            path="/books/cart"
            element={
              <AddToCart
                setProductItems={setProductItems}
                updateOrderItems={updateOrderItems}
                cartItems={cartItems}
                setCartItems={setCartItems}
                updateCartItems={updateCartItems}
              />
            }
          />
          <Route
            path="/books/checkout"
            element={
              <Checkout
                productItems={productItems}
                setProductItems={setProductItems}
                cartItems={cartItems}
                setCartItems={setCartItems}
              />
            }
          />
          <Route path="/jnv" element={<JnvIndex />} />
          <Route path="/jnv/pryquestion" element={<PryQuestionIndex />} />
          <Route path="/jnv/revisionnote" element={<RevisionNoteIndex />} />
          <Route path="/jnv/modalpaper" element={<ModalPaperIndex />} />
          <Route path="/jnv/gusspaper" element={<GussPaperIndex />} />
          <Route path="/jnv/books" element={<BooksIndex />} />
          <Route path="/jnv/omrsheet" element={<OmrSheetIndex />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-cancellation" element={<RefundCancellation />} />
          <Route
            path="/disclaimer-of-warranties"
            element={<DisclaimerOfWarranties />}
          />
          <Route path="/terms-and-service" element={<TermsAndServices />} />
          <Route
            path="/veda-academy-description"
            element={<VedaAcademyDesc />}
          />
          <Route
            path="/veda-academy-description"
            element={<VedaAcademyDesc />}
          />
          <Route
            path="/payment-details/confirmation"
            element={<PaymentResponse />}
          />
          <Route
            path="/payment/confirmation"
            element={<PhonepayPaymentResponse />}
          />

          <Route path="/support" element={<Support />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </WraperCard>
    </div>
  );
};

export default AppRouter;
