import React from "react";
import BooksMaterial from "./BooksMaterial";

function BooksIndex() {
  return (
    <>
      <BooksMaterial />
    </>
  );
}

export default BooksIndex;
