import React from "react";
import ChooseYourBoard from "./ChooseYourBoard";
import VideoSection from "./VideoSection";
import BookdemoSection from "pages/HomePage/BookdemoSection";
import ClassroomEnvironment from "./ClassroomEnvironment";
import InspireStorySection from "components/InspireStorySection";
import AppStoreSection from "components/AppStoreSection";

function AboutUs() {
  return (
    <>
      <ChooseYourBoard />
      {/* <VideoSection /> */}
      <BookdemoSection backgroundColor={"#EEF3FC"} />
      <ClassroomEnvironment />
      {/* <InspireStorySection /> */}
      <AppStoreSection />
    </>
  );
}

export default AboutUs;
